<template>
    <div class="center ">
        <div class="searchpop content  background-color" v-on:scroll.passive="handleScroll" >
            <searchBar ref="searchbar" v-on:back="onLeft" v-on:search="onSearch" />

            <!-- 검색결과 -->
            <div v-show="is_search">
                <SearchResult ref="searchResult" v-on:made="didMade" />
            </div>

            <div class="sticky-top" >
                <TabMenu ref="tabmenu" :data="tab_menus" v-on:clicked="onTabMenu"></TabMenu>
                <div v-show="selectedTab == str_recent_post && hasNewPost"  @click="pullRecentPosts" class="refresh">{{pull_new_post}}</div>
                <div v-show="selectedTab == str_recent_channel && hasNewChannel"  @click="pullRecentChannels" class="refresh">{{pull_new_channel}}</div>
            </div>

            <!-- 인기채널 -->
            <div class=""  
                v-show="selectedTab == str_hot_channel" 
            >
                <BoardCell 
                    v-for="(item, index) in hotChannels" v-bind:key="index" 
                    :data="{...item, index, rank: true}" 
                    id="around_board" 
                    v-on:clicked="onSelBoard(item.idx)" 
                />

            </div>            

            <!-- 최신채널 -->
            <div class="" 
                v-show="selectedTab == str_recent_channel" 
            >
                <BoardCell 
                    v-for="(item, index) in recentChannels" v-bind:key="index" 
                    :data="{...item, index, rank: true}"
                    v-on:clicked="onSelBoard(item.idx)" 
                />
                <!-- <div class="tail"></div> -->
            </div >

            <!-- 최신글 -->
            <div v-show="selectedTab == str_recent_post" 
            >
                <PostCell 
                    v-for="(item, index) in recentPosts " v-bind:key="index" 
                    :data="{...item, index}"
                    v-on:clicked="onPost(item.idx)" 
                />
                <!-- <div class="tail"></div> -->
            </div >
            
            <div class="tail"></div>
        </div>   

        <div class="bottom-menu">
        <!-- <Navbar ref="navbar" :data="{is_write: false}" v-on:clicked="onNavbar"></Navbar> -->
        </div>
    </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'
// import Navbar from '@/components/common/NavBar'
import TabMenu from '@/components/common/TabMenu.vue'
import BoardCell from '@/components/common/BoardCell.vue'
import searchBar from '@/components/SearchBar.vue'
import SearchResult from '@/components/SearchResult.vue'

// import TitleBar from '@/components/common/TitleBar.vue'

// import swiper module styles
import 'swiper/css'
import 'swiper/css/pagination'
import PostCell from './common/PostCell.vue'
// import PostView from '@/components/PostView.vue'
  // more module style...

  const str_hot_channel = `인기${Const.brand.name}`
  const str_recent_channel = `최신${Const.brand.name}`;
  const str_recent_post = '최신글';
  const str_hot_post = '추천글';
  const tab_menus = [str_hot_channel, str_recent_channel, str_recent_post];
// const tab_menus = [str_hot_channel, str_recent_channel, str_hot_post, str_recent_post];

  const str_empty = '같은 관심사를 가진 사람들과 \n익명으로 자유롭게\n 이야기를 나눠보세요'
  const str_no_board = '등록된 관심사가 아직 없습니다.'
  const pull_new_post = "새글 확인하기";
  const pull_new_channel = `새 ${Const.brand.name} 확인하기`;

  export default {
    components: {
    //   Navbar,
      TabMenu,
      BoardCell,
      PostCell,
      searchBar,
      SearchResult,
    },
    props: {
        param: Object,
        container:Object
    },
    setup() {
      console.log('board setup');
      return {
      }
    },
    data(){ 
      return {   
        is_search: false, // 사용자가 검색을 시도했는가?
        results: [], // 검색결과

        title: '',
        hotChannels: [], // 지역 인기채널
        recentChannels: [], // 최신글
        hotPosts: [], // 인기글
        recentPosts: [], // 최신글

        str_hot_channel, 
        str_recent_channel,
        str_recent_post,
        str_hot_post,
        tab_menus,

        hasNewPost: false, // 새로운 포스트가 있다
        hasNewChannel: false, // 새로운 채널

        selectedTab: tab_menus[0],

        end: {
            recentPosts: false,
            recentChannels: false,
        },

        str_empty,
        pull_new_post,
        pull_new_channel,
      }
    },
    created: function () {

    },
    mounted: function() {
        if(!this.param) return;

        console.log('this.param', this.param)

        const {idx, name} = this.param;
        this.title = name;
        this.$refs.searchbar.title = this.title;

    //   {
    //     const arr = name.split('>')
    //     if(arr.length > 2){
    //         this.title = arr[2];
    //         this.$refs.searchbar.title = this.title;
    //     } 
    //   }

        this.idx = idx;        
        console.log('this.idx', this.idx, name)

        {
            const comp = {
            onEvent: this.onEvent,
            onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        //   this.$refs.titlebar.title = title
        this.$refs.navbar ? this.$refs.navbar.is_share = true : '';
        
        this.setView();
    },
    methods:{
        didMade(){
            console.log('didMade');
            this.is_search = false;
        },
        search(text){
            const url = Const._api + "board/search/around";
            const body = {
                idx: this.idx,
                text,
            }

            common.api_post(url, body, data => {
                console.log('search>', data)

                const {result} = data;

                if(result.error == 1) // 위치정보가 없슴
                {
                    // 화면에 안내 표시
                    common.toast('현재 위치를 알 수 없습니다.');
                }
                else // if(around.error == 0)
                {
                    const setAddress = (obj) => {
                        const temp = obj.name.split('@');
                        obj.address = obj.name.split('>')[2];
                        obj.name= temp[0];
                    }

                    result.location_name = this.title;                                        
                    result.keyword = text;
                    result.not_found = true;
                    if(result.exact){
                        setAddress(result.exact);
                        const temp = result.exact.name.split('@');
                        result.exact.location = temp[1];
                        result.exact.name= temp[0];
                        result.not_found = false;            
                    }

                    result.similar.forEach(element => {
                        setAddress(element);
                        const temp = element.name.split('@');
                        element.location = temp[1];
                        element.name= temp[0];
                    });            

                    this.$refs.searchResult.update(result)
                }
            })
        },
        onSearch(param){
            if(param.isTrusted) return; // 두번 호출되네... ????? 임시로 막았다.

            console.log('onSearch', param)

            this.is_search = true;
            
            const text = param.trim();

            if(text.length > 0){
                this.search(text);
            }
        },
        onSelBoard(idx){
            common.push({
                name: Const.component.SavedView,
                param: {idx}
              })
        },
        pullRecentPosts(){
            this.hasNewPost = false;
            this.requestRecentPosts(0)
        },
        pullRecentChannels(){
            this.hasNewChannel = false;
            this.requestRecentChannel(0)
        },
        onShow(){
            this.setView();
        },
        onPull(tag){
            console.log('onPull');
            switch (tag) {
            case 0:

                break;
            case 1:

                break;
            default:
                break;
            }
        },
        onTouchEnd(item){
            console.log('onTouchEnd', item);
        },
        onTouchStart(item){
            console.log('onTouchStart', item);
        },
        handleScroll(e){
            const {target} = e;
            const {clientHeight, scrollTop, scrollHeight} = target;

            if((clientHeight + scrollTop + 1) >= scrollHeight) 
            {
                console.log('bottom')
                this.onScrollBottom();
            }

            if(scrollTop == 0){
                this.onScrollTop();
            } 
        },
        setView(){
            // console.log('mounted.param', this.param)
            common.checkSession((isLogin, user) => {
                // console.log('isLogin', isLogin);
                if(isLogin){
                    this.didLogin(user)
                }else{
                    this.notLogin();
                }

                // 지역의 인기채널
                this.requestHotChannel();

                // 지역의 최신채널
                this.requestRecentChannel();

                // 지역의 최신글
                // this.requestRecentPosts();

                // 지역의 인기글
                this.requestHotPosts();
            })
        },

        requestHotPosts(){

        },
        checkNewChannel(){
            const after = this.recentChannels.length > 0 ? this.recentChannels[0].idx : 0

            const url = Const._api + `board/location/new/${this.idx}/${after}`;

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {rows} = res.data;

                    if(rows.length > 0){
                        this.hasNewChannel = true;
                    }                    
                    else{
                        common.toast(`새로운 ${Const.brand.name5} 없습니다.`)
                    }
                }
            );
        },
        checkNewPosts(){
            console.log('checkNewPosts')

            // todo : 체크한 시간이 충분히 흐르지 않았다면 무시
            // if(){
            //     return;
            // }

            const after = this.recentPosts.length > 0 ? this.recentPosts[0].idx : 0

            const url = Const._api + `post/location_last/${this.idx}/${after}`;

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {rows} = res.data;

                    if(rows.length > 0){
                        this.hasNewPost = true;
                    }                    
                    else{
                        common.toast('새로운 글이 없습니다.')
                    }
                }
            );
        },
        requestRecentPosts(start = 0){
            console.log('requestRecentPosts')
            if(this.end.recentPosts){
                common.toast(Const.message.no_more)
                return;
            }

            if(start == 0) this.recentPosts = [];

            const url = Const._api + `post/location/${this.idx}/${start}`;

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {rows, end} = res.data;

                    if(this.recentPosts.length == 0 && rows.length == 0)
                    {
                        // todo 
                        // this.str_empty = str_no_board;
                        return;
                    }

                    rows.forEach(element => {
                        const temp = element.board_name?.split('@');
                        if(temp){
                            element.board_name = temp[0];
                            element.location = temp[1].split('>')[2];
                        }
                    });

                    this.recentPosts = this.recentPosts.concat(rows);
                    this.end.recentPosts = end;
                }
            );
        },
        requestRecentChannel(start=0){
            const url = Const._api + `board/location/latest/${this.idx}/${start}`;

            if(start == 0) this.recentChannels = [];

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {rows} = res.data;

                    if(this.recentChannels.length == 0 && rows.length == 0)
                    {
                        this.str_empty = str_no_board;
                        return;
                    }

                    rows.forEach(element => {
                        const temp = element.name.split('@');
                        element.location = temp[1];
                        element.address = element.location ? element.name.split('>')[2] : '', 
                        element.name= temp[0];
                    });

                    this.recentChannels = rows;
                }
            );      
        },
        requestHotChannel(){
            const url = Const._api + `board/location/hot/${this.idx}`;

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {rows} = res.data;

                    if(this.hotChannels.length == 0 && rows.length == 0)
                    {
                        this.str_empty = str_no_board;
                        return;
                    }

                    rows.forEach(element => {
                        const temp = element.name.split('@');
                        element.location = temp[1];
                        element.address = element.location ? element.name.split('>')[2] : '', 
                        element.name= temp[0];
                    });

                    this.hotChannels = rows;
                }
            );          
        },
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {
            case Const.notify.post:
            case Const.notify.post_del:
                {
                    const {board_idx} = param;
                    board_idx;
                }
                break;
            case Const.notify.login:
                {
                console.log('onEvent', param);
                this.setView();
                }
                break;        
            default:
                break;
            }
        },
        didLogin(user){
            console.log('didLogin', user);
            // console.log(persona.name + '님')

            // navbar 수정
            this.me = user;
            this.$refs.navbar?.didLogin();

            if(this.$refs.navbar)
            {
                this.$refs.navbar.is_menu = false;
                this.$refs.navbar.is_message = true;        
                this.$refs.navbar.is_bookmark = true;
                this.$refs.navbar.notice = user.notice;

                this.$refs.navbar.is_noti_on = false;

                this.$refs.navbar.is_noti_on = user.notice;
                this.$refs.navbar.is_message_on = user.message;
            }
            
            if(this.param.write) 
            this.onWrite();
        },      
        notLogin: function(){        
            if(this.param.write) // 글을 쓰려는데 로그인 상태가 아니다.
            common.popupLogin();
        },
        onLeft(){
            common.back();
        },
        onScrollTop(){
            console.log('onScrollTop')

            // [str_hot_channel, str_recent_post, str_hot_post];
            switch (this.selectedTab) {
            case str_hot_channel:
                // todo : 새글 가져오기 말고 새로 고침
                break;
            case str_recent_channel:
                if(this.hasNewChannel) return // 이미 있다.            
                this.checkNewChannel()
                break;                
            case str_recent_post:
                if(this.hasNewPost) return // 이미 있다.            
                this.checkNewPosts()
                break;
            case str_hot_post:
                
                break;        
            default:
                break;
            }
        },
        onScrollBottom(){
            switch (this.selectedTab) {
            case str_hot_channel:
                // 일정갯수만 보여주는 것으로...

                // {
                //     const row = this.rating.rows[this.rating.rows.length - 1];
                //     if(row){
                //         const idx = row.idx;
                //         this.request(idx, this.rating, 0);
                //     }
                // }            
                break;
            case str_recent_post:
                {
                // console.log(this.recent.rows);
                if(this.recentPosts.length > 0){
                    const idx = this.recentPosts[this.recentPosts.length - 1].idx;
                    this.requestRecentPosts(idx);
                }
                }
                break;
            case str_hot_post:
                
                break;        
            default:
                break;
            }
        },
        onScrollEnd: function(){
            console.log('onScrollEnd');
            
            if(this.needs_more === false) return;    // 더 이상 레코드 없으면...

            const length = this.recent.rows.length;
            if(length == 0) return;

            const lastIdx = this.recent.rows[length-1].idx;

            this.request(lastIdx);

            // test code
            // this.recent.rows = this.recent.rows.concat(this.recent.rows);
        },
        onNavbar: function(button){
            console.log('onNavbar', button);
            switch (button) {
                case 'search':
                    this.$emit('event', 'home');
                    break;
                case 'login':
                    {
                    const param = {
                        didLogin: this.setView
                    }
                    common.popupLogin(param)
                    }
                    break;
                case 'bookmark':
                    this.onLike();
                    break;
                case 'share':
                    {
                    console.log('this', window.location);
                    const param = {
                        url: `${window.origin}/?/local/${this.idx}`,
                    } ;
                    console.log('param', param);

                    common.popUp({
                        name: Const.component.QrcodeVue,
                        param
                    })
                    }
                    break;
                case 'write':
                    this.onWrite();       
                break;
            
                default:
                break;
            }
        },
        onWrite(){
            console.log('this.me', this.me);
            if(this.me)
            {
                const param = {
                    board_idx: this.idx,
                    idx: 0,
                }
                const obj = {
                    name: Const.component.PostForm,
                    param
                }
                common.popUp(obj);
            }
            else // 로그인을 한번도 한적이 없는 경우 
            {
                common.popupLogin();
            }         
        },
        onHome(){
            this.$emit('event', 'home');
        },
        onLike(){
            console.log('onLike', this.board.isLike);

            {
                const url = Const._api + "board/like";
                const param = {
                board_idx: this.idx,
                like: !(this.board.isLike),
                }
                common.api_post(url, param, data => {
                    console.log('data', data)

                    const {error, msg} = data; 
                    if(error == 100)    // not login
                    {
                        common.tryLogin();
                        return;
                    }
                    else if(error > 0){
                        console.log('error', error);
                        this.$emit('error', error, msg);      
                        return;
                    }
                    // this.$emit('done');
                    this.board.isLike = !(this.board.isLike) ? 1 : 0;
                    this.$refs.navbar ? this.$refs.navbar.bookmark = this.board.isLike ? "bookmark-on" : "bookmark-off" : '' ;

                    common.notifyEvent(Const.notify.bookmark)
                })
            }          
        },
        onTabMenu: function(menu, param){
            this.selectedTab = menu;
            console.log('index.selectedTab', menu, param);

            switch (menu) {
                case str_hot_channel:
                    this.requestHotChannel();
                break;
                case str_recent_channel:
                    this.requestRecentChannel();
                break;
                case str_recent_post:
                    this.requestRecentPosts();    
                    break;        
                default:
                break;
            }
        },
        onPost(idx){
            common.push({
                name: Const.component.PostView,
                param: {idx}
              })
        },
        onPostView(tag, param){
            console.log('onPostView', tag, param);
            switch (tag) {
            case 'close':
                this.is_show_post_page = false;              
                break;
            
            default:
                break;
            }
        },
        onDone: function(){
            console.log('onDone');
            this.recent.rows = [];
            this.request(0);
        },
        onError: function(error, param){
            console.log('onError', error, param);

            // todo : notlogin 처리 / 리책토링

            common.toast(param)
        }
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

</style>>

