<template>
    <div style="width: 100%; height: 100%; overflow: auto; background: white;">

        <div style="display:flex; justify-content: space-between;">
            <!-- <div class="bar-menu" @click="closeAll">수신함</div> -->
            <span class="bar-menu material-symbols-outlined" @click="closeAll">format_list_bulleted</span>
            <div class="bar-menu" @click="onMenu">&hellip;</div>
        </div>

        <div class="cell-background-color padding10">
            <slide-view ref="slideview" :data="data" />
        </div>
        <div class="buttons button-container max-width">
            <div />
            <div style="display: flex;">
                <span v-if="(index - 1) >= 0" style="padding: 0 10px;" class="material-symbols-outlined" @click="onPre">arrow_upward</span>
                <span v-if="rows.length > (index + 1)" style="padding: 0 10px;" @click="onNext" class="material-symbols-outlined" >arrow_downward</span>
            </div>
            <div />
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import common from '@/common';
import Const from '@/const'
import SlideView from './SlideView.vue';

export default {  
    components: {
        SlideView
    },
    props:{
        controller: Object,
        param: Object,
        close: Function,
    },
    data: function () {
      return {  
        data: {},
        index: 0,
        callerIndex: undefined,
        rows: [],
        next: null,
        swiper: null,        
        slideview: null,
      };
    },    
    mounted() {
        console.log('this.param>', this.param)
        // this.data = this.param.data;

        const {closeAll, index, rows, data, callerIndex, caller} = this.param;
        Object.assign(this.data, data)
        console.log('this.data>', this.data)

        this.closeAll = closeAll;
        this.index = index;
        this.rows = rows;
        this.callerIndex = callerIndex;
        this.caller = caller;

        this.request();

        this.slideview = this.$refs.slideview;

        {
            const comp = {
                onEvent: this.onEvent,
            }
            common.registEventHandler(comp);
        }
    },
    methods: {
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {
            case Const.notify.done:
                this.data.pickable = false;
                break;
            default:
                break;
            }

            this.param.onEvent(name, param);
        },        
        onMenu(){ // 본문메뉴
            console.log('onMenu', this.user)
            const row = this.param.data

            let arr = [];
            if(this.user == null){
                console.log(this.user)
            }
            else
            {
                if(this.user.idx == row.user_idx) // 자신이면
                { 
                    arr = [
                        // {
                        //   title : '여기에 메뉴 표시...',
                        //   cb: () => {
                        //     //   const obj = {
                        //     //     name: Const.component.DistForm,
                        //     //     param: {...this.data, chips: this.user.chips} 
                        //     //   }
                        //     //   common.popUp(obj);
                        //     console.log('메뉴선택')
                        //   },
                        // },
                        {
                            title : '공유',
                            cb: () => {
                                // this.popupShare();
                                const {idx, hash} = row;
                                common.popupShare(idx, hash)
                            }
                        },            
                    ]          
                }
                else{
                    console.log('타인의 게시글')
                }

                arr.push(
                                        
                )

            }

            common.showMenu(arr)
        },
        onShow(){
            console.log('onShow', this.data)
            this.slideview.resetSlide();
            this.slideview.start();

            if(this.data.pickable == true) this.request();
        },
        request(){
            const row = this.data;
            const url = `${Const._api}adv/${row.idx}/${row.hash}`
            // const url = `${host}/api/my/ad/${idx}`
            console.log(url)
            axios.defaults.withCredentials = true;
            axios.get(url).then(response => {
                const data = response.data;
                // console.log(data)
                const {error, row, msg} = data;

                if(error > 0){
                    console.log(msg)
                    return;
                } 

                row.logined = row.needLogin != 'y';
                // console.log('row>', row)

                this.slideview.update(row);
            })
            .catch(error => {
                console.error(error);
            });            
        },
        onChildEvent(param){
            console.log('onChildEvent', param)
        },
        popup(index)
        {
            this.slideview.resetSlide();
            const obj = {
                name: Const.component.SlideNavigator,
                param: {
                    data: this.rows[index],
                    index,
                    rows: this.rows,
                    closeAll: this.closeAll,
                    callerIndex: this.index,
                    caller: this,
                    onEvent: this.param.onEvent,
                }
            }
            common.popUp(obj)            
        },
        onNext(){
            this.slideview.resetSlide();

            if(this.callerIndex == undefined){
                this.popup(this.index + 1)
                return;
            } 

            if(this.callerIndex > this.index){
                this.close()
                if(this.caller) this.caller.onShow();
            } 
            else this.popup(this.index + 1)
        },
        onPre(){
            this.slideview.resetSlide();

            console.log('onPre', this.callerIndex, this.index)

            if(this.callerIndex == undefined) {
                this.popup(this.index - 1)
                return 
            }

            if(this.callerIndex < this.index){
                if(this.caller) this.caller.onShow();
                this.close();
            } 
            else this.popup(this.index - 1)
        },
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.swiper {
    margin: 10px;
    /* margin-top: auto;
    margin-bottom: auto; */
}

.bar-menu {
    margin-right: 0px;
    width: fit-content;
    padding: 10px;
}

.btn {
    margin: 20px;
}

.buttons {
    display: flex;    
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
}

.button-container {
    /* position: fixed; */
    bottom: 10px;
    width: 100%;

    display: flex; 
    justify-content: space-between; 
    width: 100%;
}
</style>
