<template>
    <div style="background: white; height: 100vh; text-align: center;  padding-top: 100px;">
        <pre class="modal-text" style="width: 300px;margin: 0 auto ; text-align: left;">
가입 마지막 단계입니다.
네이버페이 포인트를 받을 핸드폰 번호를 등록하면 가입이 완료 됩니다.

아래 버튼을 눌러 SMS를 보낸 후 
완료버튼을 눌러 가입신청을 마무리해주세요.
        </pre>

        <div  style="margin-top: 50px;">
            <a class="text-button" @click="onClick" :href="href">등록 SMS 보내기</a>
        </div>
        <div v-if="sent" style="margin: 50px auto 0 ;width: fit-content;">
            <div class="text-button" @click="onDone"  >SMS를 보냈어요</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import util from '@/util';
import common from '@/common'
import Const from '@/const'

export default {  
  components: {
  },  
  props:{
    close: Function,
  },
  data() {
    return {  
        href: '',
        sent: false,
    };
  },
  mounted() {
      const device = util.device()
      if(device.length == 0){
        alert('폰에서 가입절차를 완료하세요.')
        return;
      }

      this.device = device;

      const proc = (data) => {
        console.log(data)
        const {error, phone, hash, msg} = data;

        if(error > 0){
            console.log(msg)
            return;
        } 

        if(this.device == 'Android'){
            this.href = `sms:${phone}?body=${hash}`;
        }
        else if(this.device == 'iOS'){
            this.href = `sms:${phone}&body=${hash}`;
        }
    }

    const url = `${Const._api}phonehash`
    axios.get(url).then(common.checkResponse(proc))
    .catch(error => {
        console.error(error);
    });          
  },
  methods: {
    onClick(){
        this.sent = true;
    },
    onDone(){
        // todo : 서버에 요청 / 다시 뜨지 않도록
        // 대기중??? 

        // location.reload();
        // this.$emit('done')

        // 노티
        // 닫기
        common.notifyEvent(Const.notify.sms_sent)
        this.close();
    },
  },      
}
</script>

<style scoped>
@import '@/assets/common';
</style>

