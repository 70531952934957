<template>
    <div class="center ">
        <div ref="scroll_container" class="searchpop content background-color" v-on:scroll.passive="handleScroll" >
            <title-bar-ex ref="titlebar" :container="container"/>

            <div class="" >
                <TabMenu ref="tabmenu" :data="tab_menus" v-on:clicked="onTabMenu"></TabMenu>
                <!-- <div v-show="selectedTab == str_recent_post && hasNewPost"  @click="pullRecentPosts" class="refresh">{{pull_new_post}}</div>
                <div v-show="selectedTab == str_channels && hasNewChannel"  @click="pullRecentChannels" class="refresh">{{pull_new_channel}}</div> -->
            </div>

            <!-- 채널 -->
            <div class="" 
                v-show="selectedTab == str_channels" 
            >
                <div class="center cell-button text-button-color " @click="requestChannel">새로 고침</div>            
                <BoardCell 
                    v-for="(item, index) in channels" v-bind:key="index" 
                    :data="{...item, index}"
                    v-on:clicked="onSelBoard(item.idx)" 
                />
                <!-- <div class="tail"></div> -->
            </div >

            <!-- 최신글 -->
            <div v-show="selectedTab == str_posts" 
            >
                <div v-if="!posts.scroll && !posts.new" class="center cell-button text-button-color " @click="checkNewPosts">새글 불러오기</div>
                <NewAlert ref="alert_post" :request="requestPosts" />
                <!-- <div v-if="posts.new" class="center cell-button text-button-color" 
                    @click="() => {this.posts.new = false; this.requestPosts()}">
                    새글 불러오기
                </div> -->
                <PostCell 
                    v-for="(item, index) in posts.rows " v-bind:key="index" 
                    :data="{...item, index}"
                    v-on:clicked="onPost(item.idx)" 
                />
                <!-- <div class="tail"></div> -->
            </div >
            
            <div class="tail"></div>
        </div>   

        <div class="bottom-menu">
        </div>
    </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'
// import TitleBar from '@/components/common/TitleBar.vue'
import TabMenu from '@/components/common/TabMenu.vue'
import BoardCell from '@/components/common/BoardCell.vue'

import 'swiper/css'
import 'swiper/css/pagination'
import PostCell from './common/PostCell.vue'
import NewAlert from './common/NewAlert.vue'
import TitleBarEx from './common/TitleBarEx.vue'
// import PostView from '@/components/PostView.vue'
  // more module style...

  const str_channels = `${Const.brand.name}별 소식`;
  const str_posts = '최신글';
  const tab_menus = [str_posts, str_channels];

  const str_empty = '같은 관심사를 가진 사람들과 \n익명으로 자유롭게\n 이야기를 나눠보세요'
  const str_no_board = '등록된 관심사가 아직 없습니다.'
  const pull_new_post = "새글 확인하기";
  const pull_new_channel = `새 ${Const.brand.name} 확인하기`;

  export default {
    components: {
    //   Navbar,
      TabMenu,
      BoardCell,
      PostCell,
    //   TitleBar,
        NewAlert,
        TitleBarEx,
    },
    props: {
        param: Object,
        container: Object,
    },
    setup() {
      console.log('board setup');
      return {
      }
    },
    data(){ 
      return {   
        results: [], // 검색결과

        title: '',
        channels: [], // 최신글
        posts: {
            rows: [],
            end: false,
            new: false, // 새글이 있다.
            scroll: false,
        }, // 최신글

        str_channels,
        str_posts,
        tab_menus,

        hasNewPost: false, // 새로운 포스트가 있다
        hasNewChannel: false, // 새로운 채널

        selectedTab: tab_menus[0],

        str_empty,
        pull_new_post,
        pull_new_channel,
      }
    },
    created() {

    },
    mounted() {
        // this.$refs.titlebar.title = `관심${Const.brand.name}`

        {
            const comp = {
            onEvent: this.onEvent,
            onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }

        // console.log('mounted.param', this.param)
        common.checkSession((isLogin, user) => {
            // console.log('isLogin', isLogin);
            if(isLogin){
                this.didLogin(user)
            }else{
                const param = {
                didLogin: this.didLogin
                }
                common.popupLogin(param);
            }
        })
    },
    methods:{
        didMade(){
            console.log('didMade');
            this.is_search = false;
        },
        search(text){
            const url = Const._api + "board/search/around";
            const body = {
                idx: this.idx,
                text,
            }

            common.api_post(url, body, data => {
                console.log('search>', data)

                const {result} = data;

                if(result.error == 1) // 위치정보가 없슴
                {
                    // 화면에 안내 표시
                    common.toast('현재 위치를 알 수 없습니다.');
                }
                else // if(around.error == 0)
                {
                    const setAddress = (obj) => {
                        const temp = obj.name.split('@');
                        obj.address = obj.name.split('>')[2];
                        obj.name= temp[0];
                    }

                    result.location_name = this.title;                                        
                    result.keyword = text;
                    result.not_found = true;
                    if(result.exact){
                        setAddress(result.exact);
                        const temp = result.exact.name.split('@');
                        result.exact.location = temp[1];
                        result.exact.name= temp[0];
                        result.not_found = false;            
                    }

                    result.similar.forEach(element => {
                        setAddress(element);
                        const temp = element.name.split('@');
                        element.location = temp[1];
                        element.name= temp[0];
                    });            

                    this.$refs.searchResult.update(result)
                }
            })
        },
        onSearch(param){
            if(param.isTrusted) return; // 두번 호출되네... ????? 임시로 막았다.

            console.log('onSearch', param)

            this.is_search = true;
            
            const text = param.trim();

            if(text.length > 0){
                this.search(text);
            }
        },
        onSelBoard(idx){
            common.push({
                name: Const.component.SavedView,
                param: {idx}
              })
        },
        pullRecentPosts(){
            this.hasNewPost = false;
            this.requestPosts(0)
        },
        pullRecentChannels(){
            this.hasNewChannel = false;
            this.requestChannel(0)
        },
        onShow(){
            this.initView();
        },
        onPull(tag){
            console.log('onPull');
            switch (tag) {
            case 0:

                break;
            case 1:

                break;
            default:
                break;
            }
        },
        onTouchEnd(item){
            console.log('onTouchEnd', item);
        },
        onTouchStart(item){
            console.log('onTouchStart', item);
        },
        handleScroll(e){
            const {target} = e;
            const {clientHeight, scrollTop, scrollHeight} = target;

            if((clientHeight + scrollTop + 1) >= scrollHeight) 
            {
                console.log('bottom')
                this.onScrollBottom();
            }

            if(scrollTop == 0){
                this.onScrollTop();
            } 
        },
        initView(){
        },
        checkLatestPost(after, cb){
            const url = Const._api + `post/likeNewer/${after}`;

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {row} = res.data;
                    cb(row);
                }
            );            
        },
        checkNewChannel(){
            // todo : 
            // 포스트의 마지막 인덱스로 판단하는거라 새로운 채널이 추가되면 표시되지 않는다... 흠...사용 못함
            // 결국 이전 결과와 동일하냐 다르냐로 판단해야 하는데.. 단순하게 판단불가

            // if(this.channels.rows.length == 0){
            //     this.requestChannel();
            //     return;
            // }

            // const after = this.channels.rows[0].post_idx;

            // this.checkLatestPost(after, (row) => {
            //     const result = null != row;
            //     this.channels.new = result;
            //     this.$refs.alert_channel.alert(result);
            // })
        },
        checkNewPosts(){
            console.log('checkNewPosts')

            // todo : 체크한 시간이 충분히 흐르지 않았다면 무시
            // if(){
            //     return;
            // }

            if(this.posts.rows.length == 0){
                this.requestPosts();
                return;
            }

            const after = this.posts.rows[0].idx;
            const url = Const._api + `post/likeNewer/${after}`;

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {row} = res.data;

                    if(row){
                        this.posts.new = true;
                        this.$refs.alert_post.alert(true);
                    }                    
                    else{
                        this.posts.new = false;
                        this.$refs.alert_post.alert(false);
                    }
                }
            );
        },
        requestPosts(start = 0){
            console.log('requestPosts', this.posts)

            if(this.posts.end){
                common.toast(Const.message.no_more)
                return;
            }

            if(start == 0) this.posts.rows = [];

            const url = Const._api + `post/like/${start}`;

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {rows, end} = res.data;

                    if(this.posts.rows.length == 0 && rows.length == 0)
                    {
                        // todo 
                        // this.str_empty = str_no_board;
                        return;
                    }

                    const setAddress = (obj) => {
                        const temp = obj.board_name.split('@');
                        obj.location = obj.board_name.split('>')[2];
                        obj.board_name= temp[0];
                    }

                    rows.forEach(element => {
                        setAddress(element);
                        const temp = element.board_name?.split('@');
                        if(temp){
                            // element.board_name = temp[0];
                            // element.location = temp[1].split('>')[2];
                        }
                    });

                    this.posts.rows = this.posts.rows.concat(rows);
                    this.posts.end = end;

                    // 스크롤 존재여부
                    setTimeout( () => {                         
                        console.log('scrollHeight / clientHeight', this.$refs.scroll_container.scrollHeight, this.$refs.scroll_container.clientHeight)
                        this.posts.scroll = this.$refs.scroll_container.scrollHeight > this.$refs.scroll_container.clientHeight;
                    }, 1); 

                }
            );
        },
        requestChannel(){
            const url = Const._api + `board/likes`;

            common.api_get(
                url, 
                res => {
                    const {error, msg} = res.data;
                    if(error > 0){
                        switch (error) {
                        default:
                            common.toast(msg);
                            break;
                        }
                        return;
                    }

                    const {rows} = res.data;

                    if(this.channels.length == 0 && rows.length == 0)
                    {
                        this.str_empty = str_no_board;
                        return;
                    }

                    rows.forEach(element => {
                        const temp = element.name.split('@');
                        element.location = temp[1];
                        element.address = element.location ? element.name.split('>')[2] : '', 
                        element.name= temp[0];
                    });

                    this.channels = rows;
                }
            );      
        },
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {
            case Const.notify.post:
                break;
            case Const.notify.post_del:
                {
                    const {post_idx} = param;
                    const temp = [];
                    this.posts.rows.forEach(element => {
                        if(element.idx != post_idx) temp.push(element);
                    });
                    console.log('temp>', temp)
                    this.posts.rows = temp;
                }
                break;
            case Const.notify.login:
                {
                console.log('onEvent', param);
                this.initView();
                }
                break;        
            case Const.notify.nickname:
                this.posts.rows.forEach(element => {
                    console.log('element', element)
                    if(element.persona_idx == param.idx){
                        element.name = param.name
                    }
                });

                break;
            default:
                break;
            }
        },
        didLogin(user){
            console.log('didLogin', user);

            this.me = user;         
            this.requestPosts();

            this.$refs.titlebar.didLogin();
            this.$refs.titlebar.updateButtonState()
        },      
        notLogin(){        
            
        },
        onLeft(){
            common.back();
        },
        onScrollTop(){
            console.log('onScrollTop')

            switch (this.selectedTab) {
            case str_channels:
                if(this.channels.new) return // 이미 있다.                            
                this.checkNewChannel()
                break;                
            case str_posts:
                if(this.posts.new) return // 이미 있다.            

                this.checkNewPosts()
                break;
            default:
                break;
            }
        },
        onScrollBottom(){
            switch (this.selectedTab) {
            case str_channels:
     
                break;
            case str_posts:
                {
                    const start = this.posts.rows.length > 0 ? this.posts.rows[this.posts.rows.length - 1].idx : 0;
                    this.requestPosts(start);
                }
                break;
            default:
                break;
            }
        },
        onScrollEnd: function(){
            console.log('onScrollEnd');
            
            if(this.needs_more === false) return;    // 더 이상 레코드 없으면...

            const length = this.recent.rows.length;
            if(length == 0) return;

            const lastIdx = this.recent.rows[length-1].idx;

            this.request(lastIdx);

            // test code
            // this.recent.rows = this.recent.rows.concat(this.recent.rows);
        },
        onNavbar: function(button){
            console.log('onNavbar', button);
            switch (button) {
                case 'search':
                    this.$emit('event', 'home');
                    break;
                case 'login':
                    {
                    const param = {
                        didLogin: this.initView
                    }
                    common.popupLogin(param)
                    }
                    break;
                case 'bookmark':
                    this.onLike();
                    break;
                case 'share':
                    {
                    console.log('this', window.location);
                    const param = {
                        url: `${window.origin}/?/local/${this.idx}`,
                    } ;
                    console.log('param', param);

                    common.popUp({
                        name: Const.component.QrcodeVue,
                        param
                    })
                    }
                    break;
                case 'write':
                    this.onWrite();       
                break;
            
                default:
                break;
            }
        },
        onWrite(){
            console.log('this.me', this.me);
            if(this.me)
            {
                const param = {
                    board_idx: this.idx,
                    idx: 0,
                }
                const obj = {
                    name: Const.component.PostForm,
                    param
                }
                common.popUp(obj);
            }
            else // 로그인을 한번도 한적이 없는 경우 
            {
                common.popupLogin();
            }         
        },
        onHome(){
            this.$emit('event', 'home');
        },
        onLike(){
            console.log('onLike', this.board.isLike);

            {
                const url = Const._api + "board/like";
                const param = {
                board_idx: this.idx,
                like: !(this.board.isLike),
                }
                common.api_post(url, param, data => {
                    console.log('data', data)

                    const {error, msg} = data; 
                    if(error == 100)    // not login
                    {
                        common.tryLogin();
                        return;
                    }
                    else if(error > 0){
                        console.log('error', error);
                        this.$emit('error', error, msg);      
                        return;
                    }
                    // this.$emit('done');
                    this.board.isLike = !(this.board.isLike) ? 1 : 0;

                    common.notifyEvent(Const.notify.bookmark)
                })
            }          
        },
        onTabMenu: function(menu, param){
            this.selectedTab = menu;
            console.log('index.selectedTab', menu, param);

            switch (menu) {
                case str_channels:
                    if(this.channels.length == 0) this.requestChannel();                    
                break;
                case str_posts:
                    
                    break;        
                default:
                break;
            }
        },
        onPost(idx){
            common.push({
                name: Const.component.PostView,
                param: {idx}
              })
        },
        onPostView(tag, param){
            console.log('onPostView', tag, param);
            switch (tag) {
            case 'close':
                this.is_show_post_page = false;              
                break;
            
            default:
                break;
            }
        },
        onDone: function(){
            console.log('onDone');
            this.recent.rows = [];
            this.request(0);
        },
        onError: function(error, param){
            console.log('onError', error, param);

            // todo : notlogin 처리 / 리책토링

            common.toast(param)
        }
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>


.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

</style>>

