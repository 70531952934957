<template>
    <div class="scroll-container">
        <title-bar-ex ref="titlebar" :title="'문의하기'" :container="container" :right="{}" />
        <div class="margin-side">
            <!-- <input-box v-if="false" ref="input_box" :data="{title:'', comment: '200자 이내'}" v-on:entered="onDone" v-on:changed="onChanged" /> -->
            <auto-resize ref="textarea" class="post-font-family " style="width: 100%; margin-top: 10px; " :placeholder="''" :autoresize="true" v-on:input="onChanged" />
            <text-button :data="{title: '완료', onclick: onDone}" />
            <cell-container v-for="(item, index) in rows" v-bind:key="index" :data="item">
                <div class="center-v" style="word-break: break-all; white-space: pre-wrap;">
                    <div v-if="item.is_owner" style="color: blue; font-weight: bold;">답변 : {{ item.message }}</div> 
                    <div v-else>{{ item.message }}</div> 
                </div>
            </cell-container>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TitleBarEx from './common/TitleBarEx.vue'
import CellContainer from './common/CellContainer.vue'
// import InputBox from './common/InputBox.vue'
import AutoResize from './common/AutoResize.vue'
import TextButton from './common/TextButton.vue'

export default {
    components: {
        TitleBarEx,
        CellContainer,
        // InputBox,
        AutoResize,
        TextButton,
    },
    props: {
      param: Object,
      container: Object,
    },
    setup() {
      // console.log('board setup');
      return {
      }
    },
    data(){ 
      return {
        // param: '', // url로 넘어온 파라미터
        ad_idx: '',
        rows: [],
        last: 0,
        total: 0,
        needs_more: true, // 더 읽어올 데이터가 있는가에 대한...

        form: {}, // post용 데이터
        message: '',
      }
    },    
    created() {
        // this.param = common.param("idx");
    },
    mounted() {
        console.log('mounted')
        console.log('this.param', this.param)

        this.ad_idx = this.param.idx;

        this.$refs.titlebar.didLogin();
        this.$refs.titlebar.left = [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
        ]

        {
            const comp = {
                onEvent: this.onEvent,
            }
            common.registEventHandler(comp);
        }

        {
            const comp = {
                onEvent: this.onEvent,
                onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        this.request(0); 
    },
    methods:{
        onChanged(param){
            console.log('onChanged', param)
            if(param.length > 200){
                common.toast('200자까지 가능합니다.')
                this.message = param.substring(0, 200);
                this.$refs.textarea.setValue(this.message);
            }
            else{
                this.message = param;
            }
        },
        onDone(){
            const proc = (data) => {
                console.log('onSuccess', data)              

                const {error, msg} = data;

                if(error > 0){
                    common.popupModal({header: '', body: msg})
                    return;
                }

                this.message = '';
                // this.$refs.input_box.update('');
                this.$refs.textarea.setValue('')

                this.rows = [];
                this.request(0)

                // if(this.form.idx == 0)
                // {
                //     // 자신의 발행목록 업데이트
                //     const {idx} = data; 
                //     common.notifyEvent(Const.notify.post, {idx, title:this.form.title});
                // }
                // else
                // {
                //     common.notifyEvent(
                //         Const.notify.post_update, 
                //         {
                //             post_idx: this.form.idx,
                //         }
                //     );
                // }
            }

            const url = Const._api + "reply/post";
            this.form = {
                message : this.$refs.textarea.text, // this.message,
                ad_idx: this.ad_idx,
                is_owner: 0,                
            }
            const form = this.form;
            axios.post(url, form).then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });                    
        },
        request(start){             
            const url = `${Const._api}reply/list/${this.param.idx}/${start}`
            console.log(url)

            axios.defaults.withCredentials = true;        
            const proc = (data) => {
                console.log(data)
                const {rows} = data; // 신규, 본것

                console.log(rows)

                this.rows = this.rows.concat(rows);
            }
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });             
        },
        reload(){
            console.log('reload')
            this.request(0); 
        },
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {                
            // case Const.notify.post_update:
            //     this.reload()
                // break;
            default:
                break;
            }
        },
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.empty {
  height: 200px;
}

.scroll-container {
  /* height: calc(100vh - 140px); */
  position: sticky;
  height: 100vh;
}

.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

</style>

