<template>
  <navigation-controller :compName="compName" />
</template>

<script>
// import common from '@/common';
import NavigationController from '@/components/NavigationController.vue'
import Const from '@/const'
  
export default {
  components: {
    NavigationController
  },
  data() {
    return {
      // compName: Const.component.SearchMain
      compName: Const.component.MainView
    }
  },
  created() {
  },
  methods: {
  },
  mounted() {
    console.log('Home/app.vue')

    // 패스정보 가져와서 저장하기
    {
        // const url = Const._api + 'my/pass';

        // common.api_get(
        //     url, 
        //     res => {
        //       res;
        //       const {error, key} = res.data; 
        //       if(error == 0 && key)
        //       {
        //         localStorage.setItem('key', JSON.stringify(key));
        //         const json = localStorage.getItem('key');
        //         console.log('JSON.parse(json)', JSON.parse(json))
        //       }
        //     }
        // );  
    }    

  },
}
</script>

<style scoped>
@import '@/assets/common';

.top-page {
  position: absolute;
  z-index: 99;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.row {
    font-size: 100px;
    /* background: peachpuff; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background-color: red; */
}

</style>

