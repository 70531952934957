<template>
    <div id="main-container">
      <TitleBar ref="titlebar" :data="titleBar" v-on:left="close" ></TitleBar>

      <div class="margin-side">

        <div class="margin10">
          <!-- <textarea style="background:yellow; width: 100%; height: 50%; " placeholder="예의를 갖추지 않은 글은 삭제되며 활동을 정지 당할 수 있습니다." v-model="form.content" ></textarea> -->
          <!-- <auto-resize class="post-font-family " ref="textarea" style="width: 100%; " :placeholder="'예의를 갖추지 않은 글은 삭제되며 활동을 정지 당할 수 있습니다.'" :autoresize="true" /> -->

          <div style="margin-top: 10px;">입금은행 : {{ account.bank }}</div>            
          <div style="display: flex;">
              <div >계좌번호 : {{ account.num }}</div>
              <div @click="onCopy" style="color: dodgerblue; font-weight: bold; margin-left: 10px;">[복사]</div>
          </div>
          <div >예금주명 : {{ account.name }}</div>

          <div class="comment">
            <div>* 위 계좌로 입금 후 제출해주시면 확인이 가능합니다. (입금전에 제출하시면 확인불가)</div>
            <div>* 확인 후 최대 24시간안에 반영해드리고 메일을 보내드립니다.</div>
            <div>* 최대한 많은 리워드전달을 위해 무통장입금만 받고 있습니다</div>
          </div>
          
          <div style="margin-top: 20px;">
            <div class="input-container">            
              <div class=" comment-input">입금자명</div>
              <input v-model="form.name" type="text" placeholder=""  @keypress.enter="onEmail" required>    
            </div>

            <div class="input-container">
              <div class=" comment-input">입금일시</div>
              <input v-model="form.time" type="text" placeholder="날짜와 시간을 적어주세요"  required>    
            </div>

            <div class="input-container">
              <div class=" comment-input">입금액</div>
              <input v-model="amount" type="number" placeholder=""  required @input="onChange">    
            </div>

            <div class="input-container">
              <div class=" comment-input">필요시 메일로 연락을 드립니다.</div>
              <input v-model="form.email" type="text" placeholder="이메일주소"  required>    
            </div>

          </div>
          
        </div>

        <div v-show="show_submit" @click="onDone" style="text-align: center;" class="button-text-big">제출</div>

        <!-- 바틈메뉴 -->

        <div style="height:100px;"></div>        
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TitleBar from '@/components/common/TitleBar.vue'
// import AutoResize from './common/AutoResize.vue'

export default {  
  components: {
    TitleBar,
    // AutoResize,
  },
  props: {
      param: Object,
      controller: Object,
      close: Function,
  },
  computed: {
    // chips(){
    //   return this.insertComma(this.user.chips)
    // },
  },
  mounted() {
      const cb = (data) => {
        console.log('checkMe', data)

        const {error, user, msg}  = data;
        if(error > 0){
          const param = {
              header: '오류',
              body: msg,
          }
          common.popupModal(param);
          return;
        }

        Object.assign( this.user, user);
        this.form.email = user.email;
      }

      common.checkMe(common.checkResponse(cb))

      console.log('this.form.tags', this.form.tags);

      {
        const proc = (data) => {
            console.log(data)
            const {account} = data; // 신규, 본것

            this.account = account;
        }

        const url = `${Const._api}priceinfo`
        axios.get(url).then(common.checkResponse(proc))
        .catch(error => {
            console.error(error);
        });    
      }
  },
  data: function () {    
    return {  
      account: {},
      amount: 0,
      show_submit: true,
      warning_needs: '',
      needs: '0',
      user: {
          chips: 0
      }, 
      form:
      {
          name:'',
          email: '',
          time: '',
      },
      titleBar:{
          title: "충전하기",
          // right: '<div class="text-button-color">발행</div>',
          right: '<div></div>',
          left: '닫기'
      },
      show_input: false,
    };
  },
    methods: {      
        onCopy(){
            common.copyToClipboard(this.account.num);
            common.toast('계좌번호가 복사되었습니다.')
        },

        insertComma(val){
            const str = `${val}`
            var value = str.replace(/,/g, '');
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        onChange(){
            const reward = this.form.reward ? this.form.reward : 0;
            const amount = this.form.amount ? this.form.amount : 0;

            this.needs = this.insertComma(reward * amount) ;

            this.show_submit = true;
            if(reward * amount > this.user.chips){
                this.warning_needs = '충전금액이 부족합니다.'        
                this.show_submit = false;
            }
        },
    
        onDone: function(){
            var json = JSON.stringify(this.form);

            const onSuccess = (data) => {
                console.log('onSuccess', data)              
                this.close()

                if(this.form.idx == 0){
                    console.log(data)
                }
                else{
                    console.log(data)
                    common.notifyEvent(Const.notify.charge, {})
                }
            }

            const url = Const._api + "my/charge/req";
            common.api_post(url, {json, amount: this.amount} , data => {
                onSuccess(data)
            });
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.tb-btn {
  margin: 0 10px;
}

.hash-input {
  width: 100%;
  /* position: fixed; 
  bottom: 10px; */
}

#main-container {
  height: 100%;
    /* background: yellow; */
  background-color: white;
  /* display: flex;
  flex-direction: column; */
  /* height: 100%; */
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  overflow: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#main-container::-webkit-scrollbar {
    display: none;
}

.text-button > div {
  /* padding: 10px; */
  margin: 0;
}

.text-button > i {
  padding: 10px;
  /* padding-left: 15px; */
}

.flex {
  display: flex;
}

.bottom {
  overflow: hidden;
  /* background-color: #333; */
  position: fixed;
  bottom: 0;
  width: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* align-items: stretch; */
  background-color: #f1f1f1;
}

.image {
    height: 80px;
    background-color: pink;
}

.thumbnail {
    width: 90px; height: 90px;
}

.addfile{ 
  font-size: 13px;  
  padding:10px; 
  border-bottom: 1px solid #c0c0c0; 
}

.addfile > div{ 
  font-size: 12px; 
  background-color: #f8f8f8;  
  width: 100%; 
  height: 30px;  
  line-height: 30px; 
  text-align: center; 
  box-sizing: border-box; 
  margin-bottom: 10px;
}

.addfile > ul:after{content:""; display:block; clear:both; }
.addfile > ul > li {float: left; margin-right: 10px; position: relative; margin-bottom: 10px; }
.addfile > ul > li  > a{color: #eb5c53; line-height: 10px; padding: 8px; background-color: #fff; right: 0; position: absolute; }

.addfile .image > img {
    object-fit: cover;
    width:80px;
    height:80px;
}

.addfile .image {
    color: white;
    text-decoration: none;
    /* padding: 15px 26px; */
    position: relative;
    display: inline-block;
    border-radius: 2px;
    margin: 3px;
}
.image > span{
    position: absolute; 
    top:-4px;  
    right: -4px;
    position: absolute;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: black;
    color: white;
}

.post-container {
    /* border: 2px solid #ccc; */
    /* background-color: #eee; */
    border-radius: 5px;
    /* padding: 16px; */
    margin: 14px;
  }
  
.post-container::after {
  content: "";
  clear: both;
  display: table;
}

  
  /* .post-container img {
    float: left;
    margin-right: 20px;
    border-radius: 5%;
  } */
  
.post-container .info {
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.post-container span {
  font-size: 20px;
  /* margin-right: 15px; */
}


/* .post-container .hash {
  background: yellow;
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
} */

.post-container .like {
  /* background: yellow; */
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}

.txt_post { 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: box; 
  margin-top:1px; 
  max-height: 180px; 
  overflow:hidden; 
  vertical-align:top; 
  text-overflow: ellipsis; 
  word-break:break-all; 
  -webkit-box-orient:vertical; 
  white-space: pre-wrap;   
  /* 3줄 */
  -webkit-line-clamp:3;   
}

.needs {
  background: ghostwhite;
  font-weight: bold;
}

</style>

