<template>
    <div ref="bar" class="center cell-button  bar" 
        @click="onClick">
        <div :class="is_new ? 'text-button-color' : 'color-gray'">{{text}}</div>
    </div>
</template>

<style>
@import '@/assets/common';

</style>
<style scoped>

.bar{
    height: 0;
    overflow: hidden;
    /* -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;     */

    -webkit-transition: height 0.3s ease-in-out;
    transition: height 0.5s;
}

</style>

<script>
export default {  
    props:{
        request: Function,
    },
    mounted() {
        // console.log('props')
        // setTimeout( () => { 
        //     this.alert(false);
        // }, 1000); 
    },
    data: function () {
      return {  
        is_new: true,
        text: ''
      };
    },
    methods: {
        onClick(){
            if(this.is_new == false) return;

            this.is_new = false; 
            this.$refs.bar.style.height = '0';
            this.request();
        },
        show(has){
            setTimeout( () => { 
                this.$refs.bar.style.height = has ? '30px' : '0';
            }, 1); 
        },
        alert(has){
            this.is_new = has;
            if(has){
                this.text = '새로운 글'
                this.$refs.bar.style.height = has ? '30px' : '0';
                // this.show(has)
                // this.$refs.bar.style.height = '100px';
            }
            else{
                this.text = '더 이상 없습니다.'

                // this.show(has)
                // 살짝 보여주고 감준다.
                this.$refs.bar.style.height = '30px';
                setTimeout( () => { 
                    this.$refs.bar.style.height = '0';
                }, 500); 
            }
        }
    },      
}

</script>

