<template>
    <div >
        <div style="white-space: pre-line;" >{{ data.title }}</div>
        <div v-html="data.html"></div>
        <div style="margin-top: 5px;">
            <input 
                v-model="value" 
                :placeholder="data.placeholder" 
                :readonly="data.isReadonly" 
                :class="data.isReadonly ? 'readonly' : ''" 
                :style="style"
                :type="input_type"
                @input="onChange" 
                @keypress.enter.prevent="onEnter" 
            />
        </div>
        <div style="color: gray;">{{ data.comment}}</div>
    </div>
</template>

<style scoped>
@import '@/assets/common';

.readonly {
    background: ghostwhite;
    border: 1px solid white;
}

input{
    box-sizing: border-box; 
}

</style>

<script>
// import common from '@/common'
// import constVal from '@/const'

export default {  
    props:{
      data:Object,
      width:String,
      type:String,
    },
    data() {
      return {
        value: null,  
        style: {},
        input_type: 'text',
      };
    },
    mounted() {
        console.log('mounted')
        this.value = this.data.value;

        this.input_type = this.type ? this.type : 'text';

        if(this.width){
            this.style.width = this.width;
        }
    },
    methods: {
        update(value){
            this.value = value;
        },
        onEnter(){
            console.log('onEnter')
            this.$emit('entered', this.value)            
        },
        onChange(){
            this.$emit('changed', this.value)            
        },
        click(param){
            param;
            this.$emit('clicked', this.data)
        },
    },      
}
</script>

