<template>
    <div class="tagbar" style="width: 100%;">
        <a :class="selected == index ? 'tag-selected' : 'tag-nomal' " v-for="(item, index) in data" v-bind:key="index" @click="click(item, index)" v-html="item"></a>    
    </div>  
</template>

<style scoped>
@import '@/assets/common';
.tagbar {    
    margin-top: 1px;
    overflow: auto;
    white-space: nowrap;
    background: white;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.tagbar::-webkit-scrollbar {
    display: none;
}

</style>

<script>
export default {  
    props:{
      data:Array,
      id: String,
    },
    mounted() {
        console.log('data', this.data)
    },
    data: function () {
      return {  
        //   name: value
        selected: 0,
      };
    },
    methods: {
      click:function(data, n){
          this.selected = n;
          this.$emit('clicked', data, n)
      },
      setFocus(index){
        this.selected = index;
      }
    },      
}

</script>

