<template>
  <div class="background-color">
    <!-- 슬라이드 컨테이너 -->
    <SlideContainer v-for="item in comps" 
      :key="item.index" 
      :comp="item" 
      :controller="self" 
      :index = "comps.length - 1" 
    />

    <!-- 팝업 -->
    <popup-controller ref="popupController" />
    
    <snackbar ref="snackbar"></snackbar>
    <ContextMenu ref="contextmenu" style="z-index: 99"></ContextMenu>
    <FullImage ref="fullImage" style="z-index: 99"></FullImage>
  </div>
</template>

<script>
import SlideContainer from './SlideContainer.vue'
import PopupController from '@/components/common/PopupController.vue'
import snackbar from '@/components/common/SnackBar.vue'
import ContextMenu from '@/components/common/ContextMenu.vue'
import FullImage from '@/components/FullImage.vue'


export default {
//   name: 'NavigationController',
  components: {
    SlideContainer,
    PopupController,
    snackbar,
    ContextMenu,
    FullImage,
  },
  props: {
    compName: String
  },
  data() {
    return {
      comps: [],
      popups: [],
      self: null,
    }
  },
  created(){
    this.self = this;
  },
  mounted() {    
    this.snackbar = this.$refs.snackbar;
    this.popupController = this.$refs.popupController;
    
    // 넣기
    {
      if(!window.navigationControllers){
        window.navigationControllers = [];
        window.lastController = () => {
          return window.navigationControllers.length > 0 ? window.navigationControllers[window.navigationControllers.length - 1] : null;
        }
      }
      window.navigationControllers.push(this);

      // console.log(window.navigationControllers)
    }

    // window.navigationController = this;

    this.comps.push({
      name: this.compName,
      show: true,
      animation: true,  // 새로 추가
    })
  },
  methods: {
    showImage(url){
      this.$refs.fullImage.show(url);
    },
    showMenuEx(obj){
      console.log('showMenuEx');
      this.$refs.contextmenu.showEx(obj);
    },
    showMenu(arr){
      // console.log('showMenu');
      this.$refs.contextmenu.show(arr);
    },
    toast(msg){
      this.snackbar.show(msg);
    },
    pushSlideContainer(container){
      const comp = this.lastComp();
      comp.container = container
    },
    notifyEvent(name, param){
      // console.log('notifyEvent', name, param)

      this.comps.map( comp => {
        if(comp.onEvent){
          comp.onEvent(name, param);
        }
      })
    },
    componentMounted(obj){
      // console.log('componentMounted', obj)
      const {onShow, onHide, onEvent} = obj;
      const last = this.lastComp();

      if(last){
        last.onShow = onShow;
        last.onHide = onHide;
        last.onEvent = onEvent;
      }
    },
    lastComp(){
        if(this.comps.length == 0) return null;

        return this.comps[this.comps.length-1];
    },
    closeAllPop(){
      this.popupController.closeAll()
    },
    closePopup(){
      this.popupController.closePopup();
    },
    popUp(comp){
      // comp.param.controller = this;
      // this.popups.push(comp)      
      // console.log('this.popupController', this.popupController);

      this.popupController.push(comp)
    },
    back(){ // 마지막 컴포넌트를 애니메이션 효과로 제거한다. removeLastComp는 바로 제거하는 것과 차이
      // console.log('back')

      if(this.comps.length == 1) return false;

      const comp = this.lastComp();
      comp.container.back();
    },
    push(comp){
        const last = this.lastComp();

        last.animation = false;

        if(last && last.onHide){
          last.onHide();
        }

        comp.animation = true;
        comp.pre = last;

        this.comps.push(comp)  
        // console.log('this.comps', this.comps);
    },
    removeLastComp(){
        // console.log('removeLastComp');
        this.comps.splice(this.comps.length - 1, 1); 

        const last = this.lastComp();
        last.show = true;
        last.animation = true;

        if(last &&  last.onShow) last.onShow();
    },
    home(){
      return this.comps[0];
    },    
    goHome(){
      console.log('goHome')
      if(this.comps.length == 0) return;

      const home = this.comps[0];

      if(home){
        home.show = true;

        this.comps.splice(1, this.comps.length - 1); 

        home.animation = true;

        if(home.onShow) home.onShow();
      } 
    },
    onAfterLeave(){
      // console.log('onAfterLeave', el);
    },
    onPopupEvent(tag, param){
      console.log('onPopupEvent', tag, param);
      {
        param
      }

      switch (tag) {
        case 'back':
          break;
      
        default:
          break;
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '@/assets/common';

.pop {
  position: fixed;
  top: 0;
  /* left: 0; */
  width: 100%;
  height: 100vh;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
