const device = () => {
    var userAgent = navigator.userAgent;

    // iOS인지 Android인지 확인
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
        // iOS 디바이스입니다.
        console.log("iOS 디바이스입니다.");
        return 'iOS'
    } else if (/Android/i.test(userAgent)) {
        // Android 디바이스입니다.
        console.log("Android 디바이스입니다.");
        return 'Android'
    } else {
        // iOS나 Android가 아닌 다른 디바이스일 수 있습니다.
        console.log("iOS나 Android가 아닌 다른 디바이스입니다.");
        return '';
    }
}

// 화페단위로 콤마 넣는다.
const insertComma = (val) => {
    const str = `${val}`
    var value = str.replace(/,/g, '');
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


export default {
    device,
    insertComma,
}