<template>
  <div>
    <transition name="slide-up" @after-leave="onAfterLeave">
        <div class="center" v-show="show" style="height: 100%;">
            <component class="center"
                v-bind:is="param.name" 
                :param="param.param" 
                :close="close"
                v-on:event="onPopupEvent" 
            />
        </div>
    </transition>    
  </div>
</template>

<script>
import HomeView from '@/components/HomeView.vue'
import SlideContainer from '@/components/SlideContainer.vue'
import PostForm from '@/components/PostForm.vue'
import ChargeForm from '@/components/ChargeForm.vue'
import DistForm from '@/components/DistForm.vue'
import DepositForm from '@/components/DepositForm.vue'
import ExchangeForm from '@/components/ExchangeForm.vue'
import LoginForm from '@/components/LoginForm.vue'
import InputForm from '@/components/InputForm.vue'
import ModalDialog from '@/components/common/ModalDialog.vue'
import QrcodeVue from '@/components/QrcodeVue.vue'
import SearchPage from '@/components/SearchPage.vue'
import JoinForm from '@/components/JoinForm.vue'
import EmailForm from '@/components/EmailForm.vue'
import AccountForm from '@/components/AccountForm.vue'
import ParentForm from '@/components/ParentForm.vue'
import NaverForm from '@/components/NaverForm.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'
import ChildListView from '@/components/ChildListView.vue'
import BoxView from '@/components/BoxView.vue'
import SlideNavigator from '@/components/SlideNavigator.vue'
import SendAuth from '@/components/SendAuth.vue'
import PhoneReg from '@/components/PhoneReg.vue'


export default {
//   name: 'NavigationController',
  components: {
    HomeView,
    SlideContainer,
    PostForm,
    ChargeForm,
    DistForm,
    DepositForm,
    ExchangeForm,
    LoginForm,
    InputForm,
    ModalDialog,
    QrcodeVue,
    SearchPage,
    JoinForm,
    EmailForm,
    AccountForm,
    ParentForm,
    NaverForm,
    ForgotPassword,
    ChildListView,
    BoxView,
    SlideNavigator,
    SendAuth,
    PhoneReg,
  },
  props: {
    param:Object,
  },
  data() {
    return {
      container:null,
      controller: null,
      show: false,
    }
  },
  created(){
  },
  mounted() {        
    this.container = this;
    this.controller = this.param.controller;
    setTimeout(() => { 
      this.show = true;
    }, 1);
  },
  methods: {
    close(){
      this.show = false;
      this.controller.onBeforeLeave()
    },
    pushSlideContainer(container){
      const comp = this.lastComp();
      comp.container = container
    },
    notifyEvent(name, param){
        name, param
    },
    componentMounted(obj){
      const {onShow, onHide, onEvent} = obj;
      const last = this.lastComp();

      if(last){
        last.onShow = onShow;
        last.onHide = onHide;
        last.onEvent = onEvent;
      }
    },
    lastComp(){
        // 마지막
    },
    onAfterLeave(){
      this.controller?.onAfterLeave()
    },
    onPopupEvent(tag, param){
      console.log('onPopupEvent', tag, param);
      {
        param
      }

      switch (tag) {
        case 'back':
          break;
      
        default:
          break;
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '@/assets/common';

.pop {
  position: fixed;
  top: 0;
  height: 100vh;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* trnasition */
/* 진입/진출 애니메이션은 다른 durantion 및 */
/* 타이밍 기능을 사용 할 수 있습니다.   */
.slide-up-enter-active {
  transition: all 0.3s ease-out;
}

.slide-up-leave-active {
  /* transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); */
  transition:  0.3s 
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(700px);
  opacity: 0;
}

</style>
