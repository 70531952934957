<template>
  <div id="main-container">
    <TitleBar ref="titlebar" :data="titleBar" v-on:left="close" v-on:right="onDone"></TitleBar>

    <div class="margin-side">
      <div class="margin10">

        <div style="width: 80%; margin: 0 auto;">
          <input-box ref="input_title" :data="input_title" />
          <input-box ref="input_reward" :data="input_reward" style="margin-top: 20px;" />
          <input-box ref="input_amount" :data="input_amount" style="margin-top: 20px;" />

          <div class="margin-top10"></div>

          <input-box ref="input_total" :data="input_total" style="margin-top: 20px;" />

        </div>
      </div>

      <text-button :data="{title: '신청', onclick: onDone}" />
      <text-button :data="{title: '삭제', onclick: onDel, warning: true}" />

      <!-- 바틈메뉴 -->

      <div style="height:100px;"></div>        

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TitleBar from '@/components/common/TitleBar.vue'
import InputBox from './common/InputBox.vue'
import TextButton from './common/TextButton.vue'
// import AutoResize from './common/AutoResize.vue'

export default {  
components: {
  TitleBar,
InputBox,
TextButton,
  // AutoResize,
},
props: {
  param: Object,
  controller: Object,
  close: Function,
},
computed: {
},
mounted() {
  console.log('mounted', this.param)
  const {idx, title} = this.param;
  this.idx = idx;
  // this.reward = reward;
  this.title = title;

  this.$refs.input_title.value = title;
  
  this.request();
},
data: function () {    
  return {  
    input_title:{
      title : '제목',
      isReadonly: true,
    },
    input_reward:{
      isReadonly: true,
      title : '사용자 리워드 / 1건',
      value: 10,
      comment: '사용자가 확인하고 받는 리워드입니다.'
    },
    input_amount:{
      isReadonly: true,
      title : '배포량',
      comment: '메시지를 받을 최대인원입니다. 배포완료후 추가배포가 가능합니다.'
    },
    input_fee:{
      value: 1,
    },
    input_price:{
      title : '총액',
      isReadonly: true,
    },
    input_total:{
      title: '입금액(부가세 포함)',
      isReadonly: true,
      comment: '배포를 위한 입금액입니다. 사용자에게 최대한 많은 리워드 돌아가도록 각종 수수료지출을 줄일 수 있는 계좌송금만 가능합니다.'
    },

    idx: 0, 

    needs: '0',
    total: 0,
    show_submit: true,
    warning_needs: '',
    account: '', // 입금정보
    title: '',
    titleBar:{
        title: "배포신청",
        // right: '<div class="text-button-color">발행</div>',
        right: '<div></div>',
        left: '닫기'
    },
    show_input: false,
  };
},
  methods: {    
    request(){
        axios.defaults.withCredentials = true;        
        const proc = (data) => {
            console.log(data)
            const {reward, amount, total} = data; // 신규, 본것

            this.$refs.input_reward.update(reward)

            this.$refs.input_total.update(total);

            this.$refs.input_amount.update(amount);
        }

        const url = `${Const._api}priceinfo`
        axios.get(url).then(common.checkResponse(proc))
        .catch(error => {
            console.error(error);
        });    
      },
      onDel(){
        console.log('onDel')
        const arr = [
          {
            title: '정말 삭제합니다.',
            style: 'color: red;',
            cb: () => {
              const proc = (data) => {
                  console.log(data)
                  const {error, msg, affected} = data;
                  if(error == 0){
                      if(affected > 0){
                        common.notifyEvent(Const.notify.post_del)
                      }

                      this.close();
                  }else{
                    const param = {
                        header: '오류',
                        body: msg,
                        // callback
                    }
                    common.popupModal(param)
                  }
              }
              const url = `${Const._api}my/del`;
              axios.post(url, { idx: this.idx } )
              .then( common.checkResponse(proc))
              .catch(error => {
                  console.log(error);
              });                            
            }
          }
        ]
        common.showMenu(arr)                
      },
      insertComma(val){
          const str = `${val}`
          var value = str.replace(/,/g, '');
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      onDone: function(){
          const proc = (data) => {
              console.log('onSuccess', data)

              const {error, msg} = data;
              if(error == 0){
                  // common.toast('추가 배포가 시작됩니다.');
                  this.close();

                  common.notifyEvent(
                      Const.notify.dist_done,
                      {idx: this.idx},
                  );
              }
              else{
                  // common.toast(msg);
                  this.warning_needs = msg;
              }
          }

          axios.post(
            Const._api + 'my/pay/add', 
            {
              ad_idx:this.idx, 
              reward:this.$refs.input_reward.value, 
              amount: this.$refs.input_amount.value,
            }
          )
          .then(common.checkResponse(proc))
          .catch(error => {
              console.log(error);
          });                    
      },
  },      
}

</script>

<style scoped>
@import '@/assets/common';

.tb-btn {
margin: 0 10px;
}

.hash-input {
width: 100%;
/* position: fixed; 
bottom: 10px; */
}

#main-container {
height: 100%;
  /* background: yellow; */
background-color: white;
/* display: flex;
flex-direction: column; */
/* height: 100%; */
/* position: absolute; */
/* top: 0;
bottom: 0;
left: 0;
right: 0; */
overflow: scroll;

-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

#main-container::-webkit-scrollbar {
  display: none;
}

.text-button > div {
/* padding: 10px; */
margin: 0;
}

.text-button > i {
padding: 10px;
/* padding-left: 15px; */
}

.flex {
display: flex;
}

.bottom {
overflow: hidden;
/* background-color: #333; */
position: fixed;
bottom: 0;
width: 100%;
}

.flex-container {
display: flex;
flex-direction: column;
/* height: 100vh; */
/* align-items: stretch; */
background-color: #f1f1f1;
}

.image {
  height: 80px;
  background-color: pink;
}

.thumbnail {
  width: 90px; height: 90px;
}

.addfile{ 
font-size: 13px;  
padding:10px; 
border-bottom: 1px solid #c0c0c0; 
}

.addfile > div{ 
font-size: 12px; 
background-color: #f8f8f8;  
width: 100%; 
height: 30px;  
line-height: 30px; 
text-align: center; 
box-sizing: border-box; 
margin-bottom: 10px;
}

.addfile > ul:after{content:""; display:block; clear:both; }
.addfile > ul > li {float: left; margin-right: 10px; position: relative; margin-bottom: 10px; }
.addfile > ul > li  > a{color: #eb5c53; line-height: 10px; padding: 8px; background-color: #fff; right: 0; position: absolute; }

.addfile .image > img {
  object-fit: cover;
  width:80px;
  height:80px;
}

.addfile .image {
  color: white;
  text-decoration: none;
  /* padding: 15px 26px; */
  position: relative;
  display: inline-block;
  border-radius: 2px;
  margin: 3px;
}
.image > span{
  position: absolute; 
  top:-4px;  
  right: -4px;
  position: absolute;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: black;
  color: white;
}

.post-container {
  /* border: 2px solid #ccc; */
  /* background-color: #eee; */
  border-radius: 5px;
  /* padding: 16px; */
  margin: 14px;
}

.post-container::after {
content: "";
clear: both;
display: table;
}


/* .post-container img {
  float: left;
  margin-right: 20px;
  border-radius: 5%;
} */

.post-container .info {
font-size: 14px;
float: left;
color: darkgray;
margin-top: 0;
margin-bottom: 0;
}

.post-container span {
font-size: 20px;
/* margin-right: 15px; */
}


/* .post-container .hash {
background: yellow;
font-size: 14px;
float: left;
color: darkgray;
margin-top: 0;
margin-bottom: 0;
} */

.post-container .like {
/* background: yellow; */
font-size: 14px;
float: left;
color: darkgray;
margin-top: 0;
margin-bottom: 0;
margin-right: 4px;
}

.txt_post { 
display: -webkit-box; 
display: -ms-flexbox; 
display: box; 
margin-top:1px; 
max-height: 180px; 
overflow:hidden; 
vertical-align:top; 
text-overflow: ellipsis; 
word-break:break-all; 
-webkit-box-orient:vertical; 
white-space: pre-wrap;   
/* 3줄 */
-webkit-line-clamp:3;   
}

.needs {
background: ghostwhite;
font-weight: bold;
}

</style>

