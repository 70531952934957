<template>    
    <div >
        <!-- <div class="not-found">         -->
        <!-- <div v-if="result.not_found" class="not-found">                    
            <div class="between">
                <div />                
                <div>'{{result.location_name}}'에 '{{result.keyword}}' 채널이 없습니다.</div>                
                <div />
            </div>

            <div class="between">
                <div />
                <div>채널을 만들고 가장 먼저 글을 남겨보세요.</div>
                <div />
            </div>

            <div class="between">
                <div /><button class="mkbtn" @click="doMake">글쓰기</button><div />
            </div>
        </div> -->

        <lets-write 
            v-if="result.not_found"
            v-on:clicked="doMake()" 
            :text="`'채널이 없습니다.\n채널을 만들고 가장 먼저 글을 남겨보세요.`"
        />

        
        <!-- 정확한 결과 -->
        <BoardCell v-if="result.exact" :data="result.exact"  v-on:clicked="onBoard" />

        <!-- 비슷한 결과 -->
        <!-- <div v-if="result.similar.length > 0" >비슷한 채널</div> -->
        <div v-if="result.similar.length > 0" class="margin10 margin-top20" style="font-size:14px;" >그밖의 채널</div>
        <BoardCell
            v-for="(item, index) in result.similar" v-bind:key="index"
            :data="item" 
            v-on:clicked="onBoard" 
        />
    </div>
</template>

<script>
  import common from '@/common'
  import BoardCell from '@/components/common/BoardCell.vue'
  import Const from '@/const'
  import LetsWrite from './common/LetsWrite.vue';
    
  export default {
    components: {
        BoardCell,
        LetsWrite,
    },
    props: {
      param: Object
    },
    setup() {
      console.log('setup');
      return {        
      }
    },
    data() { 
      return  {
        comment: '주변사람들과 이야기하는 채널입니다.',
        result: {
            location_name: '',
            not_found: false,
            exact: {},
            similar: [],
            keyword: '',
        },
      }
    },
    created: function () {
      // console.log('created')
    },
    mounted: function() {   
    },        
    computed: {
    },
    methods:{              
        update(obj){
            this.result = obj;
        },
        onBoard(item){
            common.push({
            name: Const.component.SavedView,
            param: {idx: item.idx},
            })
        },      
        doMake(){
            console.log('doMake', this.result.keyword);
            
            const url = Const._api + "board/make";
            const body = {
                text: this.result.keyword,
                around: true
            }

            common.api_post(url, body, data => {
                // console.log(data);
                const {error, msg} = data;
                if(error > 0){
                    common.toast(msg)            
                }
                else{
                    const {board} = data.row;

                    common.push({
                        name: Const.component.SavedView,
                        param: {
                            idx: board.idx,
                            write: true,
                        } 
                    })

                    console.log('made', this)
                    this.$emit('made')
                }
            })        
        },
    },    
  }
</script>

<style scoped>
@import '@/assets/common';

.comment {
  /* background: orange; */
  padding: 5px;
  text-align: center;
  font-size: 14px;  
  color: darkgray;
}
.top-container {
    position: absolute;
    top: 0;    
    height: 100vh;
    width: 100%;
    background: white;

    /* display: flex;        
    flex-direction: column; */
}

.tail {
    width: 100%; 
    height: 80px; 
    /* background: red; */
}

</style>

