<template>
  <div style="background: white; ">
    <div class="between margin-top10 padding10">
      <div />
      <button type="button" class="btn color-gray" @click="close()">닫기</button>
    </div>

    <!-- <div class="center-text" style="margin-top: 30px;margin-bottom: 30px" v-html="Abouting" />     -->

    <form class="login-container" style="margin-top: 10%;">
      <div class="margin-bottom10" v-show="step == 1">
        <div class="container">
          <div class=" comment-input">1. 사용할 네이버페이 아이디를 입력하세요.</div>
          <div class="warning-color font-size12" style="margin-bottom: 5px;">한번 등록하면 수정이 안됩니다. 신중히 입력하시기 바랍니다.</div>
          <input v-model="naver_id" type="text" placeholder="네이버페이 아이디" name="email" @keypress.enter="onID" required>    
          <div class="warning-color font-size12">{{warning}}</div>
        </div>

        <button type="button" class="btn text-button-color font-size12 " @click="onID">인증번호 받기</button>
      </div>

      <div v-show="step == 2">
        <div class="container">
          <div class="comment-input">2. {{naver_id}}@naver로 전달된 인증번호를 입력하세요.</div>
          <input v-model="authcode" type="number" placeholder="인증번호 6자리" name="email" @keypress.enter="onConfirm" required>    
          <div class="warning-color font-size12">{{warning}}</div>
        </div>

        <button type="button" class="btn text-button-color font-size12 " @click="onConfirm">확인</button>
      </div>

    </form>

    <div class="between padding10">
      <div />
      <div class="comment-input text-button-color">
        <!-- <div style="text-align:right" @click="onUser">사용자약관</div>
        <div @click="onPrivacy">개인정보 처리방침</div> -->
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
// import JoinForm from './JoinForm.vue'

export default { 
  components: {
    // JoinForm,
  }, 
    props:{
      param:Object,
    },
    mounted() {
      // console.log("LoginForm.mounted", this.param)
    },
    data: function () {
      return {  
        //   name: value
        step: 1,
        naver_id: '',
        pw: '',
        authcode: '',
        comment: '',
        warning: '',
        is_join: false,
        Abouting: `<div class="" style="font-size:24px; font-weight: 900;">${Const.about}</div>`
      };
    },
    methods: {
        onConfirm(){ // 인증번호 입력
            this.warning = '';

            const proc = (data) => {
                if(data.error == 0){
                    console.log('인증완료')
                    common.notifyEvent(Const.notify.email, {email: this.naver_id})

                    const arr = [
                        {
                            title: '등록완료',
                            cb: () => {
                                // todo : notify. emailUpdated
                                this.close();
                            }
                        },
                    ]
                    common.showMenu(arr)
                }else{
                    console.log('인증실패')

                    var {error, msg} = data;
                    if(error > 0)
                    {
                        this.warning = msg;
                    }                    
                }
            }

            axios.post(Const._api + 'npay/res', {authcode: this.authcode} )
            .then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });                      
        },
        onID(){
            this.warning = '';

            if(this.naver_id.includes("@"))
            {
                this.warning = '아이디만 입력하세요'
                return;
            }            

            this.naver_id = this.naver_id.trim();
            if(this.naver_id.length == 0){
                return;
            }

            // 이메일 인증번호 요청
            const proc = (data) => {
                console.log(data);
                const {error, msg} = data;
                if(error == 0){
                    this.step = 2;
                }else{
                    console.log('전달실패')

                    this.warning  = msg;
                    this.$emit('clicked', 'failed', msg);
                    return;
                }
            }

            const url = Const._api + 'npay/req'
            const nid = this.naver_id
            axios.post(url, {nid} )
            .then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });      
        },      
        onEnter(id){
            console.log('onEnter', id)
            switch (id) {
            case 'id':
                // this.$refs.pw.focus();
                break;
            case 'pw':
                // this.login();
                break;        
            default:
                break;
            }
        },
        show: function(){
            this.email = '';
            this.pw = '';

            // todo : 로컬스토리지에 있다면 바로 시도한다.            
        },
        close(){
          this.param.container.close();
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sns > img {
  width: 166px;
  /* height: 40px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}
.container {
  display: flex; 
  flex-direction: column; 
  margin-left: auto; 
  margin-right: auto; 
  width: fit-content;
}

.join {
  display:flex; 
  margin-right: 0; 
  width: fit-content;
}

.open-button {
  /* background-color: #555; */
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  /* position: fixed; */
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.login-container {
    top:0;
    bottom: 0;
    /* right: 15px; */
    z-index: 9;
    max-width: 100%;
    padding: 10px 30px;
    /* height: 100vh; */
    background-color: white;
}

.login-container .btn {
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}
  
  /* Full-width input fields */
.login-container input[type=text], .login-container input[type=email], .login-container input[type=number] {
  width: 270px;
  height: 38px;

  /* margin-bottom: 10px; */
  margin-left: auto;
  margin-right: auto;
    /* padding: 15px; */
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
  /* margin: 5px 0 22px 0; */
  /* border: none; */
}
  
/* When the inputs get focus, do something */
.login-container input[type=text]:focus, .login-container input[type=password]:focus {
  /* background-color: #ddd; */
  outline: none;
}

input::placeholder {
    /* opacity: .5; */
    color: rgb(128, 128, 128);
    /* padding-left: 10px; */
}

.warning {

}

</style>

