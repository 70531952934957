<template>
    <div class="qr-container">
        <qrcode style="margin:10px" :value="param.url" :size="size" level="H" />
        <!-- <div class="button" @click="onCopy">주소복사</div> -->
        <br>
        <button type="button" class="btn text-button-color" @click="onCopy">주소복사</button>
        <!-- <div @click="onClose">닫기</div> -->
        <div class="comment" v-html="param.comment" />
        <br><br>
        <button type="button" class="btn cancel" @click="onClose">닫기</button>
    </div>
</template>

<style scoped>
@import '@/assets/common';

.qr-container {
    text-align:center;
    /* margin: 10px; */
    background: white;
    width: 100%;
    height: 100vh;
    padding: 70px 0;
}
</style>

<script>
import common from '@/common'
import qrcode from 'qrcode.vue'

export default {  
    components: {
        qrcode,
    },
    props:{
        param: Object,
        close: Function,
    },
    mounted() {
        console.log('data', this.data)
    },
    data: function () {
      return {  
        size: 200,          
      };
    },
    methods: {
        onCopy(){
            common.copyToClipboard(this.param.url);
            common.toast('복사되었습니다.')
        },
        onClose(){
            this.close();
        }
    },      
}
</script>

