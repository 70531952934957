<template>
  <div >
    <!-- <title-bar-ex ref="titlebar" :data="titleBarConfig"/> -->
    <title-bar-ex ref="titlebar" :title="title" :container="container" :right="{}"/>

    <div>아래 계좌로 입금 후 메일을 보내주세요.</div>
    <div>충전은 최대한 리워드형태로 사용될 수 있도록 기타 수수료 손실을 줄이기 위해 무통장입금으로만 가능합니다.</div>
    <div>현재는 이벤트 기간으로 충전금액의 10배를 충전해드립니다.</div>
    <div>계좌명 : </div>
    <div>Email : </div>
    <div>입금자 : {{ name }} / 입금액 / 날짜 / 입금자명</div>

  </div>
</template>

<script>

import axios from 'axios'
import common from '@/common'
import Const from '@/const'
// import Navbar from '@/components/common/NavBar'

import TitleBarEx from './common/TitleBarEx.vue'

const str_my = '나의 호감멤버'
const str_popular = '인기멤버';
const str_all = '전체글';
// const str_newone = '새멤버';
const str_hot = '추천순';
const tab_menus = [str_all, str_popular, str_my]; // 

const str_empty = '같은 관심사를 가진 사람들과 \n익명으로 자유롭게\n 이야기를 나눠보세요'
const pull_new = "새글 확인하기";

export default {
  name: 'about-view',
    components: {
        // Navbar,
        TitleBarEx,
    },
    props: {
      param: Object,
      container: Object,
    },
    setup() {
      // console.log('board setup');
      return {
      }
    },
    data(){ 
      return {
        title: '발행안내',        
        title_left : [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
        ],
        show_intro: false,
        user: null, // 사용자   
        rows: [],
        last: 0,
        total: 0,
        left:[
          {
            html: '<i class="fa fa-chevron-left"></i>',
            onClick: this.onLeft,
          },
          {
            html: '<span class="material-symbols-outlined ">home</span>',
            onClick: this.onHome,
          },
        ],
        titleBarConfig:{
            is_write: false, 
            is_menu: true,
            left: [
                {
                    html: `<i class="button-color fa fa-bars navi-button"></i>`,
                    onClick: () => {this.$refs.slidemenu.is_show = true;}
                },
                {
                    html: `<div class="navi-button" style="font-weight: 900;">${Const.about}</div>`
                }
            ]
        },        
        writable: false, // 이 게시판에 글을 작성 할 수 있는가?
        board_name: '',

        str_all,

        tab_menus,

        me: null,
        idx: 0,
        board: {},
        persona: {},
        needs_more: true,
        selectedTab: tab_menus[0],
        hashtag:['전체'],
        tag: '*', // 선택된 태그
        tags:[],
        all: { // 모든 멤버
          rows:[],
          isNew: false, // 새글이 있나?
          end: false, // 더 이상 없나?
          hasScroll: false, // 스크롤 유무
        },
        str_empty,
        pull_new,

        reported:{}, // 신고한 사람
        top_post: null,
      }
    },    
    created() {
        this.idx = common.param("idx");
    },
    mounted() {
        {
            this.$refs.titlebar.left = this.title_left;
        }
        console.log('this.param', this.param)

        // console.log('this.idx', this.idx)

        {
            const comp = {
            onEvent: this.onEvent,
            onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        // this.$refs.navbarBottom.is_share = true;

        this.initView();

        {
            const url = `${Const._api}my/posts/0`
            // const url = `${host}/api/my/ad/${idx}`
            console.log(url)

            let total = 0;

            axios.defaults.withCredentials = true;        
            const proc = (data) => {
                console.log(data)
                const {rows} = data; // 신규, 본것
                console.log(rows)

                this.rows = rows;

                if(total == 0){
                    // util.innerHTML('nodata', _nodata.html);
                    // util.hide('more')
                }
            }
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });    
        }       
    },
    methods:{
      scrollToBottomSmallTalk(){
        setTimeout(this.scrollToElement, 1);
      },
      showReportType(post_idx, cb){
        const menus = [];
        for (let key in Const.reportType) {
          menus.push({
            title : Const.reportType[key],
            cb: () => { cb(key)},
          });
        }

        const obj = {
          title: '신고사유를 선택해주세요',
          menus
        }
        common.showMenuEx(obj);                
      },      
      onFlag(){
        console.log('onFlag', this.board)
        const url = Const._api + "board/flag";
        common.api_post(url, 
          {
            board_idx : this.board.idx,
          }, 
          () => {
            console.log('성공')
            this.board.persona_idx = this.persona.idx
          },
        )                        
      },
      onUnflag(){
        console.log('onFlag', this.board)
        const url = Const._api + "board/unflag";
        common.api_post(url, 
          {
            board_idx : this.board.idx,
          }, 
          () => {
            console.log('성공')
            this.board.persona_idx = 0;
          },
        )                        
      },
      onWriteTop(){
        console.log('onWriteTop');

        const param = {
            data: {
              board_idx: this.idx,
              idx: 0,
              topfix: 1,  // 상단고정글
            }
          } 

          const obj = {
            name: Const.component.PostForm,
            param
          }
          common.popUp(obj);
      },
      scrollToElement() {
        const el = this.$refs.scroll_container;
        // console.log(el);


        if (el) {
          el.scrollTop = el.scrollHeight;
        }
      },
      didTalk(){
        console.log('didTalk');

        // 이후의 메시지를 요청한다.
        this.requestSmallTalkNew();
      },
      onTouchMove(item){
        item
        // console.log('onTouchMove',item)
      },
      onShow(){
        console.log('onShow')
        this.checkMe();
      },
      checkMe(){ // 새로운 메시지, 알람 체크
        common.checkMe( (res) => {
            console.log('checkMe', res.data);
            // const {data} = res;
        })
      },  
      onMove(location_idx){
            console.log('onMove', location_idx)

            this.$refs.locationbar.movePosition({location_idx})
        },

      onPull(){
        switch (this.selectedTab) {
          case str_my:
            break;
          case str_hot:
            
            break;        
          default:
            break;
        }
      },
      handleScroll(e){
        // console.log('handleScroll')
        const {target} = e;
        const {clientHeight, scrollTop, scrollHeight} = target;

        // console.log('handleScroll', clientHeight, scrollHeight);

        // console.log('clientHeight + scrollTop), scrollHeight) ', (clientHeight + scrollTop), scrollHeight);
        if((clientHeight + scrollTop + 1) > scrollHeight) 
        {
          // console.log('bottom')
          this.onScrollBottom();
        }

        if(scrollTop == 0){
          this.onScrollTop();
        } 
      },
      initView(){
        console.log('initView')
        common.checkSession((isLogin, user) => {
          if(isLogin){
            this.didLogin(user)
          }else{
            this.notLogin();
          }
        })
      },
      onEvent(name, param){
        // console.log('onEvent', name, param)

        switch (name) {
          case Const.notify.post:
          case Const.notify.post_del:
          case Const.notify.post_update:
            {
              const {board_idx} = param;
              // console.log('post', board_idx, this.board.idx);
              if(board_idx == this.board.idx)
              {
                this.selectedTab = str_all;
                this.$refs.tabMenu.selected = 0;
                this.requestAll();
              }              
            }
            break;
          case Const.notify.login:
            {
              // console.log('onEvent', param);
              this.initView();
            }
            break;
          case Const.notify.nickname:
            // 닉네임을 다 적용 
            this.all.rows.forEach(element => {
              console.log('element', element)
              if(element.persona_idx == param.idx){
                element.name = param.name
              }
            });

            this.popular.rows.forEach(element => {
              if(element.persona_idx == param.idx){
                element.name = param.name
              }
            });

            this.myMembers.rows.forEach(element => {
              if(element.persona_idx == param.idx){
                element.name = param.name
              }
            });

            // 추천순

            // 새멤버            

            break;
          case Const.notify.block_persona:
            // tobe : 차단한 사람을 감춰야 한다. 
            // 지금은 단순하게 다시 리로드 하는 것으로 하자.
            this.reloadSelectedRows();
            break;
          default:
            break;
        }
      },
      didLogin(user){
        console.log('didLogin', user);
        // // console.log(persona.name + '님')

        this.$refs.titlebar.didLogin();
        this.$refs.titlebar.updateButtonState()

        this.show_intro = false;

        // titlebar 수정
        this.me = user;
        this.session.user = user;

        // this.$refs.navbarBottom.is_write = true;
        // this.$refs.navbarBottom.is_menu = false;
        // this.$refs.navbarBottom.is_bookmark = true;

        
        // if(this.param.write) 
        //   this.onWrite();
      },      
      notLogin: function(){    
        console.log('notLogin')
        // if(this.param.write) // 글을 쓰려는데 로그인 상태가 아니다.
        //   common.popupLogin();

        // todo
        // 로그인 상태가 아니면 
        // 팝업으로 
        // 인트로 보여주기

        // 인트로 가져오기
        {
            const url = `${Const._api}adv/1/null`
            // const url = `${host}/api/my/ad/${idx}`
            console.log(url)

            common.api_get(
                url, 
                res => {
                    const {error, row, msg} = res.data;
                    if(error > 0){
                        const param = {
                            header: '잘못된 접근',
                            body: msg,
                        }
                        common.popupModal(param);
                        
                        return;
                    }

                    console.log(row)
                }
            );              
        }

      },
      fitToBubble(rows){
        rows.forEach(element => {
          element.my_persona_idx = this.persona.idx;
          if(element.persona_idx == this.persona.idx) element.myname = element.persona_idx;
          if(this.reported[`${element.persona_idx}`]) element.hidden = true;
          if(element.nickname) element.name = `${element.persona_idx}(${element.nickname})`
        });
      },
      requestAll(start=0){
        console.log('requestAll',start)
        
        // if(start == 0){
        //   this.resetRows(this.all);
        // }

        // const url = Const._api + `post//board/all/${this.idx}/${start}/${this.tag}`;

        // common.api_get(
        //   url, 
        //   res => {
        //     const {error, msg} = res.data;
        //     if(error > 0){
        //       const param = {
        //           header: '잘못된 접근',
        //           body: msg,
        //       }
        //       common.popupModal(param);
              
        //       return;
        //     }

        //     const {rows, end, top_post} = res.data

        //     if(!rows) {
        //       common.toast('문제가 발생했습니다.')
        //       return
        //     }

        //     this.top_post = top_post;

        //     rows.forEach(element => {
        //       if(this.persona)
        //         element.url = Const._base + element.url;
        //     });
        //     this.all.rows = this.all.rows.concat(rows);  

        //     if(end && this.all.rows.length > 0){ // 데이터가 없는 경우는 제외
        //         this.all.end = true;
        //         // common.toast(Const.message.no_more)
        //     }

        //     // 스크롤 존재여부
        //     setTimeout( () => { 
        //       this.all.hasScroll = this.$refs.scroll_container.scrollHeight > this.$refs.scroll_container.clientHeight;
        //     }, 1); 
        //   }
        // );        
      },
      onTitle(){

      },
      onLeft(){
        common.back();
      },
      pullNew(target, isMerge = false, rate = 0){             
        if(isMerge == false && target.isNew) return; // 이미 새글이 있다.
          
        const after = target.rows.length > 0 ? target.rows[0].idx : 0
        const url = Const._api + `board/new/${this.idx}/${after}/${rate}`;

        common.api_get(
          url, 
          res => {
            const {error, msg} = res.data;
            if(error > 0){
              const param = {
                  header: '잘못된 접근',
                  body: msg,
              }
              common.popupModal(param);
              
              return;
            }
            
            // this.snackbar.hide();

            const {rows} = res.data;

            if(rows.length == 0){
              common.toast('새로운 글이 없습니다.')
              return;
            }

            if(isMerge){
              target.isNew = false;
              rows.forEach(element => {
                element.url = Const._base + element.url;
              });
              target.rows = rows.concat(this.rating.rows);  
            }
            else{
              target.isNew = true; // 표시만 한다.
            }
          }
        );
      },
      checkNewAll(){
        const last = this.all.rows.length > 0 ? this.all.rows[0].idx : 0;
        const url = Const._api + `post/board/new/all/${this.idx}/${last}/${this.tag}`;

        common.api_get(
          url, 
          res => {
            const {error, msg} = res.data;
            if(error > 0){
              const param = {
                  header: '잘못된 접근',
                  body: msg,
              }
              common.popupModal(param);
              
              return;
            }

            const {isNew} = res.data
            this.all.isNew = isNew;

            this.$refs.alert_all.alert(isNew);
            // if(!isNew) common.toast(Const.message.no_new)
          }
        );        
      },
      onScrollTop(){
        switch (this.selectedTab) {
          case str_all:
            break;
          default:
            break;
        }
      },
      onScrollBottom(){
        // console.log('onScrollBottom')
        switch (this.selectedTab) {
          case str_all:
            {
              if(this.all.end) return;
              const last = this.all.rows.length > 0 ? this.all.rows[this.all.rows.length - 1].idx : 0
              this.requestAll(last)
            }
            break;
          default:
            break;
        }
      },
      onScrollEnd: function(){

      },
      onNavbar: function(button){
          // console.log('onNavbar', button);
          switch (button) {
              case 'search':
                this.$emit('event', 'home');
                // {
                //     const obj = {
                //         name: 'SearchPage',
                //     }
                //     common.popUp(obj);
                // }
                break;
              case 'login':
                {
                  const param = {
                    didLogin: this.initView
                  }
                  common.popupLogin(param)
                }
                break;
              case 'bookmark':
                this.onLike();
                break;
              case 'share':
                {
                  // console.log('this', window.location);
                  const persona_idx = this.persona ? this.persona.idx : 0;
                  const param = {
                    url: `${window.origin}/?/board/${this.idx}/${persona_idx}`,
                  } ;
                  // console.log('param', param);

                  common.popUp({
                    name: Const.component.QrcodeVue,
                    param
                  })
                }
                break;
              case 'write':
                if(this.writable == false){
                  const param = {
                      header: '',
                      body: Const.message.not_visited,
                  }
                  common.popupModal(param);                  
                  return;
                }

                this.onWrite();       
              break;
          
              default:
              break;
          }
      },
      onWrite(){
        // console.log('this.me', this.me);
        if(this.me)
        {
          const param = {
            data: {
              board_idx: this.idx,
              idx: 0,
            }
          } 

          const obj = {
            name: Const.component.PostForm,
            param
          }
          common.popUp(obj);
        }
        else // 로그인을 한번도 한적이 없는 경우 
        {
          common.popupLogin();
        }         
      },
      onHome(){
        this.$emit('event', 'home');
      },
      onLike(){
        const url = Const._api + "board/like";
        const param = {
          board_idx: this.idx,
          like: !(this.board.isLike),
        }
        common.api_post(url, param, data => {
            // console.log('data', data)

            const {error, msg} = data; 
            if(error == 100)    // not login
            {
                common.tryLogin();
                return;
            }
            else if(error > 0){
                // console.log('error', error);
                this.$emit('error', error, msg);      
                return;
            }
            // this.$emit('done');
            this.board.isLike = !(this.board.isLike) ? 1 : 0;
            // this.$refs.navbarBottom.bookmark = this.board.isLike ? "bookmark-on" : "bookmark-off" ;

            common.notifyEvent(Const.notify.bookmark)
        })
      },
      onTabMenu(menu){
        this.selectedTab = menu;
        // console.log('index.selectedTab', menu, param);

        switch (menu) {
          case str_all:
            if(this.all.rows.length == 0) this.requestAll();
            break;
          default:
            break;
        }
      },
      onTag(tag, n){     
        console.log('onTag', tag, n)
        // tag;     
        // this.tag = this.tags[n-1]

        if(n == 0){ // 전체
          this.tag = '*'
        }else{
          this.tag = this.tags[n-1]
        }

        // 선택된건 다시 로딩, 나머지는 리셋만
        {
          this.resetRows(this.all);
          this.resetRows(this.myMembers);
          this.resetRows(this.popular);
        }

        switch (this.selectedTab) {
          case str_all:
            this.requestAll();
            break;
          case str_my:
            this.requestMy();
            break;
          case str_popular:
            this.requestPopular();
            break;
        
          default:
            break;
        }
      },
      // 현재 선택탭을 리로드하고 나머지 탭은 비운다.
      reloadSelectedRows(){
        {
          // 데이터 리셋
          const reset = (target) => {
            target.rows = [];
            target.isNew = false;
            target.end = false;
            target.handleScroll = false;
          }

          reset(this.all);
          reset(this.myMembers);
          reset(this.popular);
          reset(this.newone);
          reset(this.hot);
          reset(this.smalltalk);
        }
      
        switch (this.selectedTab) {
          case str_all:
            this.requestAll();
            break;            
            default:
            break;
        }
      },
      onPost(data){
          console.log('onPost', data);
          
          common.push({
            name: Const.component.PostView,
            param: data,
          })  ;
      },
      onDone: function(){
        // 글쓰기를 하면 무조건 전체글 탭으로 이동한다.
        this.selectedTab = str_all
        this.requestAll();

        // 다른탭에는 영향을 주지 않으므로 그냥 둔다.
      },
      onError: function(error, param){
        // console.log('onError', error, param);

        // todo : notlogin 처리 / 리책토링

        common.toast(param)
      },
      resetRows(obj){
        obj.rows = [];
        obj.isNew = false; // 새글이 있나?
        obj.end = false; // 더 이상 없나?
      }
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.empty {
  height: 200px;
}

.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

</style>

