<template>
    <div class="scroll-container">
        <title-bar-ex ref="titlebar" :title="'상품권교환'" :container="container" :right="right"/>
        <div class="margin-side">

            <my-cherry :point="point" />

            <div v-show="rows.length == 0" style="margin-top: 20px;">
                <no-data :data="nodata" />                
            </div>

            <div ref="scroll_container" class="scroll_container background-color" id="scroll-container" v-on:scroll.passive="handleScroll"  >
                <div class="middle" id="contents" >
                    <point-cell v-for="(item, index) in rows" v-bind:key="index" :data="item"></point-cell>
                </div>    
            </div>
            <!-- <slidemenu ref="slidemenu" v-on:move="onMove"></slidemenu>      -->
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TitleBarEx from './common/TitleBarEx.vue'
import PointCell from './common/PointCell.vue';
import NoData from './common/NoData.vue';
import MyCherry from './common/MyCherry.vue'

export default {
    components: {
        TitleBarEx,
        PointCell,
        NoData,
        MyCherry,
    },
    props: {
      param: Object,
      container: Object,
    },
    setup() {
      // console.log('board setup');
      return {
      }
    },
    data(){ 
      return {
        point: 0,
        nodata:{
            html: '체리 500을 모으면 네이버페이로 교환이 가능합니다.'
        },
        show_manual: false,
        manual: null,
        title_left : [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
        ],
        rows: [],
        last: 0,
        total: 0,
        left:[
          {
            html: '<i class="fa fa-chevron-left"></i>',
            onClick: this.onLeft,
          },
          {
            html: '<span class="material-symbols-outlined ">home</span>',
            onClick: this.onHome,
          },
        ],
        right:{
          html: '교환하기',
          onClick: () => {
            if(this.user.exchangable == 0)
            {
                // alert(`최종교환 신청후 ${this.days}일이 지나지 않았습니다.`)
                const param = {
                    header: '',
                    body: `최종교환 신청후\n${this.days}일이 지나지 않았습니다.`
                    // callback
                }
                common.popupModal(param);

                return;
            }

            const min = this.npay[0];
            if(this.user.remain_point  < min){
                // alert(`최종교환 신청후 ${this.days}일이 지나지 않았습니다.`)
                const param = {
                    header: '',
                    body: `최소 ${min}체리 이상부터\n교환신청이 가능합니다.\n\n현재 : ${this.user.remain_point}`
                    // callback
                }
                common.popupModal(param);

                return;
            }            

            const obj = {
                name: Const.component.ExchangeForm,
                param: {npay: this.npay}
            }
            common.popUp(obj);
          }
        },
        titleBarConfig:{
            is_write: false, 
            is_menu: true,
            left: [
                {
                    html: `<i class="button-color fa fa-bars navi-button"></i>`,
                    onClick: () => {this.$refs.slidemenu.is_show = true;}
                },
                {
                    html: `<div class="navi-button" style="font-weight: 900;">${Const.about}</div>`
                }
            ]
        },        
        writable: false, // 이 게시판에 글을 작성 할 수 있는가?
        board_name: '',

        uset: null,
        idx: 0,
        board: {},
        persona: {},
        needs_more: true,
        hashtag:['전체'],
        tag: '*', // 선택된 태그
        tags:[],
        all: { // 모든 멤버
          rows:[],
          isNew: false, // 새글이 있나?
          end: false, // 더 이상 없나?
          hasScroll: false, // 스크롤 유무
        },

        reported:{}, // 신고한 사람
        top_post: null,
      }
    },    
    created() {
        this.idx = common.param("idx");
    },
    mounted() {
        console.log('this.user', this.user)

        this.$refs.titlebar.didLogin();

        console.log('mounted')
        {
            this.$refs.titlebar.left = this.title_left;
        }
        console.log('this.param', this.param)

        {
            const comp = {
                onEvent: this.onEvent,
            }
            common.registEventHandler(comp);
        }

        // console.log('this.idx', this.idx)

        {
            const comp = {
                onEvent: this.onEvent,
                onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        this.request(0); 
    },
    methods:{
        request(start){
            const url = `${Const._api}my/exchange/list/${start}`            
            console.log(url)

            let total = 0;

            axios.defaults.withCredentials = true;        
            const proc = (data) => {
                console.log(data)
                const {rows, row, user, days, npay} = data; // 신규, 본것
                console.log(rows)

                this.rows = rows;

                if(total == 0){
                    this.show_manual = true;
                    this.manual = row;
                }

                Object.assign( this.user, user);
                this.point = user.remain_point;
                this.days = days;

                this.npay = npay;
            }
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });             
        },
        reload(){
            console.log('reload')
            this.request(0); 
        },
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {                
            case Const.notify.post_update:
                this.reload()
                break;
            default:
                break;
            }
        },
        onLeft(){
            common.back();
        },
        onHome(){
            this.$emit('event', 'home');
        },
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.empty {
  height: 200px;
}

.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}
.row {
    font-size: 20px;
    background: peachpuff;
}

</style>

