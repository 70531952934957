<template>
    <div class="navbar-container bar-background-color border-top padding10">
        <div class="left-elem part-3 " >
          <!-- <div v-if="is_menu" class="flex"  @click="click('menu')">
            <i class="button-color fa fa-bars navi-button"></i>            
            <div class="navi-button" style="font-weight: 900;">Abouting</div>
          </div> -->

          <div class="navi-button margin-right" v-if="is_bookmark" @click="click('bookmark')">
            <!-- <i :class="bookmark" class="fa fa-light fa-bookmark"></i> -->
            <span :class="bookmark" class="material-symbols-outlined fill">bookmark</span>
          </div>

          <!-- <div class="navi-button" v-if="is_share" @click="click('share')">
            <span class="material-symbols-outlined ">share</span>
          </div> -->
        </div>
        
        <div class="center-elem part-3">
          <div v-if="is_write" @click="click('write')">
            <i class="color-primary border fa fa-pen"></i>
          </div>
          <!-- <a v-if="is_search" @click="click('search')">
            <i class="button-color fa fa-home "></i>  -->
            <!-- <i class="color-primary border fa fa-fw fa-search "></i>  -->
          <!-- </a>  -->
        </div>

        <!-- <a @click="onHome">
          <i class="button-color fa fa-home"></i>
        </a> -->

        <div class="right-elem part-3 ">
          <!-- 관심채널 새글 -->
          <div v-if="is_noti" @click="onMyChannels" class="navi-button">
            <span class="material-symbols-outlined " :class="is_news ? 'color-on' : ''" >bookmark</span>
          </div>         

          <!-- 알림 -->
          <!-- 댓글 좋아요 등등 -->
          <div v-if="is_noti" @click="onNotice" class="navi-button">
            <!-- <i class="fa fa-fw fa-bell" :class="is_noti_on ? 'color-on' : 'button-color'" ></i>  -->
            <span class="material-symbols-outlined" :class="is_noti_on ? 'color-on' : ''">favorite</span>
          </div>         

          <!-- 메시지 -->
          <!-- 1:1대화 -->
          <div v-if="is_message" @click="onMessage" class="navi-button ">
            <span class="material-symbols-outlined" :class="is_message_on ? 'color-on' : ''">mail</span>
          </div> 

          <!-- 로그인 -->
          <div v-if="!is_message && show_login" @click="click('login')" class="navi-button">
            <div class="side-menu-item">로그인</div>
          </div>         

          <!-- 공유 -->
          <div class="navi-button" v-if="is_share" @click="click('share')">
            <span class="material-symbols-outlined ">share</span>
          </div>
        </div>
    </div>    
</template>

<script>
import common from '@/common';
import Const from '@/const';
export default {  
  props:{
      data:Object,
    },
    mounted() {
        console.log('navbar.data', this.data)
        this.is_write = this.data?.is_write;

        this.show_login = this.data.show_login == undefined ? true : this.data.show_login;
    },
    data () {
      return {  
          show_login: true,
          is_menu: true,
          is_share: false,

          is_write: false,
          is_search: false,

          is_noti: false,
          is_message: false,
          is_bookmark: false,

          is_news: false,   // 관심채널의 새소식이 있는가?
          is_noti_on: false,          
          is_message_on: false,

          bookmark: 'bookmark-off',
      };
    },
    methods: {
      onMyChannels(){
        this.is_news = false;
        common.push({
          name: Const.component.MyChannelView, // 'NoticeView'
        })
      },
      onNotice(){
        this.is_noti_on = false;
        common.push({
          name: Const.component.NoticeView, // 'NoticeView'
        })
      },
      onMessage(){
        this.is_message_on = false;
        common.push({
          name: Const.component.MessagesView //'MessagesView'
        })
      },
      onHome(){
        window.location='/?main=1';
      },
      click:function(button){
        // console.log('appbar.click : ', button)    
        this.$emit('clicked', button)
      },
      didLogin(me){
        this.is_noti = true;
        this.is_message = true;

        this.me = me;


        // this.is_write = true;
      },
    },      
}
</script>

<style scoped>
@import '@/assets/common';


/* .navright {
  float: right;
} */

.navi-button {
  padding-right: 5px;
  padding-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.part-3 {
  width: 33%;
}

.left-elem {
    display: flex;
    justify-content: flex-start;
}

.center-elem {
  display:flex; 
  align-items: center;  
  justify-content: center;  
}

.right-elem{
    display: flex;
    justify-content: flex-end;
}

</style>
