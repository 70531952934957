<template>
    <div class="v-container center">
        <!-- top -->
        <div>
            <title-bar ref="titlebar" :data="titleBar" v-on:right="onMenu" v-on:left="onLeft"></title-bar>      
            <TabMenu class="tabmenu" :data="tap_menus" v-on:clicked="onTabMenu"></TabMenu>
        </div>    

        <!-- middle -->
        <div class="middle scroll-container" id="contents" v-on:scroll.passive="handleScroll" >
            <!-- 포스트 -->
            <div v-show="selectedTab == str_post" style="display: flex; flex-direction: column;">
                <postcell v-for="(item, index) in posts" v-bind:key="index" :data="item" v-on:clicked="onPost" v-on:show="onShow" />
            </div>
            <!-- 댓글 -->
            <div v-show="selectedTab == str_reply">
                <postcell v-for="(item, index) in repls" v-bind:key="index" :data="item" v-on:clicked="onReplyCell" />
            </div>   

            <!-- 신고된 포스트 -->
            <postcell v-show="selectedTab == str_report"  v-for="(item, index) in reportedPosts" v-bind:key="index" :data="item" v-on:clicked="onReportedPost" v-on:manage="onManagePost" />

            <!-- 신고된 댓글 -->
            <postcell v-show="selectedTab == str_report_re"  v-for="(item, index) in reportedRepls" v-bind:key="index" :data="item" v-on:clicked="onReplyCell" v-on:manage="onManageRe" />
        </div>    

        <!-- bottom -->
        <div class="bottom" onclick="onTop()"></div>
    </div>
</template>

<script>
  import common from '@/common'
  import Const from '@/const'
  import TabMenu from '@/components/common/TabMenu.vue'
  import postcell from '@/components/common/PostCell.vue'

  import 'swiper/css'
  import 'swiper/css/pagination'
  import TitleBar from '@/components/common/TitleBar.vue'
//   import ReplyCell from '@/components/common/ReplyCell.vue'
  // more module style...

  const str_post = '최신글';
  const str_reply = '최신댓글';
  const str_report = '신고글';
  const str_report_re = '신고댓글';

  const tap_menus = [str_post, str_reply, str_report, str_report_re];

  export default {    
    components: {
      // navbar,
      TabMenu,
      // tagbar,
      postcell,
      // floatingbutton,
      TitleBar,
    //   ReplyCell,
    },
    setup() {
      console.log('board setup');
      return {
      }
    },
    props: {
        container:Object
    },
    data(){ 
      return {            
        data: null,
        tap_menus,
        str_post,
        str_reply,
        str_report,
        str_report_re,

        me: null,
        idx: 0,
        isLike: false,
        persona: null,
        selectedTab: str_post,
        posts: [],        
        repls: [],
        reportedPosts: [],
        reportedRepls: [],
        show_form: false,   // 작성폼
        nickname: '',
        titleBar:{
          title: '관리자'
        },
        no_more: {

        },
        is_show_post_page: false,
      }
    },
    created: function () {
        this.idx = common.param("idx");
    },
    mounted: function() {
      common.checkSession((isLogin, user) => {
        if(isLogin){
          this.didLogin(user)
        }else{
          this.notLogin();
        }
        
        this.requestPosts(0);
        this.requestRepls(0);
      })
      
      const element = document.getElementById("contents");
      // const _this = this;
      element.addEventListener('scroll', function(event)
      {
          var element = event.target;
          if (element.scrollHeight - element.scrollTop < element.clientHeight)
          {
              console.log('scrolled', this);
              
              {
                const elem = this.posts[this.posts.length - 1];
                console.log('idx', elem.idx);
                this.request(elem.idx)
              }
              
          }
      });
    },
    methods:{      
        onShow(idx, state, comp){
            console.log('onShow', state)

            const hidden = state ? 0 : 1

            const url = Const._api + "admin/post/show";
            const param = {
                idx,
                hidden,
            }

            common.api_post(url, param, data => {
                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    this.$emit('error', error, msg);      
                    return;
                }

                comp.hidden = hidden;
            })            
        },
        handleScroll(e){
            const {target} = e;
            const {clientHeight, scrollTop, scrollHeight} = target;

            if((clientHeight + scrollTop + 1) >= scrollHeight){
            this.onScrollBottom();
            }
            else if(scrollTop == 0){
            console.log('top');
            this.onScrollTop();
            }
        },      
        didLogin(user){
          console.log('didLogin', user);
        },      
        notLogin: function(){
          console.log('로그인상태가 아님');
        },
        requestNewRepls(){
          console.log('requestNewRepls');

          const start = this.repls.length > 0 ? this.repls[0].idx : 0

          const url = Const._api + `admin/repls/${start}/1`;   // 새거

          common.api_get(
              url, res => {
                  const {data} = res;

                  this.data = data;
                  const {rows} = data;

                  rows.forEach(element => {
                      element.name = '';
                      element.content = element.text;
                      this.repls.unshift(element);
                  });
          });
        },
        requestNewPosts(){
          console.log('requestPosts');

          const start = this.posts.length > 0 ? this.posts[0].idx : 0

          const url = Const._api + `admin/posts/${start}/1`;   // 새거

          common.api_get(
              url, res => {
                  const {data} = res;

                  this.data = data;
                  const {rows} = data;

                  rows.forEach(element => {
                      element.name = '';
                      element.is_monitor = true;
                      this.posts.unshift(element);
                  });
          });
        },
        requestPosts(start){
          console.log('requestPosts', start);

          const url = Const._api + `admin/posts/${start}/0`;

          common.api_get(
            url, res => {
              const {data} = res;

              this.data = data;

              const {rows, end} = data;
              this.no_more.post = end;

              rows.forEach(element => {
                element.name = '';
                element.is_monitor = true;
              });
              this.posts = this.posts.concat(rows);  
            }
          );
        },
        requestReportedRepls(start=0){
            console.log('requestRepls', start);

            if(start == 0) this.reportedRepls =[];

            const url = Const._api + `admin/report/reply/${start}`;

            common.api_get(
                url, 
                res => {
                    const {data} = res;

                    this.data = data;

                    const {rows, end} = data;
                    this.no_more.reportedRepls = end;

                    rows.forEach(element => {
                        element.name = '';
                        element.is_report = true;
                        element.content = element.text;
                    });
                    this.reportedRepls = this.reportedRepls.concat(rows);  
                }
            );
        },        
        requestReportedPosts(start=0){
            console.log('requestRepls', start);

            if(start == 0) this.reportedPosts =[];

            const url = Const._api + `admin/report/post/${start}`;

            common.api_get(
                url, 
                res => {
                    const {data} = res;

                    this.data = data;

                    const {rows, end} = data;
                    this.no_more.reportedPosts = end;

                    rows.forEach(element => {
                        element.name = '';
                        element.is_report = true;
                    });
                    this.reportedPosts = this.reportedPosts.concat(rows);  
                }
            );
        },
        requestRepls(start=0){
          console.log('requestRepls', start);

          if(start == 0) this.repls =[];

          const url = Const._api + `admin/repls/${start}/0`;

          common.api_get(
            url, res => {
              const {data} = res;

              this.data = data;

              const {rows, end} = data;
              this.no_more.reply = end;


              rows.forEach(element => {
                element.name = '';
                element.content = element.text;
              });
              this.repls = this.repls.concat(rows);  
            }
          );
        },
        onLeft(){
            common.back();
        },
        onRight(){

        },
        onScrollTop(){
            switch (this.selectedTab) {
                case str_post:
                    this.requestNewPosts()
                    break;
                case str_reply:
                    this.requestNewRepls()
                    break;
                case str_report:
                    break;
                case str_report_re:
                    break;            
                default:
                    break;
            }
            
            const length = this.posts.length;
            if(length == 0) return;
        },
        onScrollBottom(){
          console.log('onScrollEnd');

            switch (this.selectedTab) {
                case str_post:
                    if(this.no_more.post === true){
                        common.toast('끝입니다.')
                        return;    // 더 이상 레코드 없으면...                        
                    } 

                    {
                        const arr = this.posts;
                        const lastIdx = arr.length > 0 ? arr[arr.length-1].idx : 0;
                        this.requestPosts(lastIdx);
                    }
                    break;
                case str_reply:
                    if(this.no_more.reply === true){
                        common.toast('끝입니다.')
                        return;    // 더 이상 레코드 없으면...                        
                    } 

                    {
                        const arr = this.repls;
                        const lastIdx = arr.length > 0 ? arr[arr.length-1].idx : 0;
                        this.requestRepls(lastIdx);
                    }
                    
                    break;
                case str_report:
                    if(this.no_more.report === true) return;    // 더 이상 레코드 없으면...                        
                    
                    break;
                case str_report_re:
                    if(this.no_more.report_re === true) return;    // 더 이상 레코드 없으면...                        

                    break;            
                default:
                    break;
            }
        },
        onAppBar: function(button){
            console.log('onAppBar', button);
            switch (button) {
                case 'menu':
                break;
                case 'search':
                    window.location = 'search.html';
                break;
                case 'message':
                
                break;
                case 'my':
                
                break;
            
                default:
                break;
            }
        },
        onLike(){
          console.log('onLike', this.isLike);

          {
              const url = Const._api + "board/like";
              const param = {
                board_idx: this.idx,
                like: this.isLike != 1,
              }
              common.api_post(url, param, data => {
                  console.log('data', data)

                  const {error, msg} = data; 
                  if(error == 100)    // not login
                  {
                      this.$emit('login');      
                      return;
                  }
                  else if(error > 0){
                      console.log('error', error);
                      this.$emit('error', error, msg);      
                      return;
                  }
                  this.$emit('done');
                  this.isLike = this.isLike === 0 ? 1 : 0;
              })
          }          
        },
        onMessage(){
          console.log('onMessage', this.data)
          {
              const url = Const._api + "talk/start";
              const param = {
                persona_idx: this.idx,
              }
              common.api_post(url, param, data => {
                  console.log('data', data)

                  const {error, msg, idx} = data; 
                    console.log('error', error, msg);
                  if(error == 100)    // not login
                  {
                      return;
                  }
                  else if(error > 0){
                    return;
                  }

                  console.log('성공', idx);
                  // todo : 메시지창 열기
                  window.location = `/message.html?idx=${idx}`;
              })
          }
        },
        onTabMenu: function(menu){
            // const str_post = '최신글';
            // const str_reply = '최신댓글';
            // const str_report = '신고글';
            // const str_report_re = '신고댓글';

            this.selectedTab = menu;
            console.log('index.selectedTab', menu);

            switch (menu) {
            case str_post:
                this.requestPosts();
                break;
              case str_reply:
                this.requestRepls();
                break;
            case str_report:
                this.requestReportedPosts();
                break;
            case str_report_re:
                this.requestReportedRepls();
                break;
            
              default:
                break;
            }
        },
        onMenu(){ // 본문메뉴
          // ['대화하기', '삭제', '닉네임 설정']
          const arr = [
            {
              title: '대화하기',
              cb: () => {
                this.onMessage();
              }
            },
            {
              title: '차단하기',
              cb: () => {
              }
            },
            {
              title: '닉네임 설정',
              cb: () => {
                this.show_input = true;
                this.$refs.inputForm.show();
              }
            },
          ]
          this.$refs.contextmenu.show(arr);
          // this.$refs.contextmenu.show(contextmenus)
          
        },
        onDelReportedPost(data){
            console.log('onDelReportedPost', data)

        },
        onReportedPost(data){
            // console.log('onReportedPost',data);
            common.push({
                name: Const.component.PostView,
                param: { 
                idx: data.post_idx,
                re_idx: data.idx,
                }
            })            
        },
        manageReply(param, comp){
            const url = Const._api + "admin/report/manage_re";

            common.api_post(url, param, data => {

                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    // console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }
                
                // 정상처리... 숨김? 
                console.log('정상처리... 숨김?')
                comp.managed = 1;
            })     
        },        
        managePost(param, comp){
            const url = Const._api + "admin/report/manage";

            common.api_post(url, param, data => {

                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    // console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }
                
                // 정상처리... 숨김? 
                console.log('정상처리... 숨김?')
                comp.managed = 1;
            })     
        },
        onManageRe(type, data, comp){
            console.log('onManageRe', type, data, comp)
            switch (type) {
                case 'pass': // 무혐의 처리 
                    {
                        const {idx, re_idx} = data;
                        const param = {
                            idx,
                            re_idx,
                            hidden: 0, // 감추고 있다면 보여주기
                        }
                        param;
                        this.manageReply(param, comp);                         
                    }
                    break;
                case 'delete':
                    {
                        const {idx, re_idx} = data;
                        const param = {
                            idx,
                            re_idx,
                            hidden: 1, // 감춘다.
                        }
                        param;
                        this.manageReply(param, comp);                         
                    }
                    break;
                default:
                    break;
            }
        },
        onManagePost(type, data, comp){
            switch (type) {
                case 'pass': // 무혐의 처리 
                    {
                        const {idx, post_idx} = data;
                        const param = {
                            idx,
                            post_idx,
                            hidden: 0, // 감추고 있다면 보여주기
                        }
                        this.managePost(param, comp);                         
                    }
                    
                    break;
                case 'delete':
                    {
                        const {idx, post_idx} = data;
                        const param = {
                            idx,
                            post_idx,
                            hidden: 1, // 감춘다.
                        }
                        this.managePost(param, comp);                         
                    }
                    
                    break;
            
                default:
                    break;
            }
        },
        onReplyCell(data){
            common.push({
                name: Const.component.PostView,
                param: { 
                idx: data.post_idx,
                re_idx: data.idx,
                }
            })
        },
        onPost: function(data){
            console.log('onPost', data);

            common.push({
            name: Const.component.PostView,
            param: {idx: data.idx}
          })  ;            
        },
        onPostView(tag, param){
          console.log('onPostView', tag, param);
          switch (tag) {
            case 'close':
              this.is_show_post_page = false;              
              break;
          
            default:
              break;
          }
        },        
        onLogin(){          
          // this.$refs.login.is_show = true; // 로그인창 보이기

          // 임시계정에서는 home으로 이동한다.
          window.location = "/";
        },
        onNew: function(){
            console.log('onNew');
            
            if(this.me)
            {
              this.show_form = true;
            }
            else
            {
              console.log('로그인상태가 아님')
              // todo : 로그인창
            }
        },
        onSearch:function(data){
            console.log('onSearch', data);
            // todo : 검색
            window.location = "boards.html";      
        },
        onClose: function(){
            this.show_form = false;
        },
        onDone: function(){
            console.log('onDone');
            this.show_form = false;
            this.posts = [];
            this.request(0);
        },
        onBlank: function(){
          console.log('onBlank');
        },
        onError: function(error, param){
          console.log('onError', error, param);

          // todo : notlogin 처리 / 리책토링

          this.$refs.snackbar.show(param);
        }
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.center-align {
  display: flex; 
  justify-content: center;
}

.star-size {
  font-size: x-large;
}
.star-checked {
  color: orange;
}
.star {
  color: gray;
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

.middle {
  /* background: plum; */
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  /* height: calc(100% - 155px); */
}

.bottom-menu {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%;
}

</style>
