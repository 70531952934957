<template>
    <div>
        <div v-for="(item, index) in menus" v-bind:key="index" :data="item"
        class="cell"
        :class=" { 'selected background-naver-green' : index == selected} "
        @click="click(item, index)">
          <div>{{item.title}}</div>
      </div>
    </div>
</template>

<script>
export default {  
    props:{
      data:Object,
    },
    mounted() {
        console.log('data', this.data)
        this.menus = this.data;
    },
    data: function () {
      return {  
        selected: -1,
        title: '',
        menus: [],
      };
    },
    methods: {
      click:function(data, n){
        console.log(data, n)
          this.selected = n;
          this.$emit('selected', data, n)
      },
      setFocus(index){
        this.selected = index;
      }
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.cell {
  font-size: 20px;
  color: gray;
  padding: 10px;
}

.selected {
    font-weight: bold;
    color: white !important;
}

.item {
    border: solid 0.5px;
}

</style>


