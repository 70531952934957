<template>
  <div class="center">

    <div class=" background-color" id="contents" v-on:scroll.passive="handleScroll" >
      <title-bar-ex ref="titlebar" :container="container"/>
      <TabMenu class="tabmenu sticky-top" :data="[str_tab1, str_tab2]" v-on:clicked="onTabMenu"></TabMenu>

      <!-- 포스트 -->
      <div v-show="selectedTab == str_tab1">
        <div v-for="(item, index) in rows1" v-bind:key="index" >
          <notify-cell  :data="item" v-on:clicked="onClick(item)"></notify-cell>
        </div>
        
        <lets-write
          class="margin-top10"
          v-if="rows1.length == 0"
          :text="`알림이 없습니다\n\n댓글소식 등을 받을 수 있습니다.`"
          :hide="true"
        />
      </div>

      <!-- 소식 -->
      <div v-show="selectedTab == str_tab2">
        <div v-for="(item, index) in rows2" v-bind:key="index">
          <templateCell :data="item" v-on:clicked="onTempCell(item)"></templateCell>
        </div> 
        
        <lets-write
          class="margin-top10"
          v-if="rows2.length == 0"
          :text="`소식이 없습니다\n\n서비스의 공식 소식을 받을 수 있습니다.`"
          :hide="true"
        />
        
      </div>   
    </div>    
  </div>
</template>

<style >
@import '@/assets/common';
</style>
<style scoped>

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

</style>>

<script>
  import common from '@/common'
  import Const from '@/const'
  import TabMenu from '@/components/common/TabMenu.vue'
  // import modal from '@/components/ModalDialog.vue'
  // import TitleBar from '@/components/common/TitleBar.vue'
  import NotifyCell from './common/NotifyCell.vue'
  import templateCell from './TemplateCell.vue'
  import LetsWrite from './common/LetsWrite.vue'
  import TitleBarEx from './common/TitleBarEx.vue'

  const str_tab1 = '알림';
  const str_tab2 = '소식';

  export default {
    components: {
      TabMenu,
      // modal,
      // TitleBar,
      NotifyCell,
      templateCell,
      LetsWrite,
      TitleBarEx,
    },
    props: {
      param: Object,
      container: Object,
    },
    setup() {
      console.log('board setup');
      return {
      }
    },
    data(){ 
      return {    
        str_tab1,
        str_tab2,
        me: null,
        idx: 0,
        isLike: false,
        persona: null,
        needs_more: true,
        selectedTab: str_tab1,
        rows1: [],        
        rows2: [],
        show_form: false,   // 작성폼
        titleBar: {
          title: '알림',
          right: '<div />'
        }
      }
    },
    created: function () {
        this.idx = common.param("idx");
    },
    mounted: function() {
      this.$refs.titlebar.updateButtonState()
      {
        const comp = {
          onEvent: this.onEvent
        }
        common.registEventHandler(comp)
      }

      common.checkSession((isLogin, user) => {
        if(isLogin){
          this.didLogin(user)
        }else{
          this.notLogin();
        }
      })
    },
    methods:{
      onScrollBottom(){
        console.log('onScrollBottom');
        switch (this.selectedTab) {
          case str_tab1:
            {
              if(this.needs_more === false) return;    // 더 이상 레코드 없으면...

              const length = this.rows1.length;
              if(length == 0) return;

              const lastIdx = this.rows1[length-1].idx;

              this.request(lastIdx);
            }
            break;
          case str_tab2:
            // 소식
            // todo 
            break;
          default:
            break;
        }
      },
      handleScroll(e){
        // console.log('handleScroll');
        const {target} = e;
        const {clientHeight, scrollTop, scrollHeight} = target;

        if((clientHeight + scrollTop + 1) >= scrollHeight){
          this.onScrollBottom();
        }
        else if(scrollTop == 0){
          console.log('top');
        }
      },
      didLogin(){
        this.request(0);
        this.$refs.titlebar.didLogin()
      },      
      notLogin: function(){
        const param = {
          didLogin: this.didLogin
        }
        common.popupLogin(param);
      },
      request: function(idx){          
          const url = Const._api + `my/notices/${idx}`;

          common.api_get(
            url, res => {
              const {error, msg} = res;
              if(error > 0){
                this.showModal(msg);
                return;
              }

              // this.$refs.snackbar.hide();

              const {rows} = res.data;

              if(rows.length == 0){
                  // todo : 더 이상 레코드가 없는 경우 처리
                  this.needs_more = false;

                  const msg = '모두 읽었습니다.'
                  common.toast(msg);

                  return ;
              }

              rows.forEach(element => {
                element.name = '';
                element.bottomRight = common.printTimeString(element.created);
                switch (element.type) {
                  case 1:
                    {
                      element.info1 = '댓글알림';
                      const json = JSON.parse(element.json);
                      if(json)
                        element.info2 = json.board;
                    }                    
                    break;
                
                  default:
                    break;
                }
              });
              this.rows1 = this.rows1.concat(rows);  
            }
            , this.modalErrorMessage
          );
      },
      onEvent(name, param){
        console.log('onEvent', name, param);

        switch (name) {
          case Const.notify.login:
            this.request(0);
            break;
        
          default:
            break;
        }
      },           
      onLeft(){
        common.back();
      },
      modalErrorMessage(msg){
        this.showModal('Error', msg)
      },
      showModal: function(header, body, callback){
        const param = {
            header,
            body,
            callback
        }
        common.popupModal(param);
      },
      onTabMenu: function(menu){
          this.selectedTab = menu;
          console.log('index.selectedTab', menu);
      },
      onTag: function(tag){
          console.log('onTag', tag);
          // todo
      },
      onTempCell(){

      },
      onClick: function(item){
          console.log('onClick', item);

          item.opened = true;

          {
            const {idx, json, url:location} = item;
            const obj = JSON.parse(json);
            console.log(obj);

            switch (obj.type) {
              case 'reply':
                {
                  const {post_idx} = obj;
                  common.push({
                    name: Const.component.PostView,
                    param: {idx: post_idx}
                  })  ;
                }
                break;
            
              default:
                break;
            }

            const param = {idx};
            const url = Const._api + "my/notice_open";
            common.api_post(url, param, data => {
              console.log('data', data, location)
              // window.location = location;
            })              
          }
      },
      onNew: function(){
          console.log('onNew');
          
          if(this.me)
          {
            this.show_form = true;
          }
          else
          {
            console.log('로그인상태가 아님')
            // todo : 로그인창
          }
      },
      onSearch:function(data){
          console.log('onSearch', data);
          // todo : 검색
      },
      onClose: function(){
          this.show_form = false;
      },
      onDone: function(){
          console.log('onDone');
          this.show_form = false;
          this.rows1 = [];
          this.request(0);
      },
      onError: function(error, param){
        console.log('onError', error, param);
        common.toast(param);

      }
    },
  }
</script>
