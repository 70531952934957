<template>
    <div class="scroll-container">
        <title-bar-ex ref="titlebar" :title="'적립내역'" :container="container" :right="{}"/>

        <div class="margin-side">
            <my-cherry :point="point" />
            <div v-show="rows.length == 0">
                <no-data :data="nodata" />
            </div>
            <div ref="scroll_container" class="background-color" id="scroll-container" v-on:scroll.passive="handleScroll"  >
                <div class="middle" id="contents" >
                    <log-cell v-for="(item, index) in rows" v-bind:key="index" :data="item"></log-cell>
                </div>    
                <footer-view />
            </div>
            <!-- <slidemenu ref="slidemenu" v-on:move="onMove"></slidemenu>      -->
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TitleBarEx from './common/TitleBarEx.vue'
import LogCell from './common/LogCell.vue';
import NoData from './common/NoData.vue'
import MyCherry from './common/MyCherry.vue'
import FooterView from './common/FooterView.vue'

export default {
    components: {
        TitleBarEx,
        LogCell,
        NoData,
        MyCherry,
        FooterView,
    },
    props: {
      param: Object,
      container: Object,
    },
    setup() {
      // console.log('board setup');
      return {
      }
    },
    data(){         
      return {
        point: 0,
        rows: [],
        nodata: {
            html: '<pre style="font-size: 15px;">아직 적립내역이 없습니다.\n메시지를 확인하면 체리를 모을 수 있습니다.</pre>'
        },
        exchangable: 1,
        show_manual: false,
        manual: null,
        last: 0,
        total: 0,
        uset: null,
        idx: 0,

        // ui
        titleBarConfig:{
            is_write: false, 
            is_menu: true,
            left: [
                {
                    html: `<i class="button-color fa fa-bars navi-button"></i>`,
                    onClick: () => {this.$refs.slidemenu.is_show = true;}
                },
                {
                    html: `<div class="navi-button" style="font-weight: 900;">${Const.about}</div>`
                }
            ]
        },        
        title_left : [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
        ],
        left:[
          {
            html: '<i class="fa fa-chevron-left"></i>',
            onClick: this.onLeft,
          },
          {
            html: '<span class="material-symbols-outlined ">home</span>',
            onClick: this.onHome,
          },
        ],
        needs_more: true,
      }
    },    
    created() {
        this.idx = common.param("idx");
    },
    mounted() {
        this.$refs.titlebar.didLogin();

        console.log('mounted')
        {
            this.$refs.titlebar.left = this.title_left;
        }
        console.log('this.param', this.param)

        {
            const comp = {
                onEvent: this.onEvent,
            }
            common.registEventHandler(comp);
        }

        {
            const comp = {
                onEvent: this.onEvent,
                onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        this.request(0); 
    },
    methods:{
        request(start){
            const url = `${Const._api}my/pointlog/${start}`            
            console.log(url)

            let total = 0;

            axios.defaults.withCredentials = true;        
            const proc = (data) => {
                console.log(data)
                const {rows, row, user} = data; // 신규, 본것
                console.log(rows)

                this.rows = rows;

                this.point = user.remain_point;

                if(total == 0){
                    this.show_manual = true;
                    this.manual = row;
                }

                Object.assign( this.user, user);
            }
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });             
        },
        reload(){
            console.log('reload')
            this.request(0); 
        },
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {                
            case Const.notify.post_update:
                this.reload()
                break;
            default:
                break;
            }
        },
        onLeft(){
            common.back();
        },
        onHome(){
            this.$emit('event', 'home');
        },
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.empty {
  height: 200px;
}

.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

</style>

