<template>
    <div id="main-container">
      <TitleBar ref="titlebar" :data="titleBar" v-on:left="close" v-on:right="onDone"></TitleBar>

      <div class="margin-side" style="width: 80%; margin: 0 auto;">
        <div class="margin10">
          <!-- <textarea style="background:yellow; width: 100%; height: 50%; " placeholder="예의를 갖추지 않은 글은 삭제되며 활동을 정지 당할 수 있습니다." v-model="form.content" ></textarea> -->
          <!-- <auto-resize class="post-font-family " ref="textarea" style="width: 100%; " :placeholder="'예의를 갖추지 않은 글은 삭제되며 활동을 정지 당할 수 있습니다.'" :autoresize="true" /> -->

          <div>
            <my-cherry :point="user.remain_point" />            
            
            <input-box ref="input_tel" :data="input_tel" />

            <div style="margin-top: 20px;">
              <div style="font-size: 18px;" >교환할 상품권을 선택하세요</div>
              <div style="margin-top: 5px;">
                <select-menu :data="menus" ref="menus" v-on:selected="onSel" />
              </div>
            </div>

          </div>
        </div>

        <div @click="onDone" style="text-align: center; margin-top: 30px;" class="button-text-big">
          신청
        </div>
        <div style="text-align: center;" class="warning-color font-size12">{{error_msg}}</div>

        <!-- 바틈메뉴 -->

        <div style="height:100px;"></div>        


      </div>
    </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TitleBar from '@/components/common/TitleBar.vue'
import SelectMenu from '@/components/common/SelectMenu.vue'
import MyCherry from './common/MyCherry.vue'
import InputBox from './common/InputBox.vue'
// import AutoResize from './common/AutoResize.vue'

export default {  
  components: {
    TitleBar,
    SelectMenu,
    MyCherry,
    InputBox,
    // AutoResize,
  },
  props: {
    param: Object,
    controller: Object,
    close: Function,
  },
  computed: {
    // chips(){
    //   return this.insertComma(this.chips)
    // },
  },
  mounted() {
    // this.request();
    console.log(this.param)
    const {npay} = this.param;
    const user = this.user;

    let selected = -1;
    for (const iterator of npay) {
        console.log(iterator);

        if(iterator > user.remain_point) break;

        const obj = {
            title: `네이버페이 포인트쿠폰 ${iterator}원`,
            value: iterator,
        }

        this.point = iterator;

        this.menus.push(obj)
        selected++;
    }

    this.$refs.menus.selected = selected;

    this.updateUser();
  },  
    data: function () {    
    return {  
      input_tel:{
        title: '전화번호',
        comment: '상품권을 받을 전화번호를 입력하세요',
      },
        menus: [
            // {
            //     title: '500 포인트',
            //     value: 500,
            // },
            // {
            //     title: '1000 포인트',
            //     value: 1000,
            // },
        ], 
        phone: '',
        error_msg: '',
        my: 0, // 보유량
        naver_id: '',
        point: 0,
        titleBar:{
            title: "상품권교환 신청",
            // right: '<div class="text-button-color">발행</div>',
            right: '<div></div>',
            left: '닫기'
        },
        show_input: false,
    };
  },
    methods: {
        request(start = 0){
            const url = `${Const._api}my/exchange/list/${start}`            
            console.log(url)

            axios.defaults.withCredentials = true;        

            const proc = (data) => {
                console.log(data)
            }

            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });             
        },
        onSel(item, n){
            console.log('onSel', item, n)
            this.point = item.value;
        },
        updateUser(){
            console.log('this.user', this.user)
            const {naver_id, point, take_point} = this.user;
            this.naver_id = naver_id;
            this.my = point - take_point;
        },
        insertComma(val){
            const str = `${val}`
            var value = str.replace(/,/g, '');
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        onChange(){
            this.error_msg = '';
            if(this.my < this.point){
                this.point = this.my;
                this.error_msg = '보유포인트를 넘지 못합니다.';
                return;
            }
        },
    
        onDone(){          
            // 유효성 체크            
            if(this.my < this.point){
                this.error_msg = '보유포인트를 넘지 못합니다.';
                return;
            }

            console.log(this.$refs.input_tel.value);

            if(this.ttt == undefined) return;

            const proc = (data) => {
                console.log('onSuccess', data)

                const {error, user, msg} = data;
                if(error == 0){
                    Object.assign( this.user, user);
                    common.toast('교환 신청이 접수되었습니다.');
                    this.close();

                    common.notifyEvent(
                        Const.notify.post_update,
                        null,
                    );
                }
                else{
                    // common.toast(msg);
                    this.error_msg = msg;
                }
            }
            const url = Const._api + 'exchange/req'
            const param = {point: this.point, phone: this.phone, };
            console.log(param);
            axios.post(url, param )
            .then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });                    
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';


#main-container {
  height: 100%;
    /* background: yellow; */
  background-color: white;
  /* display: flex;
  flex-direction: column; */
  /* height: 100%; */
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  overflow: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#main-container::-webkit-scrollbar {
    display: none;
}

</style>

