<template>
  <div style="background: white; height: 100%; ">
    <div class="between margin-top10 padding10">
      <div />
      <button type="button" class="btn color-gray" @click="onClose()">Skip</button>
    </div>

    <!-- <div class="center-text" style="margin-top: 30px;margin-bottom: 30px" v-html="Abouting" />     -->

    <div class="login-container" style="margin-top: 10%; ">

      <div class="margin-bottom10" v-show="step == 1">

        <div style="text-align: center;font-size: 18px; font-weight: bold;">추천인 등록</div>

        <input-box :data="input_account" style="width: 80%; margin: 20px auto 0;" 
          v-on:changed="(param) => {this.account = param}" 
          v-on:entered="onMy"
        />

        <text-button :data="buttonMy" style="margin-top: 20px;"/>
        <div class="comment warning-color" style="text-align: center;" >{{ warning }}</div>
      </div>

      <div class="margin-bottom10" v-show="step == 2">

        <input-box ref="input_parent" :data="input_parent" style="width: 80%; margin: 0 auto;" 
          v-on:changed="(param) => {this.parent_account = param}" 
          v-on:entered="onParent"
        />

        <text-button :data="buttonParent" style="margin-top: 20px;"/>
        <div style="text-align: center;" class="comment warning-color">{{ warning }}</div>
      </div>

    </div>

    <div class="between padding10">
      <div />
      <div class="comment-input text-button-color">
        <!-- <div style="text-align:right" @click="onUser">사용자약관</div>
        <div @click="onPrivacy">개인정보 처리방침</div> -->
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TextButton from './common/TextButton.vue'
import InputBox from './common/InputBox.vue'

export default { 
  components: {
    TextButton,
    InputBox,
  }, 
    props:{
      param:Object,
      close: Function,
    },
    mounted() {
      console.log("mounted", this.param)
      this.caller = this.param.caller;

      const parent = common.getLocalParent()
      const invite_idx = common.getLocalEvent()
      console.log('this.parent', parent)

      this.$refs.input_parent.value = parent;
      this.parent_account = parent;
      this.invite_idx = invite_idx;

      common.setLocalParent('')

    },
    data: function () {
      console.log('data')
      return {  
        caller: null,
        input_account:{
          title : `추천인 등록을 위해 사용자 아이디 생성이 필요합니다.
추천인 등록을 원하지 않는 경우 생략(Skip)할 수 있습니다. 

`,
          html: `<span style="color: #dc3545">단, 생략하면 이후로 등록 할 수 없습니다.</span>`,
          comment: `사용할 아이디 생성 (영문, 숫자, 밑줄'_' 사용가능)`,
          value: null,
        },
        input_parent:{
          title: `추천인 아이디`,
          comment: `추천인이 있다면 아이디를 입력하세요. 
없다면 생략하세요.`,
          value: ''
        },
        buttonMy:{
            title: '생성하기',
            onclick: this.onMy,
        },
        buttonParent:{
            title: '추천인 등록',
            onclick: this.onParent,
        },
        //   name: value
        step: 1,
        account: '',
        parent_account: '',
        invite_idx: '',
        comment: '',
        warning: '',
        is_join: false,
        Abouting: `<div class="" style="font-size:24px; font-weight: 900;">${Const.about}</div>`
      };
    },
    methods: {
        isAlphanumericUnderscoreOnly(str) {
            // 정규표현식을 사용하여 문자열이 알파벳, 숫자, 또는 밑줄(_)만 포함하는지 체크
            const regex = /^[a-zA-Z0-9_]+$/;
            return regex.test(str);
        },
        onMy(){
            this.warning = '';
            const account = this.account.trim();
            this.account = account;

            const proc = (data) => {
                console.log(data);
                const {error, msg} = data;
                if(error == 0){
                    this.user.account = account;
                    console.log(this.user)
                    const param = {
                        header: '',
                        body: '정상적으로 등록되었습니다.',
                        callback: () => {
                          this.step = 2;
                        }
                    }
                    common.popupModal(param);                    
                }else{
                    console.log('전달실패')
                    this.warning  = msg;

                    return;
                }
            }

            if(false == this.isAlphanumericUnderscoreOnly(account)){
                this.warning = '알파벳과 숫자, 밑줄(_)만 사용가능합니다.'
                return;
            }
            const url= Const._api + "my/account/reg";
            axios.post(url, {account} )
            .then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });      
        },          
        onParent(){
          console.log('onParent', this.parent_account)
            this.warning = '';

            const proc = (data) => {
                console.log(data);
                const {error, msg} = data;
                if(error == 0){
                    this.parent_account = account;
                    const param = {
                        header: '',
                        body: '정상적으로 등록되었습니다.',
                        callback: () => {

                            common.notifyEvent(Const.notify.user)

                            this.close();
                        }
                    }
                    common.popupModal(param);                    
                }else if(error == 1){
                    console.log('마감된 이벤트입니다.')
                    this.warning  = msg;
                    return;
                }
            }

            const account = this.parent_account.trim();
            const invite_idx = this.invite_idx;
            console.log('account>', account, this.parent_account)
            if(false == this.isAlphanumericUnderscoreOnly(account)){
                this.warning = '알파벳과 숫자, 밑줄(_)만 사용가능합니다.'
                return;
            }

            const url= Const._api + "my/parent/reg";
            axios.post(url, {account, invite_idx} )
            .then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });      

        },      
      
        show: function(){
            this.account = '';

            // todo : 로컬스토리지에 있다면 바로 시도한다.            
        },
        onClose(){
          if(this.caller) this.caller.onClosePop(Const.component.ParentForm)
          this.close();
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sns > img {
  width: 166px;
  /* height: 40px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}
.container {
  display: flex; 
  flex-direction: column; 
  margin-left: auto; 
  margin-right: auto; 
  width: fit-content;
}

.join {
  display:flex; 
  margin-right: 0; 
  width: fit-content;
}

.open-button {
  /* background-color: #555; */
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  /* position: fixed; */
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.login-container {
    top:0;
    bottom: 0;
    /* right: 15px; */
    z-index: 9;
    max-width: 100%;
    padding: 10px 30px;
    /* height: 100vh; */
    background-color: white;
}

.login-container .btn {
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}
  
input::placeholder {
    /* opacity: .5; */
    color: rgb(128, 128, 128);
    /* padding-left: 10px; */
}

</style>

