<template>
    <div v-if="is_show" class = "image-container " @click="close">
        <img id="image" :src="url">
    </div>
</template>

<style scoped>
.image-container {
    width: 100%;
    height: 100%;
    position:fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
    display: flex;
    justify-content: center;    
    background: white;
}

#image {
    height: 100vh;
    width: 100vw;
    object-fit: contain;
}

</style>

<script>
export default {  
props:{
      data:Object
    },
    mounted() {
        // console.log('data', this.data)
    },
    data: function () {
      return {  
        is_show: false,
        param: null,
        url: '',
      };
    },
    methods: {
        show: function(url){
            this.is_show = true;
            this.url = url;
        }, 
        click:function(item){
            this.close();
            item.cb();

            console.log('this.is_show', this.is_show);
            // this.$emit('clicked', item, index);
        },
        close:function(){
            this.is_show = false
        },
    },      
}

</script>

