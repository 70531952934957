<template>
  <div class="cell-container">
    <div class="card"  @click="click(data)">
        <div class="row">
            <div class="label">체리</div>
            <div>{{ data.point }}</div>
        </div>
        <div class="row">
            <div class="label">수단</div>
            <div :style="{color : data.type == 1 ? color_pick : color_member}">{{data.type == 1 ? '메시지확인' : '추천인보너스'}}</div>
        </div>
        <div class="row">
            <div class="label">정보</div>
            <div @click="onDone" class="title" >{{ info }}</div>
        </div>
        <div class="row">
            <div class="label">발생시간</div>
            <div>{{ timeString(data.created) }}</div>
        </div>
        <div v-show="data.reply">
            <div>{{ data.reply }}</div>
        </div>
    </div>
  </div>
</template>

<script>
import common from '@/common'
// import Const from '@/const'
// import BoardTitle from './BoardTitle.vue';

export default {  
    components: { 
        // BoardTitle 
    },  
    props:{
      data:Object,
    },
    mounted() {
        // console.log('mounted', this.data)

        const obj = JSON.parse(this.data.json)
        if(this.data.type == 1)
        {
            this.info = obj.title
        }
        else if(this.data.type == 2){
            this.info = obj.account;
        }
    },
    data: function () {
      return {  
        color_pick: 'rgb(185, 12, 46)',
        color_member: 'green',
        info: '',
        json: {}
      };
    },
    methods: {
        onDone(){
            this.$emit('done', this);
            // this.data.done = !this.data.done;
        },
        timeString(t){
            return common.timeString(t)
        },
        onShow(){
            this.$emit('show', this.data.idx, this.hidden, this)
        },
        click:function(data){
            // console.log('postcell.click : ', data)    
            this.$emit('clicked', data)
        },
        manage(type){
            console.log('manage', type)
            this.$emit('manage', type, this.data, this)
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.admin-bar {
  display: flex;
  margin: 10px 0;
}

.margin {
  margin: 5px 0 5px 0 ;
}

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.post-container {
  border-radius: 5px;
  padding: 10px;
  /* border: 2px solid #ccc; */
  /* background-color: #eee; */
  /* padding: 16px; */
}

.left {
  display: flex;
}

.post-container::after {
  content: "";
  clear: both;
  display: table;
}

/* .post-container img {
  float: left;
  margin-right: 20px;
  border-radius: 5%;
} */

.post-container span {
  font-size: 20px;
  /* margin-right: 15px; */
}


.post-container .hash {
  background: yellow;
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.post-container .like {  
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}

.title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row {
    display: flex;
    margin-bottom: 10px;
}
.label {
    flex: none;
    margin-right: 10px;
    color: gray;
}

.card {
    margin: 5px;
}

</style>

