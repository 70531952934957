<template>    
    <div class="intro-back">
        <intro-view :data="data" />
    </div>
</template>

<style scoped>
@import '@/assets/common';
@import '@/assets/intro';

.icon {
    font-size: 80px;
  }


</style>

<script>
import IntroView from './IntroView.vue';
// import Const from '@/const'
export default {  
    setup() {
        return {
        };
    },
    components: {
        IntroView,
    },  
    props:{
    },
    mounted() {
        console.log('Intro1', this.data)
    },
  data() {
    return { 
        data: [
            {
                header: '세상에 하고 싶은 말!',
                icon : 'campaign',
                contents:  `사람들에게 하고 싶은 이야기가 있나요?
잠깐 시간내어 이야기를 들어주길 원하나요?`
            },
            {
                header: '외면, 삭제, 강퇴',
                // icon : 'campaign',
                img: {
                    src: 'https://t1.daumcdn.net/cfile/tistory/99D61B345B35DB5D19',
                    style: {width: '60%'}
                },
                contents:  `혹시 당신의 글이 외면 받거나 
홍보라는 이유로 
관리자에게 삭제당한 적이 있으신가요?`
            },
            {
                header: '간결한 메시지 전달',
                // icon : 'campaign',
                img: {
                    src: 'https://images.mypetlife.co.kr/content/uploads/2019/08/09153147/thomas-q-INprSEBbfG4-unsplash.jpg',
                    style: {width: '80%'}
                }, 
                contents:  `당신의 메시지를 
잘 정리하여 
다섯장의 카드로 전달해보세요.`
            }, 
            {
                header: '청중들에게 답례하기',
                // icon : 'campaign',
                img: {
                    src: 'https://images.mypetlife.co.kr/content/uploads/2019/08/09153147/thomas-q-INprSEBbfG4-unsplash.jpg',
                    style: {width: '80%'}
                }, 
                contents:  `그리고 이야기를 들어준 청중에게 
체리를 선물하세요.`
            },                       
        ] 
    };
  },
  methods: {
  },      
}
</script>

