<template>
  <div class="cell-container">
    <div class="card"  @click="click(data)">
        <div class="row">
            <div class="label">입금자명</div>
            <div>{{ name }}</div>
        </div>
        <div class="row">
            <div class="label">입금날짜</div>
            <div>{{ time }}</div>
        </div>
        <div class="row">
            <div class="label">이메일</div>
            <div>{{ email }}</div>
        </div>
        <div class="row">
            <div class="label">전화번호</div>
            <div>{{ phone }}</div>
        </div>
        <div class="row">
            <div class="label">총액(부가세포함)</div>
            <div>{{ cost }}</div>
        </div>
        <!-- <div class="row">
            <div class="label">금액</div>
            <div>{{ cost }}</div>
        </div> -->
        <!-- <div class="row">
            <div class="label">할인율</div>
            <div>{{ discount }}</div>
        </div>
        <div class="row">
            <div class="label">정가</div>
            <div>{{ sum }}</div>
        </div>
        <div class="row">
            <div class="label">전송비</div>
            <div>{{ fee }}</div>
        </div> -->
        <div class="row">
            <div class="label">반영여부</div>
            <div @click="onDone" :style="{color : done ? 'blue' : 'red'}">{{ done ? '완료' : '대기' }}</div>
        </div>
        <div v-show="reply">
            <div>{{ reply }}</div>
        </div>
    </div>
  </div>
</template>

<script>
import common from '@/common'
// import Const from '@/const'
// import BoardTitle from './BoardTitle.vue';

export default {  
    components: { 
        // BoardTitle 
    },  
    props:{
      data:Object,
    },
    mounted() {
        console.log('mounted', this.data)
        try {
            const param = JSON.parse(this.data.json)
            // console.log('this.json>', param)

            const submit = JSON.parse(this.data.json_submit)

            this.sum = param.sum;
            this.cost = this.data.cost;
            this.fee = param.fee;
            this.discount = param.discount;
            this.account = param.account;
            this.taxed = param.taxed;
            this.name = submit.name;
            this.time = submit.time;
            this.email = submit.email;
            this.phone = submit.phone;
            
        } catch (error) {
            console.error('json error', this.data.json)
        }
    },
    data: function () {
      return {  
        sum: 0,
        fee: 0,
        discount: 0,
        account: 0, 
        taxed: 0, 
        name: 0,
        time: '',
        email: '',
        phone: '',
        cost: '',
        done: 0,
        reply: '',
        json: {}
      };
    },
    methods: {
        onDone(){
            this.$emit('done', this);
            // this.data.done = !this.data.done;
        },
        timeString(t){
            return common.timeString(t)
        },
        onShow(){
            this.$emit('show', this.data.idx, this.hidden, this)
        },
        click:function(data){
            // console.log('postcell.click : ', data)    
            this.$emit('clicked', data)
        },
        manage(type){
            console.log('manage', type)
            this.$emit('manage', type, this.data, this)
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.admin-bar {
  display: flex;
  margin: 10px 0;
}

.margin {
  margin: 5px 0 5px 0 ;
}

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.post-container {
  border-radius: 5px;
  padding: 10px;
  /* border: 2px solid #ccc; */
  /* background-color: #eee; */
  /* padding: 16px; */
}

.left {
  display: flex;
}

.post-container::after {
  content: "";
  clear: both;
  display: table;
}

/* .post-container img {
  float: left;
  margin-right: 20px;
  border-radius: 5%;
} */

.post-container span {
  font-size: 20px;
  /* margin-right: 15px; */
}


.post-container .hash {
  background: yellow;
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.post-container .like {  
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}

.title {
    font-size: 20px;
    font-weight: 400;
  }

.row {
    display: flex;
    margin-bottom: 10px;
}
.label {
    margin-right: 10px;
    color: gray;
}

.card {
    margin: 5px;
}

</style>

