<template>
  <div class="cell-container">
    <div class="card"  @click="click(data)">
        <div v-for="(item, index) in data" v-bind:key="index" class="row">
            <div style="display: flex;">
                <div class="label"><strong>{{ item.title }}</strong></div>
                <div>{{ item.value }}</div>
            </div>
            <div style="font-size: 12px; color: gray;" v-html="item.comment"></div>
        </div>
    </div>
  </div>
</template>

<script>

export default {  
    components: { 
        // BoardTitle 
    },  
    props:{
      data:Array,
    },
    mounted() {
        console.log('mounted', this.data)
    },
    data: function () {
      return {  
      };
    },
    methods: {
        click:function(data){
            // console.log('postcell.click : ', data)    
            this.$emit('clicked', data)
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';


img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.row {
    /* display: flex; */
    margin-bottom: 10px;
}
.label {
    margin-right: 10px;
    color: gray;
}

.card {
    margin: 5px;
}

</style>

