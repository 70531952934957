<template>
    <div class="center background-color">
        <div class="searchpop content  " v-on:scroll.passive="handleScroll">
            <TitleBarEx ref="titlebar" :data="titleBarConfig" />

            <!-- <div class="text-center">Abouting</div> -->
            <div class="cell-background-color padding10">
                <div class="text-center post-font-family margin20" style="font-size:15px;">당신이 생각하는 그것과 그곳에 대한 이야기</div>
                <div class="text-center">
                    <input class="searchpop-input" type="search" ref="input" @input="onChange" @keypress.enter="onSearch" v-model="val" placeholder="무엇에 대해 이야기하고 싶으세요?" name="search">
                    <!-- <button type="submit" @click="click" ><i class="fa fa-search"></i></button>             -->
                </div>
                <!-- <div style="background: white; width:100%;">
                </div> -->
                <TabMenu class="" ref="tabmenu" style="width: 50%;; margin:auto; margin-top: 0;" :data="menus" v-on:clicked="onTabMenu"></TabMenu>
                <div class="comment ">{{comment}}</div>
                <ScrollMenu ref="scrollMenu" :data="scrollMenu" v-on:clicked="onScrollMenu"/>
            </div>

            <!-- 검색결과 페이지 -->
            <div v-if="search_mode">
                <div class="text-center background-white bold-black font-size14" style="margin-top:20px">검색결과</div>

                <div v-show="selectedTab == str_arround">
                    <LocationBar  ref="locationbar"
                        v-on:success="onGotAddress" 
                        v-on:failed="onFailedAddress" 
                        />
                </div>

                <LetsWrite 
                    v-if="result.not_found"
                    v-on:clicked="doMake()" 
                    :text="`'${range}' 사람들과 이야기하는\n '${search_param.text}' ${name5} 없습니다.\n${name2} 만들고 가장 먼저 글을 남겨보세요.`"
                />

                <!-- 일치하는 결과 -->
                <boardcell v-if="result.exact"                
                    :data="result.exact" 
                    v-on:clicked="onSelBoard(result.exact.idx)" 
                />

                <!-- 그밖의 결과 -->
                <div class="margin10 margin-top20" style="font-size:14px;" v-if="result.similar.length > 0" v-html="`그밖의 ${name}`"></div>
                <div v-for="(item, index) in result.similar" v-bind:key="index">
                <boardcell
                    :data="item" 
                    v-on:clicked="onSelBoard(item.idx)" />
                </div>
            </div>

            <!-- 인기페이지 -->
            <div v-else>
                <div v-show="selectedTab == str_arround">
                    <LocationBar  ref="locationbar"
                        v-on:success="onGotAddress" 
                        v-on:failed="onFailedAddress" 
                        />
                    <div 
                        v-if="!show_try_button && aroundBoards.length > 0"  
                        class="comment-now" >
                        {{`동네사람들은 이 ${name}에서 많이 이야기하고 있어요`}}
                    </div>
                </div>
                
                <!-- <div v-show="selectedTab == str_world" class="comment-now" style="padding:20px;">세상사람들은 여기에서 이야기하고 있어요.</div> -->
                <div 
                    v-show="selectedTab == str_world && allBoards.length > 0" 
                    class="comment-now" >{{`세상사람들은 이 ${name}에서 많이 이야기하고 있어요.`}}
                </div>

                <!-- 주변의 관심 -->
                <div class=""
                    v-show="selectedTab == str_arround" 
                >
                    <div v-if="show_try_button" class="margin-bottom">
                        <div v-if="aroundBoards.length == 0" class="no-location" >
                        {{str_empty}}
                        </div>

                        <LetsWrite class="margin-top10"
                            :text="'내 주변의 관심사를 둘러보세요.\n'"
                            :title="'현재 위치 가져오기'"
                            v-on:clicked="$refs.locationbar.search()"
                        />
                    </div>

                    <boardcell 
                        v-for="(item, index) in aroundBoards" v-bind:key="index" 
                        :data="{...item, index, rank: true}" 
                        id="around_board" 
                        v-on:clicked="onSelBoard(item.idx)" 
                    />

                </div>            

                <!-- 세상의관심 -->
                <div class="" 
                    v-show="selectedTab == str_world" 
                    v-on:scroll.passive="handleScroll"
                >
                    <boardcell 
                        v-for="(item, index) in allBoards" v-bind:key="index" 
                        :data="{...item, index, rank: true}"
                        v-on:clicked="onSelBoard(item.idx)" 
                    />
                    <!-- <div class="tail"></div> -->
                </div >

                <!-- 나의관심 -->
                <div class="margin-top" 
                    v-show="selectedTab == str_my" 
                    v-on:scroll.passive="handleScroll"
                >
                    <boardcell 
                        v-for="(item, index) in myBoards " v-bind:key="index" 
                        :data="{...item, index}"
                        v-on:clicked="onSelBoard(item.board_idx)" 
                    />
                    <!-- <div class="tail"></div> -->
                </div >
            </div>
            
            <div class="tail"></div>
        </div>   
        <slidemenu ref="slidemenu" v-on:move="onMove"></slidemenu>     
    </div>
</template>

<script>
import common from '@/common';
import Const from '@/const'
import TabMenu from '@/components/common/TabMenu.vue'
import boardcell from '@/components/common/BoardCell.vue'
import LocationBar from '@/components/common/LocationBar.vue'
import slidemenu from '@/components/SlideMenu'
// import Navbar from '@/components/common/NavBar'
import LetsWrite from './common/LetsWrite.vue';
import TitleBarEx from './common/TitleBarEx.vue';
// import TagBar from './common/TagBar.vue';
import ScrollMenu from './common/ScrollMenu.vue';

const str_arround = '동네'
const str_world = '세상'
const str_my = '나의 관심'

const menus = [str_world, str_arround];
const str_no_board = '등록된 관심사가 아직 없습니다.'

export default {
    name: 'searchPop',

    components: {
        TabMenu,
        boardcell,
        LocationBar,
        // TitleBar,
        slidemenu,
        // Navbar,
        LetsWrite,
        TitleBarEx,
        // TagBar,
        ScrollMenu,
    },    
    setup() {
    },
    computed: {
        range(){
            return this.search_param.range == str_arround ? `<b class="color-primary">${this.address}</b>` : '<b class="color-primary">세상</b>'
        },
        comment(){
            switch (this.selectedTab) {
                case str_arround:
                    return `동네사람끼리 이야기하는 ${Const.brand.name2} 만듭니다`
                case str_world:
                    return `모두가 함께 이야기하는 ${Const.brand.name2} 만듭니다`
                case str_my:
                    return ''
                default:
                    return ''
            }
        }
    },
    props:{
      data:Object,
      container: Object,
    },
    data () {
      return {
        name: Const.brand.name,
        name2: Const.brand.name2,
        name5: Const.brand.name5,

        google_keywords: [],
        google_keywords_raw: [],
        around_keywords:[],
        around_keywords_raw:[],
        scrollMenu:[],
        titleBarConfig:{
            is_write: false, 
            is_menu: true,
            left: [
                {
                    html: `<i class="button-color fa fa-bars navi-button"></i>`,
                    onClick: () => {this.$refs.slidemenu.is_show = true;}
                },
                {
                    html: `<div class="navi-button" style="font-weight: 900;">${Const.about}</div>`
                }
            ]
        },
        search_mode: false,
        search_param:{
            range: '',
            text: '',
        },
        result:{
            exact: null,
            similar: [],
        },
        str_arround,
        str_world,
        str_my,

        titleBar: {
            right: '<i class="button-color fa fa-bars"></i>',
            left: '',
        },
        val: '',
        nearby: false,
        menus,
        selectedTab: str_world, // str_arround,
        aroundBoards: [],
        allBoards: [],
        myBoards: [],
        str_empty: '',
        user: null,
        show_try_button: true,    // 위치정보 가져오기 버튼
        needs_location: Const.message.needs_location,
        address: '',
      };
    },
    mounted() {
        this.locationbar = this.$refs.locationbar;

        {
            const url = Const._api + 'board/keyword/world';

            common.api_get(
                url, 
                res => {
                    const {error, rows} = res.data; // {title, idx}

                    this.google_keywords_raw = rows;

                    if(error == 0){
                        rows.forEach(element => {
                            this.google_keywords.push(`${element.title}`) 
                        });                        
                    }

                    // 초기 스크롤 메뉴
                    this.scrollMenu = this.google_keywords;
                }
            );  
        }

        // 이벤트 핸들러
        {
            const comp = {
                onEvent: this.onEvent,
                onShow: this.onShow,
            }
            common.registEventHandler(comp)
        }

        common.checkSession( this.updateView)

        {
            const path = location.search;
            console.log('location', location);

            const arr = path.split('/');

            console.log('arr', arr);

            // if(
            //     process.env.NODE_ENV == 'production' 
            //     && arr[1] != 'a243f0e4961e32a482e1bbf873c7247b')
            //     window.location = '/abouting.html'

            if(arr.length > 2){
                const type = arr[1];
                const idx = arr[2]
                // const recommend = arr.length > 3 ? arr[3] : 0;
                // console.log(type, idx, recommend);

                switch (type) {
                    case 'name':
                        if(
                            idx == 'steve'
                            || idx == 'hoseon'
                            || idx == 'seungho'
                            || idx == 'bae'
                        )
                        {
                            localStorage.setItem('pass', idx)
                        }
                        break;
                    case 'local':
                        common.push({
                            name: Const.component.LocalView,
                            param: {idx}
                        })
                        return;
                        // break;
                    case 'board':
                        common.push({
                            name: Const.component.SavedView,
                            param: {idx}
                        })
                        return;
                        // break;
                    case 'post':
                        common.push({
                            name: Const.component.PostView,
                            param: {idx}
                        })
                        // break;
                        return;
                    case 'e1': // 추천 이벤트
                        {
                            // todo
                            // 서버에 전달
                            // const code = parseInt(idx, 16);
                            common.api_get(
                                Const._api + `my/recommend/${idx}`, 
                                res => {
                                    const {error} = res.data; // {title, idx}
                                    error;
                                    window.location = '/'
                                }
                            );                              
                        }
                        break;
                    default:
                        break;
                }
            }        
        }  

        // localStorage.setItem('intro', 0)
        if(localStorage.getItem('intro') != 1)
        {
            const obj = {
                name: Const.component.IntroView,
                param: {}
            }
            common.popUp(obj)
            
            localStorage.setItem('intro', 1)
        }
    },    
    methods: {   
        onScrollMenu(data, n){
            console.log('onScrollMenu', data, n)

            // 추천게시판의 경우 게시판이 없다면 바로 생성하고 가야 한다.

            // 만들어놓고...

            console.log('this.around_keywords_raw', this.around_keywords_raw)

            const idx = this.selectedTab == str_world ? this.google_keywords_raw[n].idx : this.around_keywords_raw[n].idx;
            
            this.onSelBoard(idx);
        },
        resetSearch(){
            this.search_mode = false;
            this.val = '';
            this.search_param = {
                range: '',
                text: '',
            }                    
        },
        doMake(){
            console.log('doMake', this.search_param);
            
            const url = Const._api + "board/make";
            const body = {
                text: this.search_param.text,
                around: this.search_param.range == str_arround ? 1 : 0,
            }

            common.api_post(
                url, body, 
                data => {
                    // console.log(data);
                    const {error, msg} = data;
                    if(error > 0){
                        common.toast(msg)            
                    }
                    else{
                        // around ? this.around.not_found = false : this.world.not_found = false;
                        this.resetSearch();

                        const {board} = data.row;

                        common.push({
                        name: Const.component.SavedView,
                        param: {
                            idx: board.idx,
                            write: true,
                        } 
                        })
                    }
                }
            )        
        },        
        onChange(){
            // this.val = this.val.trim();
            if(this.val.length == 0){
                this.resetSearch();
                return;
            }

            {
                this.val = common.shrinkSpace(this.val);
                if(this.val.length > Const.value.max_length ){
                    common.toast(`공백포함 최대 ${Const.value.max_length}자까지 가능합니다.`)

                    this.val = String(this.val).substring(0,(Const.value.max_length));
                }
            }
        },
        onMove(location_idx){
            console.log('onMove', location_idx)

            this.$refs.locationbar.movePosition({location_idx})
        },
        checkMe(){
            // 스크롤 움직임에서도 체크하면 좋다. 수시로...
            if(this.checking) return;
            this.checking = true;

            common.checkMe( (res) => {
                console.log('checkMe', res.data);
                const {data} = res;
                const {error, user} = data;

                this.session.user = user;

                if(error === 0 && user && user.idx){
                    this.updateTitleBar();
                }
            })   
            
            setTimeout( () => { 
                this.checking = false;
            }, 1000 * 10); 
        },
        onShow(){
            this.checkMe();
        },
        updateView(isLogin, user){            
            console.log('isLogin', isLogin, user)

            this.session.user = user;

            const cb = () => {
                this.$refs.locationbar?.moveSavedLocation();
            }

            this.requestBoards(cb);

            if(isLogin){ // 로그인 상태라면...
                this.requestMyBoards();

                Object.assign( this.user, user);

                this.session = user;

                this.$refs.slidemenu.is_login = true;
                this.$refs.slidemenu.is_temp = user.is_temp ? user.is_temp : false;

                this.updateTitleBar(user);
            }
            else if(this.config.temp) // 서버세션도 없고 로컬 계정정보가 없다. 새로 접근한 경우다.
            {                
                const url= Const._api + "temp_account_is_temp";

                const params = {
                serail: 'xxxxxx' // todo: 봇이 아님을 확인하는 인증값을 담아야 한다.
                };

                common.api_post(url , params, (data) => {                        
                    var {error, msg} = data;
                    if(error > 0){
                        localStorage.setItem('uid', '');
                        localStorage.setItem('pw', '');
                        
                        const param = {
                            header: '오류발생',
                            body: msg,
                        }
                        common.popupModal(param);                  
                    }
                    else{
                        // 임시 계정 완료
                        console.log('생성 완료')
                        const {uid, pw} = data;
                        localStorage.setItem('uid', uid);
                        localStorage.setItem('pw', pw);

                        // 안내메시지 보여주고 확인을 받으면 시작
                        const param = {
                            header: '환영합니다.',
                            body: '가입 없이\n흔적 없이\n 부담 없이\n\n매너 있게\n 재미있게 \n\n즐거운 소통하십시요',
                            callback: () => {
                                this.uid = uid;
                                this.pw = pw;
                                // this.login(); // 
                                location.reload();
                            }
                        }
                        common.popupModal(param);
                    }
                });     
            }

            if(user && user.level == 1){
                common.push({
                    name: Const.component.MonitorView,
                })                
            }
        },
        updateTitleBar(user){
            user
            this.$refs.titlebar.didLogin();
            
            this.$refs.titlebar.updateButtonState()
        },
        onEvent(name, param){
            console.log('onEvent', name, param, this.idx); // user 넘어온다.

            switch (name) {
                case Const.notify.login:
                    common.checkSession(this.updateView) // 다시 유저정보 가져오기 
                break;

                case Const.notify.bookmark:
                    console.log('case Const.notify.bookmark:');
                    this.requestMyBoards();
                break;
            
                default:
                break;
            }
        },           
        onNavbar: function(button){
            console.log('onAppBar', button);
            switch (button) {
                case 'login':
                    common.popupLogin();
                break;
                case 'menu':
                // this.$refs.slidemenu.is_show = true;
                {
                    const obj = {
                        name: Const.component.IntroView,
                    }
                    common.popUp(obj)
                }
                break;
                case 'my':
                
                break;
            
                default:
                break;
            }
        },        
        onMenu(){
            this.$refs.slidemenu.is_show = true;
        },
        onGotAddress(address, location_idx){
            console.log('onGotAddress', address, location_idx);
            this.address = address.region_3depth_name;
            this.show_try_button = false;

            switch (this.selectedTab) {
                case str_arround:
                this.requestAroundBoard();
                break;
                case str_world:
                this.requestBoards();
                break;
            }

            // 동네 키워드 가져오기
            {
                const url = Const._api + `board/keyword/around/${location_idx}`;

                common.api_get(
                    url, 
                    res => {
                        const {error, rows} = res.data; // {title, idx}

                        this.around_keywords_raw = rows;

                        if(error == 0){
                            rows.forEach(element => {
                                this.around_keywords.push(element.title)
                            });                        
                        }

                        // 초기 스크롤 메뉴
                        // this.scrollMenu = this.around_keywords;
                    }
                );  
            }
        },
        onFailedAddress(msg){
            common.toast(msg);
        },        
        requestAroundBoard(){
            const url = Const._api + 'board/around';

            common.api_get(
                url, 
                res => {
            const {error, msg} = res.data;
            if(error > 0){
                console.log('msg', error, msg);
                switch (error) {
                case Const.err_code.not_login:
                    
                    break;
                case Const.err_code.not_location:
                    {
                        this.str_empty = msg;
                        this.show_try_button = true;
                    }
                    break;              
                default:
                    common.toast(msg)
                    break;
                }
                return;
            }

            const {rows} = res.data;

            if(this.aroundBoards.length == 0 && rows.length == 0)
            {
                this.str_empty = str_no_board;
                return;
            }

            rows.forEach(element => {
                const temp = element.name.split('@');
                element.location = temp[1];
                element.address = element.location ? element.name.split('>')[2] : '', 
                element.name= temp[0];
            });

            this.aroundBoards = rows;
            });  
        },
        requestBoards: function(cb){
            const url = Const._api + 'board/hot';
                    
            common.api_get(url, res => {
                const {error, msg, rows} = res.data;
                if(error > 0){          
                    this.showModal('오류', msg);
                return;
                }

                this.allBoards = rows;

                cb ? cb(): {};
            });
        },      
        requestMyBoards(){
            console.log('requestMyBoards');
            const url = Const._api + 'board/likes';
                
            common.api_get(url, res => {
                const {error, msg, rows} = res.data;
                if(error > 0){
                    this.showModal('오류', msg);
                    return;
                }
                else if(error == 100){
                    common.tryLogin();
                    return;
                }

                rows.forEach(element => {
                    if(element.name){
                        const temp = element.name.split('@');
                        element.location = temp[1];
                        element.address = element.location ? element.name.split('>')[2] : '', 
                        element.name= temp[0];
                    }

                    element.info1 = element.content
                    element.bottomRight = common.elapsedTime(element.updated);
                });

                // if(this.myBoards.length == 0 && rows.length > 0){
                //     this.$refs.tabmenu.menus.push(str_my);
                // }

                this.myBoards = rows;

            });
        },          
        handleScroll(e){
            const {target} = e;
            const {clientHeight, scrollHeight, scrollTop} = target;

            if((clientHeight + scrollTop) == scrollHeight) 
            {
                // this.onScrollBottom();
            }
            else if(scrollTop == 0){
                // console.log('top')
                // this.onScrollTop();
            } 

            this.checkMe();
        },   
        onSelBoard(idx){  
            idx;

            // test
            // {
            //     const obj = {
            //         name: Const.component.IntroView,
            //         param: {}
            //     }
            //     common.popUp(obj)
            // }

            common.push({
                name: Const.component.SavedView,
                param: {idx}
            })
        },
        onTabMenu(menu){
            // console.log('onTabMenu', menu);
            this.resetSearch();
            this.selectedTab = menu;
            switch (menu) {
                case str_arround:
                    if(this.aroundBoards.length == 0) this.requestAroundBoard()
                    this.scrollMenu = this.around_keywords;
                break;
                case str_world:
                    this.scrollMenu = this.google_keywords;
                    if(this.allBoards.length == 0) this.requestBoards()                
                break;            
                case str_my:

                break;            
                default:
                    break;
            }
        },
        onSearch(){
            this.$refs.input.blur();
            
            this.val = this.val.trim();

            if(this.val.length == 0) return;

            // this.val = common.shrinkSpace(this.val);

            // if(this.val.length > Const.value.max_length){

            //     this.val = String(this.val).substring(0,(Const.value.max_length));

            //     common.toast(`공백을 포함하여 ${Const.value.max_length}자까지 입력이 가능합니다.`)
            //     return;
            // }
            
            const text = this.val;

            this.result = {
                exact: null,
                not_found: false,
                similar: []
            }

            this.search_mode = true;
            this.search_param.text = text;
            this.search_param.range = this.selectedTab;

            let url;            
            if(this.selectedTab == str_arround){
                // 주변 검색 결과
                url = Const._api + "board/search/around2";
            }
            else if(this.selectedTab == str_world){
                // 세상 검색 결과
                url = Const._api + "board/search/world";
            }

            const body = {
                    text,
            }

            // const onError = (msg, error) => {
            //     if(error == Const.err_code.not_location){

            //     }
            // }

            common.api_post(
                url, body, 
                data => {
                    console.log('search>', data)

                    const {result} = data;

                    if(result.error == 1) // 위치정보가 없슴
                    {
                        // 화면에 안내 표시
                        common.toast('현재 위치를 알 수 없습니다.');
                    }
                    else // if(around.error == 0)
                    {
                        result.location_name = this.title;                                        
                        result.keyword = text;
                        result.not_found = true;

                        console.log('result.exact', result.exact)

                        const setAddress = (obj) => {
                            const temp = obj.name.split('@');
                            obj.address = obj.name.split('>')[2];
                            obj.name= temp[0];
                        }

                        if(result.exact){
                            const {exact} = result;
                            
                            if(exact.is_geo){
                                setAddress(exact)
                            }
                            result.not_found = false;            
                        }

                        result.similar.forEach(element => {
                            if(element.is_geo){
                                setAddress(element)
                            }
                            // const temp = element.name.split('@');
                            // element.location = temp[1];
                            // element.name= temp[0];
                        });            

                        this.result = result;
                    }
                }
            )                   
        },
        onHome(){
            window.location = '/';
        }
    },  
}
</script>

<style scoped>
@import '@/assets/common';

.comment-now {
    /* text-align: center; */
    background: white;
    font-size : 14px; 
    margin-top: 5px;
    padding:10px;
}

.tail {
    height: 100vh;
    background: white;
}

.close {
    padding: 10px;
    /* font-size: 20px; */
    /* background: yellow; */
}

.content {
    /* padding-top: 30px; */
}

.text-center {
    display: flex;
    justify-content: center;
}

.searchpop {
    /* height: 100%; */
    width: 100%;
    /* height: 100vh; */
    display: block;
    /* position: fixed; */
    z-index: 1;
    top: 0;
    left: 0;
}

.searchpop-content {
    display: block;
    /* position: relative; */
    top: 36%;
    width: 80%;
    text-align: center;
    margin: auto;
}

.searchpop .closebtn {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 40px;
    cursor: pointer;
    color: black;
}

.searchpop .closebtn:hover {
    color: #ccc;
}

.searchpop input[type=text]:hover {
    background: #f1f1f1;
}

.searchpop button {
    /* float: left; */
    width: 20%;
    padding: 15px;
    background: dodgerblue;
    font-size: 17px;
    border: none;
    cursor: pointer;
}

.searchpop button:hover {
    background: #bbb;
}

/* 스위치  */
.searchpop-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.searchpop-switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>



