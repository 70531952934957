<template>
    <div style="margin-top: 20px;" @click="data.click">
        <div class="block-title">{{data.title}}</div>
        <div class="block">
            <div style="text-align: center; font-weight: bold;">{{ data.value }}</div>
            <div class="between">
                <div></div>
                <div v-show="data.show_button" class="color-text-button" @click="data.button?.click" v-html="data.button?.title" />
            </div>
        </div>
        <div class="block-comment">{{data.comment}}</div>
    </div>
</template>

<script>
export default {  
    props:{
      data:Object,
    },
    data: function () {
      return {  
      };
    },
    mounted() {
    },
    methods: {
      click:function(){
          this.$emit('clicked')
      },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.block {
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px ;
}

.block-title {
    font-size: 14px;
    color: gray;
}

.block-comment {
    font-size: 12px;
    margin-top: 5px ;
    color: gray;
}


</style>



