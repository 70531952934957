import { createApp } from 'vue'
import App from './App.vue'

// import VueScroller from 'vue-scroller'
// App.use(VueScroller)

const app = createApp(App)

console.log('mode > ', process.env.NODE_ENV); // "development", "production" or "test"

app.config.globalProperties.user = {idx:0, memo:'init'};
app.config.globalProperties.session = { name: 'cherrymoa'}
app.config.globalProperties.config = { 
    temp: false, // 임시계정 지원
    logout: true, // 로그아웃 메뉴 보이기
}
app.config.globalProperties.ui = {}

// app.use(VueScroller)
app.mount('#app')

app.directive('prevent-scroll', {
    bind(el, binding) {
      el.addEventListener('scroll', binding.value, { passive: false });
    },
    unbind(el, binding) {
      el.removeEventListener('scroll', binding.value);
    }
  });

var deviceWidth = window.innerWidth;
console.log("디바이스의 화면 폭: " + deviceWidth + "px");





