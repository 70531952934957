<template>
    <div class="email-reg">
        <div v-if="email && email.length > 0 "><b>{{ email }}</b>로 새소식을 전달중입니다.</div>
        <div v-else class="between">
            <div>충전금 : {{ mychips }}</div>
            <div class="color-text-button" @click="onReload" >새로고침</div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import util from '@/util'
import Const from '@/const'

export default {  
    props:{
      email:String
    },
    data() {
      return {
        chips: '',
      };
    },
    mounted() {
        console.log('mounted', this.user)

        this.chips = this.user.chips;
    },
    computed: {
        mychips(){
            return `${util.insertComma(this.chips)}원`
        }
    },
    methods: {        
        onReload(){
            const proc = (data) => {
                console.log(data)

                const {user} = data;
                this.user = user;
                this.chips = this.user.chips;
            }
            
            this.chips = '...'

            setTimeout(() => {
                const url = `${Const._api}chips`
                console.log(url);
                axios.get(url).then(common.checkResponse(proc))
                .catch(error => {
                    console.error(error);
                }); 

            }, 1000);
        },
        click(param){
            param;
            this.$emit('clicked', this.data)
        },
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.email-reg {
    font-size: 14px;
    background: white;
    padding: 10px;
}

</style>



