<!-- 애니메이션 효과로 나타나는 뷰 -->
<template>
  <div class="top-container" ref="topContainer" @touchstart="onTouchStart" @touchend="onTouchEnd" @touchmove="onTouchMove">
    <transition 
      :name="comp.animation ? 'slide-fade' : ''" 
      @after-leave="onAfterLeave"
      @after-enter="onAfterEnter"
    >
      <div v-show="show" class="slide">
        <component 
          v-bind:is="comp.name" 
          :param="comp.param" 
          :container="self"  
          v-on:event="onChildEvent" 
        />
      </div>
    </transition>
  </div>
</template>

<script>
import About from './About.vue'
import MainView from './MainView.vue'
import HomeView from './HomeView.vue'
import BoxView from './BoxView.vue'
import SavedView from './SavedView.vue'
import MyPostsView from './MyPostsView.vue'
import ChargeListView from './ChargeListView.vue'
import AdminChargeListView from './Admin/AdminChargeListView.vue'
import PaymentListView from './Admin/PaymentListView.vue'
import SettingView from './SettingView.vue'
import PostView from './PostView.vue'
import MemberView from './MemberView.vue'
import NoticeView from './NoticeView.vue'
import SearchMain from './SearchMain.vue'
import SearchPage from './SearchPage.vue'
import LocalView from './LocalView.vue'
import MonitorView from './MonitorView.vue'
import MyChannelView from './MyChannelView.vue'
import ExchangeListView from './ExchangeListView.vue'
import PointLogListView from './PointLogListView.vue'
import ChildListView from './ChildListView.vue'
import ReplyView from './ReplyView.vue'

console.log('MonitorView', MonitorView);

import MyView from './MyView.vue'
import MyTest from './MyTest.vue'
import common from '@/common'

export default {
  name: 'MyTransition',
  components: {    
    HomeView
    ,About
    ,MainView
    ,BoxView
    ,SavedView
    ,MyPostsView
    ,ExchangeListView
    ,PointLogListView
    ,SettingView
    ,PostView
    ,MemberView
    ,NoticeView
    ,MyView
    ,MyTest
    ,SearchMain
    ,SearchPage
    ,LocalView
    ,MonitorView
    ,MyChannelView
    ,ChargeListView
    ,AdminChargeListView
    ,ChildListView,
    PaymentListView,
    ReplyView,
  },
  props: {
    comp: Object,
    controller: Object,
    index: Number,
  },
  data() {
    return {
      show: false,
      goHome: false,
      self: null,
      touchStart: 0,
      touchStartY: 0,
    }
  },
  computed: {
  },
  created(){
    // console.log('SlideContainer.created')
    this.self = this;
  },
  mounted() {
    console.log('mounted', this.comp)
    this.controller.pushSlideContainer(this);

    setTimeout(() => { 
      this.show = true;
    }, 1);

    console.log('this.$refs.topContainer', this.$refs.topContainer.offsetWidth)
    this.ui.width = this.$refs.topContainer.offsetWidth;
  },
  methods: {
    onTouchMove(event){
      event;

      // console.log('onTouchMove', event)
      if(this.index == 0) return; // 첫 페이지는 움직이지 않도록 

      if(this.touchStart > 10) return;

      // todo : 위로 스크롤 안되게 하는게 좋지.. 우왕 좌왕
      event.preventDefault();

      this.$refs.topContainer.style.left = `${(event.changedTouches[0].pageX - this.touchStart)}px`
    },
    onTouchEnd(event){
      // console.log('(event.changedTouches[0].pageY - this.touchStartY)', Math.abs(event.changedTouches[0].pageY - this.touchStartY))

      // 유효시작점은 10 미만으로 한다.
      if(this.touchStart > 10) return;

      // 새로 스크롤이 더 많은 변화를 일으키면 세로 스크롤로 이해한다.
      if
      (
        Math.abs(event.changedTouches[0].pageY - this.touchStartY) > 
        (event.changedTouches[0].pageX - this.touchStart)
      ) return;

      if((event.changedTouches[0].pageX - this.touchStart) > 50){
        if(false == this.controller.back()){
          this.$refs.topContainer.style.left =0;          
        }
      }
      else{
        this.$refs.topContainer.style.left =0;
      }
    },
    onTouchStart(event){
      // console.log('onTouchStart', event);
      // console.log('onTouchStart', event.touches[0].pageX);
      this.touchStart = event.touches[0].pageX;
      this.touchStartY = event.touches[0].pageY;
    },

    // onAfterEnter(el){
    onAfterEnter(){
      // console.log('onAfterEnter', el);

      const pre = this.comp.pre;
      if(pre){
        pre.show = false;
      } 
    },
    // onAfterLeave(el){
    onAfterLeave(){
      // console.log('onAfterLeave');
      // this.$emit('event', 'pop');
      if(this.goHome)
      {
        common.goHome();
        this.goHome = false;
      }
      else
        common.removeLastComp();
    },
    back(){
      if(this.comp.pre){
        // console.log('this.pre', this.comp.pre);
      } 

      this.show = false;
    },
    onChildEvent(tag, param){
      console.log('onChildEvent', tag, param);

      switch (tag) {
        case 'back':
          if(this.comp.pre){
            // console.log('this.pre', this.comp.pre);
          } 

          this.show = false;
          break;      
        case 'home':
          this.home();
          break;
        default:
          break;
      }
    },
    home(){
      console.log('home')
      common.home().show = true;
      this.show = false;
      this.goHome = true;
    },
    action(){
      this.show = true;
    },
    hide(){
      this.show = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.top-container {
  position: relative;
  overflow: hidden;
}

/* trnasition */
/* 진입/진출 애니메이션은 다른 durantion 및 */
/* 타이밍 기능을 사용 할 수 있습니다.   */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  /* transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); */
  transition:  0.3s 
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(300px);
  opacity: 0;
}

</style>
