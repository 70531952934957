<template>
  <textarea 
    v-model="text"
    id="textarea"
    :placeholder="placeholder"
    name="textarea" 
    @input="input($event.target.value)"/>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    autoresize: {
      type: [Boolean, String],
      default: false
    },
  },
  data: function() {
    return {
      text: ''
    }
  },
  computed: {
    $textarea() {
      return document.getElementById('textarea')
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.resize()
    },
    input(value) {
      this.$emit('input', value)
      this.resize()
    },
    // resize($event) {
    resize() {
      if (!this.autoresize) return
      this.$textarea.style.height = '1px'
      this.$textarea.style.height = `${this.$textarea.scrollHeight + 12}px`
    },
    setValue(str){
      this.text = str;
      setTimeout( () => { this.resize() }, 1); // 수정모드일때 스크롤바 안나오도록
      
    }
  }
}
</script>

<style scoped>
#textarea {
  min-height: 100px;
}
</style>