<template>
    <div style="height: 100px;"></div>
</template>

<script>
// import common from '@/common'
// import constVal from '@/const'

export default {  
    props:{
      data:Object,
    },
    data() {
      return {
      };
    },
    mounted() {
    },
    methods: {
        click(param){
            param;
            this.$emit('clicked', this.data)
        },
    },      
}
</script>

<style scoped>
@import '@/assets/common';


</style>



