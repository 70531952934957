<template>
    <div class="center">
        <div class="top">
            Hello
        </div>
    </div>
</template>

<style scoped>
@import '@/assets/common';

.top {
    /* width: 100%; */
    height: 100vh;
    background: yellow;
}
</style>

<script>
export default {  
    props:{
      data:Array,
      id: String,
    },
    mounted() {
        console.log('MyView.mounted')
        console.log('data', this.data)
    },
    data: function () {
      return {  
        //   name: value
        selected: 0,
      };
    },
    methods: {
      click:function(data, n){
          this.selected = n;
          this.$emit('clicked', data, this.id)
      },
    },      
}
</script>

