<template>
  <div style="background: white; ">
    <div class="between margin-top10 padding10">
      <div />
      <button type="button" class="btn color-gray" @click="close()">닫기</button>
    </div>

    <!-- <div class="center-text" style="margin-top: 30px;margin-bottom: 30px" v-html="Abouting" />     -->

    <form class="login-container" style="margin-top: 10%;">
      <div class="margin-bottom10" v-show="step == 1">
        <div class="container">
          <div class=" font-size14">1. 사용할 이메일 주소를 입력하세요.</div>
          <input v-model="uid" type="email" placeholder="이메일" name="email" @keypress.enter="onEmail" required>    
          <div class="warning-color font-size12">{{warning}}</div>
        </div>

        <button type="button" class="btn text-button-color font-size12 " @click="onEmail">인증번호 받기</button>
      </div>

      <div v-show="step == 2">
        <div class="container">
          <div class="font-size14">2. 이메일로 전달된 인증번호를 입력하세요.</div>
          <input v-model="num6" type="number" placeholder="인증번호 6자리" name="email" @keypress.enter="onConfirm" required>    
          <div class="warning-color font-size12">{{warning}}</div>
        </div>

        <button type="button" class="btn text-button-color font-size12 " @click="onConfirm">확인</button>
      </div>

      <div v-show="step == 3">
        <div class="container">
          <div class=" font-size14">3. 비밀번호를 등록하세요</div>
          <input v-model="pw" type="text" placeholder="비밀번호" @keypress.enter="onPassword" required>    
          <div class="warning-color font-size12">{{warning}}</div>
        </div>

        <button type="button" class="btn text-button-color font-size12 " @click="onPassword">확인</button>

      </div>

      <!-- 또는 -->
      <!-- <div class="center-text font-size12 color-darkgray " style="margin-top: 20%;">다음 방식으로 가입하기</div>
      <div class="buttons margin-bottom10" id="sns">
        <img @click="onGoogle" src="@/assets/img/google.png"/>
        <img @click="onNaver" src="@/assets/img/naver.png"/>
        <img @click="onKakao" src="@/assets/img/kakao.png"/>
      </div> -->

    </form>

    <div class="between padding10">
      <div />
      <div class="font-size14 text-button-color">
        <div style="text-align:right" @click="onUser">사용자약관</div>
        <div @click="onPrivacy">개인정보 처리방침</div>
      </div>
    </div>

  </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'
// import JoinForm from './JoinForm.vue'

export default { 
  components: {
    // JoinForm,
  }, 
    props:{
      param:Object,
    },
    mounted() {
      // console.log("LoginForm.mounted", this.param)
    },
    data: function () {
      return {  
        //   name: value
        step: 1,
        uid: '',
        pw: '',
        num6: '',
        message:'',
        comment: '',
        warning: '',
        is_join: false,
        Abouting: `<div class="" style="font-size:24px; font-weight: 900;">${Const.about}</div>`
      };
    },
    methods: {
      onPassword(){
        const url= Const._api + "member/reg_password";

        const pw = this.pw.trim();
        if(false == common.checkPw(pw)){
          this.warning = '비밀번호 자리 8~20자, 영문, 숫자, 특수문자 포함'
          return;
        }

        const params = {
          pw: this.pw,
        };

        common.api_post(url , params, 
          (data) => {            
            console.log('login.data', data);
            const param = {
                header: '안내',
                body : '회원가입을 완료했습니다.\n로그인해주세요.',
                callback: () => { window.location = '/'}
            }

            const obj = {
                name: 'ModalDialog',
                param
            }
            common.popUp(obj);                
          },
          (msg, error) => {
            if(error == 10){
              this.warning = '비밀번호 자리 8~20자, 영문, 숫자, 특수문자 포함'
              this.num6 = '';
            }
            else{
              console.log(msg);
            }
          }
        );      
      },
      onConfirm(){ // 인증번호 입력
        this.warning = '';

        const url= Const._api + "member/email_auth";

        const params = {
          num6: this.num6,
        };

        common.api_post(url , params, 
          (data) => {            
            console.log('login.data', data);
            var {error, msg} = data;
            if(error > 0){
              this.warning = msg;
              // this.$emit('clicked', 'failed', msg);

              return;
            }

            // 토스트
            // 비번 등록
            this.step = 3;
            console.log('비번등록')
          },
          (msg) => {
            // common.toast(msg);
            this.warning = msg;
            this.num6 = '';
          }
        );          
      },
      onEmail(){
        const url= Const._api + "member/email_check";

        const params = {
          email: this.uid,
        };

        common.api_post(url , params, 
          (data) => {            
            console.log('login.data', data);
            var {error, msg} = data;
            if(error > 0){              
              this.message = msg;
              this.$emit('clicked', 'failed', msg);
              return;
            }

            this.step = 2;
          }
          ,(msg, error) => {
            console.log(msg, error)
            if(error == 1){
              const param = {
                header: '안내',
                body : msg, 
                callback: () => { this.close();}
              }

              const obj = {
                name: 'ModalDialog',
                param
              }
              common.popUp(obj);                              
            }
          }
        );
      },
      onPrivacy(){
        window.open('http://naver.com')
      },
      onUser(){
        window.open('http://naver.com')
      },
      onEnter(id){
        switch (id) {
          case 'id':
            this.$refs.pw.focus();
            break;
          case 'pw':
            this.login();
            break;        
          default:
            break;
        }
      },
      show: function(){
        this.message = '';
        this.email = '';
        this.pw = '';

        // todo : 로컬스토리지에 있다면 바로 시도한다.            
      },
      close(){
        this.param.container.close();
      },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sns > img {
  width: 166px;
  /* height: 40px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}
.container {
  display: flex; 
  flex-direction: column; 
  margin-left: auto; 
  margin-right: auto; 
  width: fit-content;
}

.join {
  display:flex; 
  margin-right: 0; 
  width: fit-content;
}

.open-button {
  /* background-color: #555; */
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  /* position: fixed; */
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.login-container {
    top:0;
    bottom: 0;
    /* right: 15px; */
    z-index: 9;
    max-width: 100%;
    padding: 10px 30px;
    /* height: 100vh; */
    background-color: white;
}

.login-container .btn {
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}
  
  /* Full-width input fields */
.login-container input[type=text], .login-container input[type=email], .login-container input[type=number] {
  width: 270px;
  height: 38px;

  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
    /* padding: 15px; */
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
  /* margin: 5px 0 22px 0; */
  /* border: none; */
}
  
/* When the inputs get focus, do something */
.login-container input[type=text]:focus, .login-container input[type=password]:focus {
  /* background-color: #ddd; */
  outline: none;
}

input::placeholder {
    /* opacity: .5; */
    color: rgb(128, 128, 128);
    /* padding-left: 10px; */
}

.warning {

}

</style>

