<template>
    <div class="scroll-container" v-on:scroll.passive="handleScroll">
        <title-bar-ex ref="titlebar" :title="'충전기록'" :container="container" :right="right"/>
        <div class="margin-side">
            <cash-info style="margin-top: 10px;" />
            <div ref="scroll_container" class=" background-color" id="scroll-container" v-on:scroll.passive="handleScroll"  >
                <div class="middle" id="contents" >
                    <charge-cell v-for="(item, index) in rows" v-bind:key="index" :data="item"></charge-cell>
                </div>    
            </div>
            <!-- <slidemenu ref="slidemenu" v-on:move="onMove"></slidemenu>      -->
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import common from '@/common'
import Const from '@/const'
// import Navbar from '@/components/common/NavBar'

import TitleBarEx from './common/TitleBarEx.vue'
// import SlideCell from './common/SlideCell.vue';
import ChargeCell from './common/ChargeCell.vue';
import CashInfo from './common/CashInfo.vue'

export default {
    components: {
        TitleBarEx,
        // SlideCell,
        ChargeCell,
        CashInfo,
    },
    props: {
      param: Object,
      container: Object,
    },
    setup() {
      // console.log('board setup');
      return {
      }
    },
    data(){ 
      return {
        show_manual: false,
        manual: null,
        title_left : [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
        ],
        show_intro: false,
        user: null, // 사용자   
        rows: [],
        last: 0,
        total: 0,
        left:[
          {
            html: '<i class="fa fa-chevron-left"></i>',
            onClick: this.onLeft,
          },
          {
            html: '<span class="material-symbols-outlined ">home</span>',
            onClick: this.onHome,
          },
        ],
        right:{
          html: '신청',
          onClick: () => {
            const obj = {
                name: Const.component.ChargeForm,
                param: {}
            }
            common.popUp(obj);
          }
        },
        titleBarConfig:{
            is_write: false, 
            is_menu: true,
            left: [
                {
                    html: `<i class="button-color fa fa-bars navi-button"></i>`,
                    onClick: () => {this.$refs.slidemenu.is_show = true;}
                },
                {
                    html: `<div class="navi-button" style="font-weight: 900;">${Const.about}</div>`
                }
            ]
        },        
        writable: false, // 이 게시판에 글을 작성 할 수 있는가?
        board_name: '',

        me: null,
        idx: 0,
        board: {},
        persona: {},
        needs_more: true,
        hashtag:['전체'],
        tag: '*', // 선택된 태그
        tags:[],
        all: { // 모든 멤버
          rows:[],
          isNew: false, // 새글이 있나?
          end: false, // 더 이상 없나?
          hasScroll: false, // 스크롤 유무
        },

        reported:{}, // 신고한 사람
        top_post: null,
      }
    },    
    created() {
        this.idx = common.param("idx");
    },
    mounted() {
        this.$refs.titlebar.didLogin();

        console.log('mounted')
        {
            this.$refs.titlebar.left = this.title_left;
        }
        console.log('this.param', this.param)

        {
            const comp = {
                onEvent: this.onEvent,
            }
            common.registEventHandler(comp);
        }

        // console.log('this.idx', this.idx)

        {
            const comp = {
                onEvent: this.onEvent,
                onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        this.reload();
    },
    methods:{
        reload(){
            console.log('reload')
            const url = `${Const._api}my/charge/list/0`
            // const url = `${host}/api/my/ad/${idx}`
            console.log(url)

            let total = 0;

            axios.defaults.withCredentials = true;        
            const proc = (data) => {
                console.log(data)
                const {rows, row} = data; // 신규, 본것
                console.log(rows)

                this.rows = rows;

                if(total == 0){
                    this.show_manual = true;
                    this.manual = row;
                }
            }
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });    
        },
        checkMe(){ // 새로운 메시지, 알람 체크
            common.checkMe( (res) => {
                console.log('checkMe', res.data);
                // const {data} = res;
            })
        },  
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {
            case Const.notify.charge:
                this.reload()
                break;
            default:
                break;
            }
        },
        didLogin(user){
            console.log('didLogin', user);
            // // console.log(persona.name + '님')

            this.$refs.titlebar.didLogin();
            this.$refs.titlebar.updateButtonState()

            this.show_intro = false;

            // titlebar 수정
            this.me = user;
            this.session.user = user;

            // this.$refs.navbarBottom.is_write = true;
            // this.$refs.navbarBottom.is_menu = false;
            // this.$refs.navbarBottom.is_bookmark = true;

            
            // if(this.param.write) 
            //   this.onWrite();
        },      
        notLogin: function(){    
            console.log('notLogin')
        },
        fitToBubble(rows){
            rows.forEach(element => {
            element.my_persona_idx = this.persona.idx;
            if(element.persona_idx == this.persona.idx) element.myname = element.persona_idx;
            if(this.reported[`${element.persona_idx}`]) element.hidden = true;
            if(element.nickname) element.name = `${element.persona_idx}(${element.nickname})`
            });
        },
        requestAll(start=0){
            console.log('requestAll',start)
        },
        onTitle(){

        },
        onLeft(){
            common.back();
        },
        pullNew(target, isMerge = false, rate = 0){             
            if(isMerge == false && target.isNew) return; // 이미 새글이 있다.
            
            const after = target.rows.length > 0 ? target.rows[0].idx : 0
            const url = Const._api + `board/new/${this.idx}/${after}/${rate}`;

            common.api_get(
            url, 
            res => {
                const {error, msg} = res.data;
                if(error > 0){
                const param = {
                    header: '잘못된 접근',
                    body: msg,
                }
                common.popupModal(param);
                
                return;
                }
                
                // this.snackbar.hide();

                const {rows} = res.data;

                if(rows.length == 0){
                common.toast('새로운 글이 없습니다.')
                return;
                }

                if(isMerge){
                target.isNew = false;
                rows.forEach(element => {
                    element.url = Const._base + element.url;
                });
                target.rows = rows.concat(this.rating.rows);  
                }
                else{
                target.isNew = true; // 표시만 한다.
                }
            }
            );
        },
        checkNewAll(){
            const last = this.all.rows.length > 0 ? this.all.rows[0].idx : 0;
            const url = Const._api + `post/board/new/all/${this.idx}/${last}/${this.tag}`;

            common.api_get(
            url, 
            res => {
                const {error, msg} = res.data;
                if(error > 0){
                const param = {
                    header: '잘못된 접근',
                    body: msg,
                }
                common.popupModal(param);
                
                return;
                }

                const {isNew} = res.data
                this.all.isNew = isNew;

                this.$refs.alert_all.alert(isNew);
                // if(!isNew) common.toast(Const.message.no_new)
            }
            );        
        },

        onWrite(){
            // console.log('this.me', this.me);
            if(this.me)
            {
            const param = {
                data: {
                board_idx: this.idx,
                idx: 0,
                }
            } 

            const obj = {
                name: Const.component.PostForm,
                param
            }
            common.popUp(obj);
            }
            else // 로그인을 한번도 한적이 없는 경우 
            {
            common.popupLogin();
            }         
        },
        onHome(){
            this.$emit('event', 'home');
        },
        onLike(){
            const url = Const._api + "board/like";
            const param = {
            board_idx: this.idx,
            like: !(this.board.isLike),
            }
            common.api_post(url, param, data => {
                // console.log('data', data)

                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    // console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }
                // this.$emit('done');
                this.board.isLike = !(this.board.isLike) ? 1 : 0;
                // this.$refs.navbarBottom.bookmark = this.board.isLike ? "bookmark-on" : "bookmark-off" ;

                common.notifyEvent(Const.notify.bookmark)
            })
        },
        onError: function(error, param){
            // console.log('onError', error, param);

            // todo : notlogin 처리 / 리책토링

            common.toast(param)
        },
        resetRows(obj){
            obj.rows = [];
            obj.isNew = false; // 새글이 있나?
            obj.end = false; // 더 이상 없나?
        }
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.empty {
  height: 200px;
}

.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

</style>

