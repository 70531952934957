<template>
    <div id="main-container">
      <TitleBar ref="titlebar" :data="titleBar" v-on:title="onTitle"  v-on:left="close" v-on:right="onDone"></TitleBar>

      <div class="margin-side">

        <div class="margin10">
          <!-- <textarea style="background:yellow; width: 100%; height: 50%; " placeholder="예의를 갖추지 않은 글은 삭제되며 활동을 정지 당할 수 있습니다." v-model="form.content" ></textarea> -->
          <!-- <auto-resize class="post-font-family " ref="textarea" style="width: 100%; " :placeholder="'예의를 갖추지 않은 글은 삭제되며 활동을 정지 당할 수 있습니다.'" :autoresize="true" /> -->

          <div>
            <input-box ref="input_title" :data="input_title" />
            <input-box v-show="instance" :type="'number'" :width="'100px'" style="margin-top: 20px;" ref="input_amount" :data="input_amount" />
            <input-box style="margin-top: 20px;" ref="input_url" :data="input_url" />
            <input-box :type="'number'" :width="'100px'" style="margin-top: 20px;" ref="input_amount" :data="input_amount" v-on:changed="onChangeAmount" />
          </div>

          <div v-show="sel_img_files.length > 0" class="addfile">  
              <a v-for="(item, index) in sel_img_files" v-bind:key="index" class="image">      
                <!-- <img :src="item.src" :data-file="data" alt=""><span v-on:click="delImage(index)"> X</span> -->
                <img :src="item.src" alt=""><span v-on:click="onDelImage(index)"> X</span>
              </a>
          </div>

          <!-- 이미지 / 태그 버튼 -->
          <div v-show="!show_input" class="">
            <div class="center-h margin-top20">
              <i class="fa fa-image image-button" @click="onImage"></i>
            </div>
            <input type="file" ref="input_imgs" @change="change($event, '파일')" :multiple="!instance" style="visibility: hidden; height:0px;float:left;"/>
          </div>

          <div class="comment">
            <div v-if="instance">이미지는 한장만 첨부가능합니다.</div>
            <div v-else>최대 {{image_amount}}개의 이미지를 첨부 할 수 있습니다.</div>
            <pre>각 이미지는 정사각형으로 최대 사이즈는 10M입니다.</pre>
          </div>

          <div>
            <div>차감금액 ; {{ price }}</div>
          </div>
        </div>

        <div v-show="show_submit" @click="onDone" style="text-align: center;" class="button-text-big">
          완료
        </div>

        <!-- 바틈메뉴 -->

        <div style="height:100px;"></div>        


      </div>
    </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'
import TitleBar from '@/components/common/TitleBar.vue'
import axios from 'axios'
import InputBox from './common/InputBox.vue'
// import AutoResize from './common/AutoResize.vue'

export default {  
  components: {
    TitleBar,
    InputBox,
  },
  props: {
      param: Object,
      container: Object,
      close: Function,
  },
  computed: {
  },
  data: function () {    
    return {  
      prices: {}, // 가격 테이블
      price: '', // 충전 금액
      instance: false,
      remain_point: 0,
      image_amount: 0, // 이미지 첨부 갯수
      input_title:{
        title: '제목',
        comment: '제목을 입력하세요. 최대 20자',
      },
      input_amount:{
        title: '발행량',
        comment: '전달되는 최대 분량을 입력하세요.',
        value: 0,
      },
      input_url:{
        title: 'URL',
        comment: '외부연결 주소를 입력하세요. 최대 100자',
      },
      show_submit: true,
      warning_needs: '',
      needs: '0',
      img_count: 0,
      form:
      {
        idx:0,
        title:'',
        url:'',
        reward:'',
        amount:0,

        deleted:[],  // 삭제할 이미지 idxs
      },
      sel_img_files:[],
      titleBar:{
        title: "글쓰기",
        // right: '<div class="text-button-color">발행</div>',
        right: '<div></div>',
        left: '닫기'
      },
      placeholder: '각 태그는 띄어쓰기로 구분해서 10글자이하로 입력하세요.',
      show_input: false,
      tags_input: '',
      hash_tags: '',
    };
  },
  mounted() {
      console.log('user', this.user)

      const {data} = this.param;

      this.instance = this.param.instance ? this.param.instance : false;
      console.log('instance', this.instance)
      console.log('isDev', common.isDev())

      if(this.instance){
        const {point, take_point} = this.user;
        this.remain_point = point - take_point;

        if(this.remain_point == 0){
          // 설명.. 그리고 닫기
          const param = {
              header: '체리가 부족합니다.',
              body: `메시지를 사용하기위해서는 
체리가 필요합니다.

다른 사람의 메시지를 확인하면 
체리를 받을 수 있습니다.`,
              callback: () => {
                this.close();
              }
          }

          common.popupModal(param)
          return;
        }

        this.input_amount.comment = `발행수를 입력하세요. 현재 ${this.remain_point}까지 가능합니다.`
      }

      console.log('data', data) 
      
      if(data.idx > 0) // 수정모드
      {
        this.form = common.clone(data);

        data.images.forEach(item => {
            var f = {};
            f.idx = item.idx;
            f.src = `${item.url}/${item.filename}` ;      
            this.sel_img_files.push(f);                  
        });

        this.links = data.links;
        this.form.deleted = [];
      }
      else{          
        this.form.board_idx = data.board_idx;
      }

      const cb = (data) => {
        console.log('checkMe', data)

        const {error, user, msg, image_max, prices}  = data;
        if(error > 0){
          const param = {
              header: '오류',
              body: msg,
          }
          common.popupModal(param);
          return;
        }

        this.image_amount = image_max;
        this.prices = prices;
        Object.assign( this.user, user);
      }
      common.checkMe(common.checkResponse(cb))

      console.log('this.form.tags', this.form.tags);

      // this.input_amount = Object.assign(this.input_amount, this.input_amount);
  },
  methods: {
    onChangeAmount(value){
      console.log('onChangeAmount', value)
      this.form.amount = value;

      const {price} = this.checkPrice();
      this.price = `${price}원`;
    },
    insertComma(val){
      const str = `${val}`
      var value = str.replace(/,/g, '');
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    reset: function(){          
      this.img_count = 0;
      this.form =       
      {
        idx:0,
        board_idx:0,
        header:'',
        title:'',
        content:'',
        deleted:[],  // 삭제할 이미지 idxs
      };
      this.sel_img_files = [];
    },
    onDelImage: function(index){
        console.log('onDelImage', index);
        var image = this.sel_img_files[index];

        console.log('image', image);
        this.sel_img_files.splice(index, 1);    // slice가 아니다

        this.img_count = this.sel_img_files.length;
        this.onImageCount()

        if(image.idx > 0){
          this.form.deleted.push(image.idx);
        }        
    },
    onDone(){
      let formData = new FormData();

      const {result} = this.checkPrice()
      if(false == result){
        return;
      }

      this.form.title = this.$refs.input_title.value;
      this.form.url = this.$refs.input_url.value;
      this.form.amount = this.$refs.input_amount.value;
      this.form.instance = this.instance;

      var json = JSON.stringify(this.form);
      formData.append('json', json);
      formData.append('instance', this.instance);

      // var files = [];
      for (let index = 0; index < this.sel_img_files.length; index++) {
        const element = this.sel_img_files[index];
        //files.push(element.file);              
        if(element.idx == 0){
          formData.append('images',element.file);  // 'images' 파일변수 이름
        }
      }

      const proc = (data) => {
        console.log('onSuccess', data)              

        const {error, msg} = data;

        if(error > 0){
          common.popupModal({header: '', body: msg})
          return;
        }

        this.close();

        if(this.form.idx == 0){
          // 자신의 발행목록 업데이트
          const {idx} = data; 
          common.notifyEvent(Const.notify.post, {idx, title:this.form.title});
        }
        else{
          common.notifyEvent(
            Const.notify.post_update, 
            {
              post_idx: this.form.idx,
            }
          );
        }
      }

      const url = Const._api + "post/reg";
      axios.post(url, formData).then(common.checkResponse(proc))
      .catch(error => {
          console.log(error);
      });                    
    },
    onTitle(){

    },
    onTag(){
      this.show_input = true;
    },
    onImage: function(){
        console.log('onImage')
        // this.show_image = true;
        const elem = this.$refs.input_imgs
        elem.click();
    },
    checkPrice(){
      const count = this.img_count;
      let p = this.prices[`p${count}`];
      p = p ? p : this.prices.default

      console.log('p>', p)

      const price = p * this.form.amount;

      if(this.user.chips < price){
        console.log('충전금액이 부족해요.')
        common.toast('충전금액이 부족합니다.')

        return {result:false, price};
      }

      return {result:true, price} ;
    },
    onImageCount(){
      // 이미지 갯수가 바뀌면 호출
      console.log('onImageCount', this.img_count);

      const {price} = this.checkPrice(); 

      this.price = `${price}원`;
    },
    change(e, id){
      switch (id) {
        case '파일':
          {
              var files = e.target.files;
              console.log('files.length', files.length)

              const maxCount = this.instance ? 1 : this.image_amount;
              if((this.img_count + files.length) > maxCount)
              {
                console.log('this.img_count', this.img_count)
                common.toast(`이미지는 최대 ${maxCount}장까지 가능합니다.`)
                return;
              }
              var filesArr = Array.prototype.slice.call(files);
          
              const _this = this;
              filesArr.forEach((f) => {
                  if (!f.type.match("image.*")) {
                      // _alert("확장자는 이미지 확장자만 가능합니다.");
                      return;
                  }
                  if(_this.img_count == _this.image_amount){
                      // _alert("이미지는 10장만 올리실 수 있습니다.");
                      return;
                  }
      
                  var img = {idx:0};
                  img.file = f;

                  if(this.instance)
                  {
                    _this.img_count = 0;
                    _this.sel_img_files.length = 0;
                  }
      
                  _this.img_count++;
      
                  var reader = new FileReader();
                  reader.onload = function(t) {
                      _this.image = t.target.result;
                      // _this.data = f.name;
                      
                      img.src = t.target.result;
                      _this.sel_img_files.push(img);  
                  }
                  reader.readAsDataURL(f);
              });

              this.onImageCount()
          }

        break;
      }
    
    },    
  },      
}

</script>

<style scoped>
@import '@/assets/common';

.tb-btn {
  margin: 0 10px;
}

.hash-input {
  width: 100%;
  /* position: fixed; 
  bottom: 10px; */
}

#main-container {
  height: 100%;
    /* background: yellow; */
  background-color: white;
  /* display: flex;
  flex-direction: column; */
  /* height: 100%; */
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  overflow: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#main-container::-webkit-scrollbar {
    display: none;
}

.text-button > div {
  /* padding: 10px; */
  margin: 0;
}

.text-button > i {
  padding: 10px;
  /* padding-left: 15px; */
}

.flex {
  display: flex;
}

.bottom {
  overflow: hidden;
  /* background-color: #333; */
  position: fixed;
  bottom: 0;
  width: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* align-items: stretch; */
  background-color: #f1f1f1;
}

.image {
    height: 80px;
    background-color: pink;
}

.thumbnail {
    width: 90px; height: 90px;
}

.addfile{ 
  font-size: 13px;  
  padding:10px; 
  border-bottom: 1px solid #c0c0c0; 
}

.addfile > div{ 
  font-size: 12px; 
  background-color: #f8f8f8;  
  width: 100%; 
  height: 30px;  
  line-height: 30px; 
  text-align: center; 
  box-sizing: border-box; 
  margin-bottom: 10px;
}

.addfile > ul:after{content:""; display:block; clear:both; }
.addfile > ul > li {float: left; margin-right: 10px; position: relative; margin-bottom: 10px; }
.addfile > ul > li  > a{color: #eb5c53; line-height: 10px; padding: 8px; background-color: #fff; right: 0; position: absolute; }

.addfile .image > img {
    object-fit: cover;
    width:80px;
    height:80px;
}

.addfile .image {
    color: white;
    text-decoration: none;
    /* padding: 15px 26px; */
    position: relative;
    display: inline-block;
    border-radius: 2px;
    margin: 3px;
}
.image > span{
    position: absolute; 
    top:-4px;  
    right: -4px;
    position: absolute;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: black;
    color: white;
}

.post-container {
    /* border: 2px solid #ccc; */
    /* background-color: #eee; */
    border-radius: 5px;
    /* padding: 16px; */
    margin: 14px;
  }
  
.post-container::after {
  content: "";
  clear: both;
  display: table;
}

  
  /* .post-container img {
    float: left;
    margin-right: 20px;
    border-radius: 5%;
  } */
  
.post-container .info {
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.post-container span {
  font-size: 20px;
  /* margin-right: 15px; */
}


/* .post-container .hash {
  background: yellow;
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
} */

.post-container .like {
  /* background: yellow; */
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}

.txt_post { 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: box; 
  margin-top:1px; 
  max-height: 180px; 
  overflow:hidden; 
  vertical-align:top; 
  text-overflow: ellipsis; 
  word-break:break-all; 
  -webkit-box-orient:vertical; 
  white-space: pre-wrap;   
  /* 3줄 */
  -webkit-line-clamp:3;   
}

.image-button{
  margin: 0 auto; 
  font-size: 50px;
  color: dodgerblue;
}

</style>

