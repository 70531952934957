<template>
    <div id="main-container">
      <TitleBar ref="titlebar" :data="titleBar" v-on:left="close" ></TitleBar>

      <div class="margin-side">
        <div class="margin10">
          <!-- <textarea style="background:yellow; width: 100%; height: 50%; " placeholder="예의를 갖추지 않은 글은 삭제되며 활동을 정지 당할 수 있습니다." v-model="form.content" ></textarea> -->
          <!-- <auto-resize class="post-font-family " ref="textarea" style="width: 100%; " :placeholder="'예의를 갖추지 않은 글은 삭제되며 활동을 정지 당할 수 있습니다.'" :autoresize="true" /> -->

          <div style="margin: 20px; font-size: 14px;">
            <div>사용자 리워드 : {{row.reward}} </div>
            <div>배포량 : {{ amount }}</div>
            <div>입금액(부가세 포함) : {{ total }}원</div>
            
            <div style="margin-top: 10px;">입금은행 : {{ row.account.bank }}</div>            
            <div style="display: flex;">
                <div >계좌번호 : {{ row.account.num }}</div>
                <div @click="onCopy" style="color: dodgerblue; font-weight: bold; margin-left: 10px;">[복사]</div>
            </div>
            <div >예금주명 : {{ row.account.name }}</div>
          </div>

          <div style="width: 80%; margin: 0 auto;">

            <input-box ref="input_name" :data="{title: '입금자명'}" v-on:changed="(param)=>{this.form.name = param;}" />

            <input-box ref="input_time" :data="{title: '입금일시'}" v-on:changed="(param)=>{this.form.time = param;}" style="margin-top: 20px;"/>

            <input-box ref="input_email" :data="{title: '이메일주소', comment: '진행결과를 전달해드립니다.'}" v-on:changed="(param)=>{this.form.email = param;}" style="margin-top: 20px;"/>

            <input-box ref="input_phone" :data="{title: '전화번호', comment: '비상연락처'}" v-on:changed="(param)=>{this.form.phone = param;}" style="margin-top: 20px;" />

          </div>

          <div class="comment" style="margin-top: 20px;">
            <div>* 위 양식을 입금 후 제출해주시면 확인이 가능합니다.</div>
            <div>* 입금전에 제출하시면 확인불가</div>
            <div>* 확인 후 최대 24시간안에 반영해드리고 메일을 보내드립니다.</div>
          </div>
          
        </div>

        <div style="margin-top: 20px;">
          <div v-if="row.json_submit">        
              <div v-show="show_submit" @click="onDone" style="text-align: center;" class="button-text-big">수정하기</div>
          </div>
          <div v-else>
              <div v-show="show_submit" @click="onDone" style="text-align: center;" class="button-text-big">입금확인 및 배포요청</div>
              <div v-show="show_submit" @click="onDel" style="color: red; margin-top: 20px; text-align: center;" class="button-text-big">배포신청 삭제</div>
          </div>
        </div>

        <!-- 바틈메뉴 -->

        <div style="height:100px;"></div>        
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TitleBar from '@/components/common/TitleBar.vue'
import InputBox from './common/InputBox.vue'
// import AutoResize from './common/AutoResize.vue'

export default {  
    components: {
        TitleBar,
        InputBox,
        // AutoResize,
    },
    props: {
        param: Object,
        controller: Object,
        close: Function,
    },
    data: function () {    
        return {  
            row: {
                account:{},
            },
            show_submit: true,
            warning_needs: '',
            needs: '0',
            user: {
                chips: 0
            }, 
            form:
            {
                name:'',
                email: '',
                time: '',
                phone: '',
            },
            titleBar:{
                title: "입금확인요청",
                // right: '<div class="text-button-color">발행</div>',
                right: '<div></div>',
                left: '닫기'
            },
            show_input: false,
        };
    },
    computed: {
        amount(){            
            return this.insertComma(this.row.amount)
        },
        sum(){ 
            return this.insertComma(this.row.sum)
        },
        total(){
            return this.insertComma(this.row.cost)
        },
        cost(){
          return this.insertComma(this.row.cost)
        },
    },
    mounted() {
        console.log('this.param.ad_idx', this.param)

        const {idx} = this.param;
        this.form.ad_idx = idx;
        {
            const proc = (data) => {
                console.log(data)
                const {row} = data; // 신규, 본것
                console.log(row)

                const param = JSON.parse(row.json)
                console.log('param>', param)

                if(row.json_submit) // 이미 제출한 경우
                {
                    console.log('row.json_submit>', row.json_submit)
                    const submit = JSON.parse(row.json_submit);
                    // Object.assign(row, submit)
                    console.log('submit', submit)

                    Object.assign(this.form, submit)
                }

                this.row = {...row, ...param};

                this.$refs.input_email.value = this.form.email;
                this.$refs.input_name.value = this.form.name;
                this.$refs.input_time.value = this.form.time;
                this.$refs.input_phone.value = this.form.phone;
            }
            
            const url = `${Const._api}my/pay/get/${idx}`
            console.log(url);
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });            
        }
    },
    methods: {
        onCopy(){
            common.copyToClipboard(this.row.account.num);
            common.toast('계좌번호가 복사되었습니다.')
        },
        insertComma(val){
            const str = `${val}`
            var value = str.replace(/,/g, '');
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        onDel(){
            const onSuccess = (data) => {
                console.log('onSuccess', data)              
                this.close();

                console.log(data)
                common.notifyEvent(Const.notify.post_del, data)
            }
            
            const url = Const._api + "my/pay/del";
            common.api_post(url, {ad_idx: this.row.ad_idx} , data => {
                onSuccess(data)
            });
        },
        onDone(){
            const proc = (data) => {
                console.log('onSuccess', data)              
                this.close();

                console.log(data)
                common.notifyEvent(Const.notify.pay_done, {idx: this.idx});
            }

            const url = Const._api + "my/pay/submit";

            axios.post(url, this.form).then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });                    
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.tb-btn {
  margin: 0 10px;
}

.hash-input {
  width: 100%;
  /* position: fixed; 
  bottom: 10px; */
}

#main-container {
  height: 100%;
    /* background: yellow; */
  background-color: white;
  /* display: flex;
  flex-direction: column; */
  /* height: 100%; */
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  overflow: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#main-container::-webkit-scrollbar {
    display: none;
}

.text-button > div {
  /* padding: 10px; */
  margin: 0;
}

.text-button > i {
  padding: 10px;
  /* padding-left: 15px; */
}

.flex {
  display: flex;
}

.bottom {
  overflow: hidden;
  /* background-color: #333; */
  position: fixed;
  bottom: 0;
  width: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* align-items: stretch; */
  background-color: #f1f1f1;
}

.image {
    height: 80px;
    background-color: pink;
}

.thumbnail {
    width: 90px; height: 90px;
}

.addfile{ 
  font-size: 13px;  
  padding:10px; 
  border-bottom: 1px solid #c0c0c0; 
}

.addfile > div{ 
  font-size: 12px; 
  background-color: #f8f8f8;  
  width: 100%; 
  height: 30px;  
  line-height: 30px; 
  text-align: center; 
  box-sizing: border-box; 
  margin-bottom: 10px;
}

.addfile > ul:after{content:""; display:block; clear:both; }
.addfile > ul > li {float: left; margin-right: 10px; position: relative; margin-bottom: 10px; }
.addfile > ul > li  > a{color: #eb5c53; line-height: 10px; padding: 8px; background-color: #fff; right: 0; position: absolute; }

.addfile .image > img {
    object-fit: cover;
    width:80px;
    height:80px;
}

.addfile .image {
    color: white;
    text-decoration: none;
    /* padding: 15px 26px; */
    position: relative;
    display: inline-block;
    border-radius: 2px;
    margin: 3px;
}
.image > span{
    position: absolute; 
    top:-4px;  
    right: -4px;
    position: absolute;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: black;
    color: white;
}

.post-container {
    /* border: 2px solid #ccc; */
    /* background-color: #eee; */
    border-radius: 5px;
    /* padding: 16px; */
    margin: 14px;
  }
  
.post-container::after {
  content: "";
  clear: both;
  display: table;
}

  
  /* .post-container img {
    float: left;
    margin-right: 20px;
    border-radius: 5%;
  } */
  
.post-container .info {
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.post-container span {
  font-size: 20px;
  /* margin-right: 15px; */
}


/* .post-container .hash {
  background: yellow;
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
} */

.post-container .like {
  /* background: yellow; */
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}

.txt_post { 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: box; 
  margin-top:1px; 
  max-height: 180px; 
  overflow:hidden; 
  vertical-align:top; 
  text-overflow: ellipsis; 
  word-break:break-all; 
  -webkit-box-orient:vertical; 
  white-space: pre-wrap;   
  /* 3줄 */
  -webkit-line-clamp:3;   
}

.needs {
  background: ghostwhite;
  font-weight: bold;
}

</style>

