<template>    
    <div class="intro-back">
        <fetch-view :urls="urls" />
    </div>
</template>

<script>
import FetchView from './FetchView.vue';

export default {  
    components: {
        FetchView,
    },  
    props:{
    },
    mounted() {
    },
  data() {
    return {  
        urls: [
            'http://localhost:8001/html/invite/1.html',
            'http://localhost:8001/html/invite/2.html',
            'http://localhost:8001/html/invite/3.html',
        ],
    };
  },
  methods: {
  },      
}
</script>

