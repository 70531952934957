<template>
  <div class="template-contaioner" @click="click(data)">
      <div v-if="data.name" v-html="data.name"></div>
      <p class="title">{{data.title}}</p>
      <div class="between">
        <div style="display:flex;">
          <p v-if="data.info1" class="info" v-html="data.info1"></p>&nbsp; 
          <p v-if="data.info2" class="info" v-html="data.info2"></p>
        </div>          
        <!-- <p class="info">{{data.bottomRight}}</p> -->
        <p class="info" v-html="data.bottomRight"></p>
      </div>
  </div>
</template>

<style scoped>
@import '@/assets/common';
.template-contaioner {
  margin: 14px 0;
}

.template-contaioner::after {
  content: "";
  clear: both;
  display: table;
}

 .info {
  font-size: 14px;
  /* float: left; */
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.template-contaioner span {
  font-size: 20px;
  /* margin-right: 15px; */
}


.template-contaioner .hash {
  /* background: yellow; */
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.template-contaioner .like {  
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}

.title { 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: box; 
  margin-top:1px; 
  max-height: 180px; 
  overflow:hidden; 
  vertical-align:top; 
  text-overflow: ellipsis; 
  word-break:break-all; 
  -webkit-box-orient:vertical; 
  white-space: pre-wrap;   
  /* 3줄 */
  -webkit-line-clamp:3;   
}
</style>

<script>
export default {  
    props:{
      data:Object,
    },
    mounted() {
        // console.log('data', this.data)
    },
    data: function () {
      return {  
        //   name: value
      };
    },
    methods: {
      click:function(){
          this.$emit('clicked')
      },
    },      
}

</script>

