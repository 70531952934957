<template>
  <div class="scroll-container">
    <!-- <title-bar-ex ref="titlebar" :data="titleBarConfig"/> -->
    <title-bar-ex ref="titlebar" :title="'설정'" :container="container" :right="{}" :show_login="false"/>

    <div class="margin-side">
        <data-cell ref="email_cell" :data="email_cell" />
        <data-cell ref="account_cell" :data="account_cell" />
        <data-cell v-show="this.p_account" ref="parent_cell" :data="parent_cell" />
        <!-- <data-cell ref="children_cell" :data="children_cell" /> -->
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import DataCell from '@/components/common/DataCell'
// import slidemenu from '@/components/SlideMenu'

import TitleBarEx from './common/TitleBarEx.vue'
// import SlideCell from './common/SlideCell.vue';

export default {
    components: {
        TitleBarEx,
        DataCell,
    },
    props: {
      param: Object,
      container: Object,
    },
    setup() {
      // console.log('board setup');
      return {
      }
    },
    data(){ 
      return {
        children_cell:{
            title: ' ',
            value: '추천 혜택',
            show_button: false,
            click: this.onChildren,
            button: {},
        },
        email_cell: {
            title: '새로운 피드소식을 받을 이메일 주소',
            value: this.email,            
            show_button: true,
            button: {
                title : '등록/수정',
                click: this.onEmail
            }
        },
        account_cell: {
            title: '사용자 아이디',
            value: this.account,            
            show_button: true,
            button: {
                title : '등록',
                click: this.onAccount
            }
        },
        parent_cell:{
            title: '추천인 아이디',
            value: this.p_account,            
            show_button: true,
            button: {
                title : '연결해지',
                click: this.onDisconnect
            },
            comment: '나를 초대한 사용자로 내가 광고를 볼때마다 보너스가 전달됩니다.',
        },
        p_account: null, // 페어런트
        account: null,
        email: '',
        naver_id: '',
        title_left : [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
        ],
        rows: [],
        last: 0,
        total: 0,
        left:[
          {
            html: '<i class="fa fa-chevron-left"></i>',
            onClick: this.onLeft,
          },
          {
            html: '<span class="material-symbols-outlined ">home</span>',
            onClick: this.onHome,
          },
        ],
        titleBarConfig:{
            is_write: false, 
            is_menu: true,
            left: [
                {
                    html: `<i class="button-color fa fa-bars navi-button"></i>`,
                    onClick: () => {this.$refs.slidemenu.is_show = true;}
                },
                {
                    html: `<div class="navi-button" style="font-weight: 900;">${Const.about}</div>`
                }
            ]
        },        
        writable: false, // 이 게시판에 글을 작성 할 수 있는가?
        board_name: '',

        me: null,
        idx: 0,
        board: {},
        persona: {},
        needs_more: true,
        hashtag:['전체'],
        tag: '*', // 선택된 태그
        tags:[],
        all: { // 모든 멤버
          rows:[],
          isNew: false, // 새글이 있나?
          end: false, // 더 이상 없나?
          hasScroll: false, // 스크롤 유무
        },

        reported:{}, // 신고한 사람
        top_post: null,
      }
    },    
    created() {
        this.idx = common.param("idx");
    },
    mounted() {
        console.log('mounted')
        {
            // this.$refs.titlebar.title = `저장목록` 
            this.$refs.titlebar.left = this.title_left;
        }
        console.log('this.param', this.param)

        // console.log('this.idx', this.idx)

        {
            const comp = {
            onEvent: this.onEvent,
            onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        this.request();      
    },
    methods:{
        request(){
            const url = `${Const._api}me`
            console.log(url)

            axios.defaults.withCredentials = true;        
            const proc = (data) => {
                console.log(data)
                const {user} = data;
                console.log(user)

                Object.assign(this.user, user) ;

                const {email, account, p_account} = user;
                console.log('account', account, (account == null))

                this.email = email;
                this.account = account ? account : '미등록';
                this.updateAccount();

                // this.p_account = p_account ? p_account : '미등록'
                this.p_account = p_account;
                this.updateParent();

                this.email_cell.value = email;
            }
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });    
        } ,
        updateParent(){
            this.parent_cell.value = this.p_account;                
            this.parent_cell.show_button = !this.user.p_account ? false : true;
        },
        updateAccount(){
            this.account_cell.value = this.account;                
            this.account_cell.show_button = this.user.account ? false : true;
        },
        onChildren(){
            console.log('onChildren', this.user.account)
            if(this.user.account){
                common.push({
                    name: Const.component.ChildListView,
                    param: {}
                })
            }
            else{
                const param = {
                    header: '',
                    body: '추천을 위해 아이디 생성이 필요합니다.',
                    callback: this.onAccount
                }
                common.popupModal(param);
            }
        },
        onTest(){
            this.email_cell.title = 'test';
        },
        onEmail(){
            console.log('onEmail')
            common.popUp({
                name: Const.component.EmailForm,
                param: {}
            })
        },
        onDisconnect(){ // 추천인 소개 끊기
            console.log('onDisconnect')

            const disconnect = () => {
                const proc = (data) => {
                    console.log(data);
                    const {error, msg} = data;
                    if(error == 0){
                        this.request();
                    }else{
                        console.log(msg)
                        return;
                    }
                }

                const url= Const._api + "my/parent/disconnect";
                axios.post(url, {} )
                .then(common.checkResponse(proc))
                .catch(error => {
                    console.log(error);
                });                   
            }

            const param = {
                header: '주의사항',
                body: '연결을 해지하면 다시 되돌릴 수 없고\n다른 사용자를 지정할 수 없습니다.',
                callback: () => {
                    console.log('해지')
                    disconnect();
                },
                cancel: true,
            }
            common.popupModal(param);                  

            // todo 
            // 컨펌을 받는다. 
            // 한번 해지하면 다시 연결할 수 없습니다.
            // 추천인에게 돌아가는 보너스가 중지됩니다.
        },
        onParent(){ // parent 설정하기
            common.popUp({
                name: Const.component.ParentForm,
                param: {}
            })
        },
        onAccount(){
            console.log('onAccount')
            if(this.user && this.user.account > 0){
                return;
            }

            common.popUp({
                name: Const.component.AccountForm,
                param: {}
            })
        },
        // onNaver(){
        //     console.log('onNaver')
        //     if(this.naver_id && this.naver_id.length > 0){
        //         return;
        //     }

        //     common.popUp({
        //         name: Const.component.NaverForm,
        //         param: {}
        //     })
        // },

        onMove(location_idx){
                console.log('onMove', location_idx)

                this.$refs.locationbar.movePosition({location_idx})
            },

        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {
            case Const.notify.user:
                {
                    console.log(this.user)
                    const {account, p_account} = this.user; //  
                    this.user.account = account;
                    this.account = account;
                    this.account_cell.value = account;
                    this.updateAccount();

                    this.p_account = p_account;                    
                    this.updateParent();
                }
                break;
            default:
                break;
            }
        },
        notLogin(){    
            console.log('notLogin')
            // todo : 로그인창 보여주기
        },
        onTitle(){
            console.log('enter')
        },
        onLeft(){
            common.back();
        },
        onScrollEnd: function(){
        },
        onNavbar: function(button){
            // console.log('onNavbar', button);
            switch (button) {
                case 'search':
                    this.$emit('event', 'home');
                    // {
                    //     const obj = {
                    //         name: 'SearchPage',
                    //     }
                    //     common.popUp(obj);
                    // }
                    break;
                case 'login':
                    {
                    const param = {
                        didLogin: this.initView
                    }
                    common.popupLogin(param)
                    }
                    break;
                case 'bookmark':
                    this.onLike();
                    break;
                case 'share':
                    {
                    // console.log('this', window.location);
                    const persona_idx = this.persona ? this.persona.idx : 0;
                    const param = {
                        url: `${window.origin}/?/board/${this.idx}/${persona_idx}`,
                    } ;
                    // console.log('param', param);

                    common.popUp({
                        name: Const.component.QrcodeVue,
                        param
                    })
                    }
                    break;
                case 'write':
                    if(this.writable == false){
                    const param = {
                        header: '',
                        body: Const.message.not_visited,
                    }
                    common.popupModal(param);                  
                    return;
                    }

                    this.onWrite();       
                break;
            
                default:
                break;
            }
        },
        onWrite(){
            // console.log('this.me', this.me);
            if(this.me)
            {
            const param = {
                data: {
                    idx: 0,
                },
            } 

            const obj = {
                name: Const.component.PostForm,
                param
            }
            common.popUp(obj);
            }
            else // 로그인을 한번도 한적이 없는 경우 
            {
            common.popupLogin();
            }         
        },
        onHome(){
            this.$emit('event', 'home');
        },
        onLike(){
            const url = Const._api + "board/like";
            const param = {
            board_idx: this.idx,
            like: !(this.board.isLike),
            }
            common.api_post(url, param, data => {
                // console.log('data', data)

                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    // console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }
                // this.$emit('done');
                this.board.isLike = !(this.board.isLike) ? 1 : 0;
                // this.$refs.navbarBottom.bookmark = this.board.isLike ? "bookmark-on" : "bookmark-off" ;

                common.notifyEvent(Const.notify.bookmark)
            })
        },
        onPost(data){
            console.log('onPost', data);
            
            common.push({
                name: Const.component.PostView,
                param: data,
            })  ;
        },
        onError: function(error, param){
            // console.log('onError', error, param);

            // todo : notlogin 처리 / 리책토링

            common.toast(param)
        },
        resetRows(obj){
            obj.rows = [];
            obj.isNew = false; // 새글이 있나?
            obj.end = false; // 더 이상 없나?
        }
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.empty {
  height: 200px;
}

.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

.block {
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px ;
}

</style>

