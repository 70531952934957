<template>
  <div style="background: white; height: 100vh; ">
    <div class="between margin-top10 padding10">
      <div />
      <button type="button" class="btn color-gray" @click="close()">닫기</button>
    </div>

    <!-- <div class="center-text" style="margin-top: 30px;margin-bottom: 30px" v-html="Abouting" />     -->

    <div class="login-container" style="margin-top: 10%;">
      <div class="margin-bottom10" v-show="step == 1">
        <input-box ref="input_email" :data="input_email" v-on:changed="(param)=>{this.email = param}" />
        <button type="button" class="btn text-button-color font-size12 " @click="onEmail" style="margin-top: 20px;">인증번호 받기</button>
      </div>

      <div v-show="step == 2">
        <input-box ref="input_authcode" :data="{title:'2. 이메일로 전달된 인증번호를 입력하세요.'}" v-on:changed="(param)=>{this.authcode = param}" />
        <button type="button" class="btn text-button-color font-size12 " @click="onConfirm" style="margin-top: 20px;">확인</button>
      </div>
    </div>

    <div class="between padding10">
      <div />
      <div class="comment-input text-button-color">
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import InputBox from './common/InputBox.vue'
// import JoinForm from './JoinForm.vue'

export default { 
  components: {
    InputBox
    // JoinForm,
  }, 
    props:{
      param:Object,
      close: Function,
    },
    mounted() {
      console.log("EmailForm.mounted", this.param)
    },
    data: function () {
      return {  
        //   name: value
        input_email: {title:'1. 사용할 이메일 주소를 입력하세요.'},
        step: 1,
        email: '',
        pw: '',
        authcode: '',
        comment: '',
        warning: '',
        is_join: false,
        Abouting: `<div class="" style="font-size:24px; font-weight: 900;">${Const.about}</div>`
      };
    },
    methods: {
        onConfirm(){ // 인증번호 입력
            this.warning = '';

            const proc = (data) => {
                if(data.error == 0){
                    console.log('인증완료')
                    common.notifyEvent(Const.notify.email, {email: this.email})

                    const param = {
                      header: '',
                      body: '정상적으로 등록되었습니다.',
                      callback : ()=> {this.close();}
                  }

                    common.popupModal(param)
                }else{
                    console.log('인증실패')

                    var {error, msg} = data;
                    if(error > 0)
                    {
                        this.warning = msg;
                    }                    
                }
            }

            axios.post(Const._api + 'email_res', {authcode: this.authcode} )
            .then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });                    
        },
        onEmail(){
            this.warning = '';

            if(!common.validateEmail(this.email)){
                // show('error1')
                // setText('error1', '유효하지 않은 이메일주소입니다.')

                this.$refs.comment = '유효하지 않은 이메일주소입니다.';
                this.warning = '유효하지 않은 이메일주소입니다.';
                return;
            }

            const proc = (data) => {
                console.log(data);
                const {error, msg} = data;
                if(error == 0){
                    this.step = 2;
                    // todo : 인증
                    // hide('email_div')
                    // show('auth')
                }else{
                    console.log('전달실패')
                    // show('error1')
                    // setText('error1', data.msg)
                    this.warning  = msg;

                    this.input_email.comment = msg; 

                    this.$emit('clicked', 'failed', msg);
                    return;
                }
            }

            const email = this.email;
            const url= Const._api + "email_req";
            axios.post(url, {email} )
            .then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });      

        },      
      onEmail_(){

        const url= Const._api + "member/email_check";

        const params = {
          email: this.email,
        };

        common.api_post(url , params, 
          (data) => {            
            console.log('login.data', data);
            var {error, msg} = data;
            if(error > 0){              
              this.warning = msg;
              return;
            }

            this.step = 2;
          }
          ,(msg, error) => {
            console.log(msg, error)
            if(error == 1){
              const param = {
                header: '안내',
                body : msg, 
                callback: () => { this.close();}
              }

              const obj = {
                name: 'ModalDialog',
                param
              }
              common.popUp(obj);                              
            }
          }
        );
      },
      onPrivacy(){
        window.open('http://naver.com')
      },
      onUser(){
        window.open('http://naver.com')
      },
      onEnter(id){
        switch (id) {
          case 'id':
            this.$refs.pw.focus();
            break;
          case 'pw':
            this.login();
            break;        
          default:
            break;
        }
      },
      show: function(){
        this.email = '';
        this.pw = '';

        // todo : 로컬스토리지에 있다면 바로 시도한다.            
      },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sns > img {
  width: 166px;
  /* height: 40px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}
.container {
  display: flex; 
  flex-direction: column; 
  margin-left: auto; 
  margin-right: auto; 
  width: fit-content;
}

.join {
  display:flex; 
  margin-right: 0; 
  width: fit-content;
}

.open-button {
  /* background-color: #555; */
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  /* position: fixed; */
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.login-container {
    top:0;
    bottom: 0;
    /* right: 15px; */
    z-index: 9;
    max-width: 100%;
    padding: 10px 30px;
    /* height: 100vh; */
    background-color: white;
}

.login-container .btn {
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}
  
input::placeholder {
    /* opacity: .5; */
    color: rgb(128, 128, 128);
    /* padding-left: 10px; */
}

.warning {

}

</style>

