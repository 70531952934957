<template>
    <div style="background: white; height: 100vh; ">
        <div class="between margin-top10 padding10">
            <div />
            <button type="button" class="btn color-gray" @click="close()">닫기</button>
        </div>
        <div class="popup-container" style="padding: 80px 40px 0">
            <!-- 전화번호 입력창 010을 제외하고 -->
            <div v-show="step == 1" style="margin-top: 30px;">
                <pre class="center-h" style="white-space: pre-line;">
네이버페이 쿠폰을 수령하기 위한 본인의 핸드폰번호를 입력하세요. 
(타인의 폰을 등록하는 경우 포인트수령이 취소됩니다)
                </pre>
                          
                <div class="center-v center-h" style="margin-top: 25px;">
                    <div style="font-size: 18px;">010 - </div>
                    <div style="width: fit-content;">
                        <input v-model="phone" type="tel" style="margin-left: 5px;" placeholder="8자리 번호입력" @keyup.enter.prevent="onRequest" />
                    </div>
                </div>
                <text-button class="margin-top20" :data="{title: '인증번호요청', onclick: onRequest}" />
            </div>

            <!-- 인증번호 입력창 -->
            <div v-show="step == 2" class="center-h">
                <div style="width: fit-content">
                    <input-box ref="number" :data="{title: '인증번호 입력'}" v-on:changed="onChanged" v-on:entered="onSubmit" />

                    <div class="right" style="margin-top: 5px;">
                        <div>남은시간 {{ time_str }}</div>
                    </div>

                    <div style="margin-top: 20px;"></div>
                    <text-button :data="{title: '인증확인', onclick: onSubmit}" />
                    <text-button :data="{title: '다시 보내기', onclick: onRetry}" />
                </div>
            </div>
        </div>        
    </div>
</template>

<style scoped>
@import '@/assets/common';

input{
    box-sizing: border-box; 
}

</style>

<script>
import common from '@/common';
import Const from '@/const'

import InputBox from './common/InputBox.vue';
import TextButton from './common/TextButton.vue';

export default {  
  components: {
    InputBox,
    TextButton,
  },  
  props:{
    param: Object,
    controller: Object,
    close: Function,
  },
  mounted() {
      console.log('data', this.data)
  },
  data: function () {
    return {  
        step: 1,
        phone: '',
        number: '',
        time_str: '03:00',
        interval: null,
    };
  },
  methods: {
    onRequest(){
        console.log('onRequest', this.phone);

        const phone = this.phone.trim(); // 문자열로 바꿔야 trim이 작동한다.
        if(phone.length < 8  || phone.length > 8)
        {
            // console.log('phone.length>', phone.length)
            common.toast('8자를 입력해주세요')
            return;
        }

        const url = Const._api + 'phone/req';
        const body = {phone};
        const proc = (data) => {
            const {error, msg, seconds} = data;
            if(error == 0)
            {
                this.timer(seconds);
                console.log('인증번호 입력하세요')
                this.step = 2;
            }
            else if(error == 4)
            {
                common.toast('조금만 더 기다려주세요')
                return;
            }
            else
            {
                console.log('에러', error, msg)
                
                const param = {
                    header: '잘못된 접근',
                    body: msg,
                }

                common.popupModal(param)
            }            
        }

        common.post(url, body, proc)
    },
    onRetry(){
        this.step = 1;
        this.phone = '';
        this.number = '';
    },
    onSubmit(){
        console.log('onSubmit', this.number)
        // 공백제거
        let number = this.number.trim();
        // 숫자만 - 이거 서버에서 하고 있다. 
        // 길이체크 - 이거 서버에서 하고 있다.
        console.log('number>', number)
        
        const url = Const._api + 'phone/submit';
        const body = {number};
        const proc = (data) => {
            const {error, msg} = data;
            if(error == 0)
            {
                console.log('성공')             
                common.notifyEvent(Const.notify.phone_reg_done)
                this.close();
            }else
            {
                // incorrect : 1, // 잘못된 접근
                // too_late: 2, // 유효시간 초과
                // not_answer: 3, // 틀림
                
                console.log('에러', error, msg)

                // todo 
                // 에러메시지 보여주고

                {
                    console.log('에러', error, msg)
                
                    const param = {
                        header: '인증오류',
                        body: msg
                    }

                    common.popupModal(param)
                }

                if(error == 2){
                    this.$refs.number.update('')
                }
            }            
        }

        console.log(body)
        common.post(url, body, proc)

    },
    onChanged(value){
        this.number = value;
    },
    click:function(data, n){
        this.selected = n;
        this.$emit('clicked', data, this.id)
    },
    timer(inputSeconds){
        console.log('timer', inputSeconds)
        clearInterval(this.interval); // 이전 타이머가 실행 중이라면 중지

        let remainingSeconds = inputSeconds;

        const _this = this;
        this.interval = setInterval(() => {

            const minutes = Math.floor(remainingSeconds / 60);
            const seconds = remainingSeconds % 60;
            _this.time_str = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            // document.getElementById('timer').textContent = timerDisplay;

            if (remainingSeconds <= 0) {
                clearInterval(_this.interval); // 타이머 종료
                // alert('타이머 종료');
                _this.step = 1;
            }

            remainingSeconds--;
        }, 1000);
    }
  },      
}
</script>

