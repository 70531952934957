<template>
    <div class="scroll-container">
        <title-bar-ex ref="titlebar" :title="'초대회원'" :container="container" :right="right"/>
        <div class="margin-side">
            <intro-invite />
            <div class="group">
                <div>진행중인 이벤트</div>
                <list-cell :data="info" />
            </div>

            <div class="group">
                <div>초대한 친구</div>
                <no-data v-show="rows.length == 0" :data="{html: '아직 초대한 친구가 없습니다.'}" />    
                <div class=" background-color"  >
                    <div class="middle" id="contents" >
                        <child-cell v-for="(item, index) in rows" v-bind:key="index" :data="item"></child-cell>
                        <footer-view />
                    </div>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import common from '@/common'
import Const from '@/const'
// import Navbar from '@/components/common/NavBar'

import TitleBarEx from './common/TitleBarEx.vue'
import ChildCell from './common/ChildCell.vue';
import IntroInvite from './Intro/IntroInvite.vue'
import ListCell from './common/ListCell.vue'
import NoData from './common/NoData.vue'
import FooterView from './common/FooterView.vue'

export default {
    components: {
        TitleBarEx,
        // SlideCell,
        ChildCell,
        IntroInvite,
        ListCell,
        NoData,
        FooterView
    },
    props: {
      param: Object,
      container: Object,
    },
    setup() {
      // console.log('board setup');
      return {
      }
    },
    data(){ 
      return {
        invite: null,
        info: [
            {
                title: '체리',
                value: '',
            },
            {
                title: '이벤트기한',
                value: '',
                comment: '인원이 채워지면 자동으로 마감됩니다.'
            },
        ],
        show_manual: false,
        manual: null,
        title_left : [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
        ],
        show_intro: false,
        rows: [],
        last: 0,
        total: 0,
        left:[
          {
            html: '<i class="fa fa-chevron-left"></i>',
            onClick: this.onLeft,
          },
          {
            html: '<span class="material-symbols-outlined ">home</span>',
            onClick: this.onHome,
          },
        ],
        right:{
          html: '초대링크복사',
          onClick: () => {
            console.log('초대링크복사', window.location.origin)

            // 서버에서 현재 추천프로그램의 초대 코드를 받아온다.
            const proc = (data) => {
                console.log(data);

                const {code} = data;

                const link = `${window.origin}/invite/${code}`
                console.log('link', link)

                common.copyToClipboard(link)
                common.toast('복사되었습니다.')                
            }

            const url = `${Const._api}invite/code`
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });    
          }
        },       
        writable: false, // 이 게시판에 글을 작성 할 수 있는가?
        board_name: '',

        me: null,
        idx: 0,
        board: {},
        persona: {},
        needs_more: true,
        hashtag:['전체'],
        tag: '*', // 선택된 태그
        tags:[],
        all: { // 모든 멤버
          rows:[],
          isNew: false, // 새글이 있나?
          end: false, // 더 이상 없나?
          hasScroll: false, // 스크롤 유무
        },

        reported:{}, // 신고한 사람
        top_post: null,
      }
    },    
    created() {
        this.idx = common.param("idx");
    },
    mounted() {
        this.$refs.titlebar.didLogin();

        console.log('mounted')
        {
            this.$refs.titlebar.left = this.title_left;
        }
        console.log('this.param', this.param)

        {
            const comp = {
                onEvent: this.onEvent,
            }
            common.registEventHandler(comp);
        }

        // console.log('this.idx', this.idx)

        {
            const comp = {
                onEvent: this.onEvent,
                onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        this.request();
    },
    methods:{
        request(start=0){
            console.log('request')
            const url = `${Const._api}my/children/${start}`
            // const url = `${host}/api/my/ad/${idx}`
            console.log(url)

            let total = 0;

            axios.defaults.withCredentials = true;        
            const proc = (data) => {
                console.log(data)
                const {rows, row, invite} = data; // 신규, 본것
                console.log(rows)

                this.rows = rows;

                if(total == 0){
                    this.show_manual = true;
                    this.manual = row;
                }

                if(invite){
                    this.invite = invite;

                    const comment = `가입한 친구가 광고를 확인할때마다 내가 받는 보너스`
                    this.info[0].value = invite.reward;
                    this.info[0].comment = comment;
                    this.info[1].value = `${invite.limit_count - invite.total}명 남음`;

                    this.info = Object.assign(this.info, this.info)
                }
            }
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });    
        },
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {
            case Const.notify.charge:
                this.request()
                break;
            default:
                break;
            }
        },    
        notLogin: function(){    
            console.log('notLogin')
        },
        fitToBubble(rows){
            rows.forEach(element => {
            element.my_persona_idx = this.persona.idx;
            if(element.persona_idx == this.persona.idx) element.myname = element.persona_idx;
            if(this.reported[`${element.persona_idx}`]) element.hidden = true;
            if(element.nickname) element.name = `${element.persona_idx}(${element.nickname})`
            });
        },
        requestAll(start=0){
            console.log('requestAll',start)
        },
        onTitle(){

        },
        onLeft(){
            common.back();
        },
        pullNew(target, isMerge = false, rate = 0){             
            if(isMerge == false && target.isNew) return; // 이미 새글이 있다.
            
            const after = target.rows.length > 0 ? target.rows[0].idx : 0
            const url = Const._api + `board/new/${this.idx}/${after}/${rate}`;

            common.api_get(
            url, 
            res => {
                const {error, msg} = res.data;
                if(error > 0){
                const param = {
                    header: '잘못된 접근',
                    body: msg,
                }
                common.popupModal(param);
                
                return;
                }
                
                // this.snackbar.hide();

                const {rows} = res.data;

                if(rows.length == 0){
                common.toast('새로운 글이 없습니다.')
                return;
                }

                if(isMerge){
                target.isNew = false;
                rows.forEach(element => {
                    element.url = Const._base + element.url;
                });
                target.rows = rows.concat(this.rating.rows);  
                }
                else{
                target.isNew = true; // 표시만 한다.
                }
            }
            );
        },
        checkNewAll(){
            const last = this.all.rows.length > 0 ? this.all.rows[0].idx : 0;
            const url = Const._api + `post/board/new/all/${this.idx}/${last}/${this.tag}`;

            common.api_get(
            url, 
            res => {
                const {error, msg} = res.data;
                if(error > 0){
                const param = {
                    header: '잘못된 접근',
                    body: msg,
                }
                common.popupModal(param);
                
                return;
                }

                const {isNew} = res.data
                this.all.isNew = isNew;

                this.$refs.alert_all.alert(isNew);
                // if(!isNew) common.toast(Const.message.no_new)
            }
            );        
        },

        onWrite(){
            // console.log('this.me', this.me);
            if(this.me)
            {
            const param = {
                data: {
                board_idx: this.idx,
                idx: 0,
                }
            } 

            const obj = {
                name: Const.component.PostForm,
                param
            }
            common.popUp(obj);
            }
            else // 로그인을 한번도 한적이 없는 경우 
            {
            common.popupLogin();
            }         
        },
        onHome(){
            this.$emit('event', 'home');
        },
        onLike(){
            const url = Const._api + "board/like";
            const param = {
            board_idx: this.idx,
            like: !(this.board.isLike),
            }
            common.api_post(url, param, data => {
                // console.log('data', data)

                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    // console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }
                // this.$emit('done');
                this.board.isLike = !(this.board.isLike) ? 1 : 0;
                // this.$refs.navbarBottom.bookmark = this.board.isLike ? "bookmark-on" : "bookmark-off" ;

                common.notifyEvent(Const.notify.bookmark)
            })
        },
        onError: function(error, param){
            // console.log('onError', error, param);

            // todo : notlogin 처리 / 리책토링

            common.toast(param)
        },
        resetRows(obj){
            obj.rows = [];
            obj.isNew = false; // 새글이 있나?
            obj.end = false; // 더 이상 없나?
        }
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>
.group{
    margin-top: 20px;
}

</style>

