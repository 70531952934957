<template>
  <div style="background: white; ">
    <div class="between margin-top10 padding10">
      <div />
      <button type="button" class="btn color-gray" @click="close()">닫기</button>
    </div>

    <!-- <div class="center-text" style="margin-top: 30px;margin-bottom: 30px" v-html="Abouting" />     -->

    <div class="login-container" style="width: 60%; margin: 10% auto 0;">
      <div class="" v-show="step == 1">

        <input-box :data="input_account" v-on:entered="onDone" v-on:changed="(param) => {this.account = param;}" />

        <button type="button" class="btn text-button-color font-size12 " @click="onDone">등록</button>
      </div>

    </div>

    <div class="between padding10">
      <div />
      <div class="comment-input text-button-color">
        <!-- <div style="text-align:right" @click="onUser">사용자약관</div>
        <div @click="onPrivacy">개인정보 처리방침</div> -->
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import InputBox from './common/InputBox.vue'
// import JoinForm from './JoinForm.vue'

export default { 
  components: {
    InputBox
    // JoinForm,
  }, 
    props:{
      param:Object,
    },
    mounted() {
      // console.log("LoginForm.mounted", this.param)
    },
    data: function () {
      return {  
        input_account:{
          title: `사용할 아이디를 등록하세요.(영문, 숫자, 밑줄'_'만 사용이 가능합니다.)`,
          value: '',
        },
        step: 1,
        account: '',
        comment: '',
        is_join: false,
        Abouting: `<div class="" style="font-size:24px; font-weight: 900;">${Const.about}</div>`
      };
    },
    methods: {
        isAlphanumericUnderscoreOnly(str) {
            // 정규표현식을 사용하여 문자열이 알파벳, 숫자, 또는 밑줄(_)만 포함하는지 체크
            const regex = /^[a-zA-Z0-9_]+$/;
            return regex.test(str);
        },
        onDone(){
            this.input_account.comment = '';
            const account = this.account.trim();
            this.account = account;

            const proc = (data) => {
                console.log(data);
                const {error, msg} = data;
                if(error == 0){
                    this.user.account = account;
                    console.log(this.user)
                    const param = {
                        header: '',
                        body: '정상적으로 등록되었습니다.',
                        callback: () => {

                            common.notifyEvent(Const.notify.user)

                            this.close();
                        }
                    }
                    common.popupModal(param);                    
                }else{
                    console.log('전달실패')
                    this.input_account.comment = msg;

                    return;
                }
            }

            if(false == this.isAlphanumericUnderscoreOnly(account)){
                this.warning = '알파벳과 숫자, 밑줄(_)만 사용가능합니다.'
                return;
            }
            const url= Const._api + "my/account/reg";
            axios.post(url, {account} )
            .then(common.checkResponse(proc))
            .catch(error => {
                console.log(error);
            });      

        },      
      
        show: function(){
            this.account = '';

            // todo : 로컬스토리지에 있다면 바로 시도한다.            
        },
        close(){          
            this.param.container.close();
        },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sns > img {
  width: 166px;
  /* height: 40px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}
.container {
  display: flex; 
  flex-direction: column; 
  margin-left: auto; 
  margin-right: auto; 
  width: fit-content;
}

.join {
  display:flex; 
  margin-right: 0; 
  width: fit-content;
}

.open-button {
  /* background-color: #555; */
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  /* position: fixed; */
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.login-container {
    top:0;
    bottom: 0;
    /* right: 15px; */
    z-index: 9;
    max-width: 100%;
    padding: 10px 30px;
    /* height: 100vh; */
    background-color: white;
}

.login-container .btn {
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}
  
input::placeholder {
    /* opacity: .5; */
    color: rgb(128, 128, 128);
    /* padding-left: 10px; */
}

.warning {

}

</style>

