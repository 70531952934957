<template>
    <div>
        <div>
            <TabMenu class="sticky-top" ref="tabmenu" v-on:clicked="onTabMenu"></TabMenu>
            <div class="middle margin-side" id="contents" >

                <!-- 나의 관심글 목록 -->
                <div class=""  
                    v-show="selectedTab == my_like_post"                     
                    v-on:scroll.passive="handleScroll"
                >
                    <postcell 
                        v-for="(item, index) in likePosts" v-bind:key="index" 
                        :data="item" 
                        v-on:clicked="onSelPost"
                    />
                    <div v-if="likePosts.length == 0" class="blank" >
                        {{str_no_like}}
                    </div>
                    <div v-else class="tail"></div>
                </div>
                
                <!-- 나의 관심게시판 목록 -->
                <div class="scroll-container"
                    v-show="selectedTab == my_boards && user !=null" 
                    v-for="(item, index) in myPosts" v-bind:key="index"
                    v-on:scroll.passive="handleScroll"
                >
                    <boardcell :data="item" v-on:clicked="onSelBoard(item.board_idx)"></boardcell>
                </div>

                <!-- 세상의관심 -->
                <div class="scroll-container" 
                    v-show="selectedTab == recent_board" 
                    v-on:scroll.passive="handleScroll"
                >
                    <boardcell 
                        v-for="(item, index) in recentBoards" v-bind:key="index" 
                        :data="{...item, index, rank: true}"
                        v-on:clicked="onSelBoard(item.idx)" 
                    />
                    <div class="tail"></div>
                </div >

                <!-- 주변 게시판 목록 -->
                <div v-show="selectedTab == around_board || selectedTab == around_post" >
                    <LocationBar  ref="locationbar" 
                        v-on:success="onGotAddress" 
                        v-on:failed="onFailedAddress" 
                    />
                </div>

                <div class="scroll-container"  
                    v-show="selectedTab == around_board" 
                    v-on:scroll.passive="handleScroll"
                >
                    <boardcell 
                        v-for="(item, index) in recentNearbyBoards" v-bind:key="index" 
                        :data="{...item, index, rank: true}" 
                        id="around_board" 
                        v-on:clicked="onSelBoard(item.idx)" 
                    />
                    <div v-if="recentNearbyBoards.length == 0" class="blank" >
                        {{str_empty}}
                        <div v-if="show_try_button" >
                            <div class="location-button" @click="$refs.locationbar.search(); show_try_button = false;">현재 위치 가져오기</div>
                        </div>
                    </div>
                    <div v-else class="tail"></div>

                </div>

                <!-- 주변 최신글 목록 -->
                <div class="scroll-container"
                    v-show="selectedTab == around_post"  
                    v-on:scroll.passive="handleScroll"
                >
                    <postcell 
                        v-for="(item, index) in recentNearbyPosts" v-bind:key="index"  
                        :data="item" 
                        v-on:clicked="onSelPost" 
                    />

                    <div v-if="recentNearbyPosts.length == 0" class="blank" >
                        {{str_empty}}
                        <div v-if="show_try_button" >
                            <div class="location-button" @click="$refs.locationbar.search(); show_try_button = false;">현재 위치 가져오기</div>
                        </div>
                    </div>
                    <div v-else class="tail"></div>
                </div>

                <!-- 세상글 목록 -->
                <div class="scroll-container"
                    v-show="selectedTab == recent_post" 
                    v-on:scroll.passive="handleScroll"
                >
                    <div v-for="(item, index) in recentPosts" v-bind:key="index">
                        <postcell :data="item" v-on:clicked="onSelPost"></postcell>
                    </div>
                    <div class="tail"></div>
                </div>
            </div>            
        </div>
        
        <div class="bottom-menu ">
            <Navbar ref="navbar" v-on:clicked="onNavbar"></Navbar>
        </div>

        <slidemenu ref="slidemenu"></slidemenu>
    </div>

</template>

<style scoped>
@import '@/assets/common';

.top-page {
  position: absolute;
  z-index: 99;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.row {
    font-size: 100px;
    /* background: peachpuff; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background-color: red; */
}

</style>
<style scoped>

.tail {
    height: 200px;
}

.padding-top {
    margin-top: 48px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>

<script>
  import common from '@/common'
  import Const from '@/const'
  import slidemenu from '@/components/SlideMenu'
  import Navbar from '@/components/common/NavBar'
  import TabMenu from '@/components/common/TabMenu.vue'
  import boardcell from '@/components/common/BoardCell.vue'
  import postcell from '@/components/common/PostCell.vue'
  import LocationBar from '@/components/common/LocationBar.vue'
  
  const my_like_post = Const.my_like_post;
  const my_boards = Const.my_boards;
  const recent_board = Const.world_board;
  const around_board = Const.around_board;
  const around_post = Const.around_post; 
  const recent_post = Const.recent_post;

  const str_no_board = '등록된 관심사가 아직 없습니다.'
  const str_no_like = '등록된 관심사가 아직 없습니다.'

  export default {
    components: {
      slidemenu,
      Navbar,
      TabMenu,
      boardcell,
      postcell,
      LocationBar,
    //   SearchPage,
    },
    data(){
      return {
        show_try_button: false,
        str_empty: '',
        str_no_like,
        str_no_board,
        show_board: false,
        show_post: false,

        my_like_post,
        my_boards,
        recent_board,
        around_board,
        around_post,
        recent_post,

        selectedTab: recent_board,
        tabmenu: {
          items:[
              recent_board, 
              around_board, 
              around_post, 
          ]
        },
        likePosts:[],
        myPosts:[], 
        recentNearbyBoards:[],
        recentNearbyPosts:[],
        recentBoards:[],
        recentPosts:[],
        user: null,
      }
  },
  created: function () {
  },
  computed: {
  },
  mounted: function() {

    {
        const {pathname} = location;
        console.log('HomeView.mounted', pathname);
    }

    this.loadView();

    {
        const comp = {
            onEvent: this.onEvent,
            onShow: this.onShow,
        }
        common.registEventHandler(comp)
    }

    {
        const path = location.search;
        console.log('location', location);

        const arr = path.split('/');
        if(arr.length > 2){
            console.log('arr', arr);
            const type = arr[1];
            const idx = arr[2]
            const recommend = arr.length > 3 ? arr[3] : 0;
            console.log(type, idx, recommend);

            switch (type) {
                case 'board':
                    common.push({
                        name: Const.component.SavedView,
                        param: {idx}
                    })
                    return;
                    // break;
                case 'post':
                    common.push({
                        name: Const.component.PostView,
                        param: {idx}
                    })
                    // break;
                    return;
                default:
                    break;
            }
        }        
    }    
  },
  methods:{
    onScrollBottom(){
        console.log('onScrollBottom')        
        switch (this.selectedTab) {
            case this.my_like_post:
                this.requestLikePosts();
                break;
            case this.around_board:
            //   this.requestNearbyBoard();
            break;
            case this.around_post:
            //   this.requestNearbyPosts();
            break;
            case this.recent_board:
            // this.requestBoards();
            break;
            case this.recent_post:
            //   this.requestPosts();
            break;        
            default:
            break;
        }        
    },      
    handleScroll(e){
        // console.log('handleScroll',e, tag);

        const {target} = e;
        const {clientHeight, scrollHeight, scrollTop} = target;

        if((clientHeight + scrollTop) == scrollHeight) 
        {
            this.onScrollBottom();
        }
        else if(scrollTop == 0){
            console.log('top')
            // this.onScrollTop();
        } 

    },   
    loadView(){
        // {
            // const main = common.param("main");
            // console.log('main', main);

            // if(main)
            //     this.$refs.searchPage.showPopup(false);
            // else
            //     this.$refs.searchPage.showPopup(true);
        // }
        
        common.checkSession(this.isLogin)
        this.$refs.tabmenu.menu(this.tabmenu.items);
    },
    isLogin(isLogin, user){
        this.$refs.slidemenu.is_login = true;
        this.$refs.slidemenu.is_temp = user.is_temp ? user.is_temp : false;
        console.log('this.$refs.slidemenu.is_temp', this.$refs.slidemenu.is_temp);
        this.requestBoards();


        console.log('isLogin', isLogin, user);
        if(isLogin){
            this.composeTabMenu();
            // this.requestMyBoards();
            this.requestLikePosts();

            {
                Object.assign( this.user, user);
                this.$refs.navbar.didLogin();
                this.$refs.navbar.is_message = true; // 노출

                this.$refs.navbar.is_noti = user.notice;
                this.$refs.navbar.has_message = user.message;
            }
        }
        else
        {     
            // todo 
        }
    },
    composeTabMenu(){
        console.log('composeTabMenu');
        this.tabmenu.items = // 탭메뉴를 늘린다.
        [
            my_like_post,
            // my_boards,
            recent_board, 
            around_board, 
            around_post, 
            // recent_post,
        ]
        this.$refs.tabmenu.menu(this.tabmenu.items);
        this.$refs.tabmenu.selected = 0;
        // 최초 선택메뉴를 바꾼다.
        this.selectedTab = my_like_post;
    },
    didLogin(){
      this.$refs.login.is_show = false;
      common.checkSession(this.isLogin);
    },
    didJoin(){
      console.log('didJoin');
    },
    checkMe(){ // 새로운 메시지, 알람 체크
        common.checkMe( (res) => {
            console.log('checkMe', res.data);
            const {data} = res;
            const {error, user} = data;

            if(error === 0 && user && user.idx){
                this.$refs.navbar.has_message = user.message;
                this.$refs.navbar.is_noti = user.notice;
            }
        })
    },
    onShow(){
        this.checkMe();
    },
    onEvent(name, param){
        console.log('onEvent', name, param, this.idx);

        switch (name) {
            case Const.notify.login:

                this.loadView();
                this.$refs.slidemenu.is_login = true;
            break;

            case Const.notify.bookmark:
                console.log('case Const.notify.bookmark:');
                this.requestMyBoards();
            break;
        
            default:
            break;
        }
    },    
    onPostView(tag, param){
        console.log('onPostView', tag, param)
        switch (tag) {
            case 'close':
            this.show_post = false;
            break;
        
            default:
            break;
        }
    },
    onBoardView(tag, param){
        console.log('onBoardView', tag, param)

        switch (tag) {
            case 'back':
            this.show_board = false;
            break;
        
            default:
            break;
        }
    },
    onMenu(){      
    },
    onClose(){
      console.log('onClose');
    },
    onOK() {
      console.log('onOK');
    },
    onGotAddress(address){
        console.log('onGotAddress', address);
      switch (this.selectedTab) {
        case this.around_board:
          this.requestNearbyBoard();
          break;
        case this.around_post:
          this.requestNearbyPosts();
          break;
      }

    },
    onFailedAddress(msg){
      common.toast(msg);
    },
    requestNearbyBoard: function(){
        const url = Const._api + 'board/around';

        common.api_get(
            url, 
            res => {
          const {error, msg} = res.data;
          if(error > 0){
            console.log('msg', error, msg);
            switch (error) {
              case Const.err_code.not_login:
                
                break;
              case Const.err_code.not_location:
                  {
                    this.str_empty = msg;
                    this.show_try_button = true;


                  }
                break;              
              default:
                common.toast(msg);
                break;
            }
            return;
          }

          console.log('rows', res.data.rows)

          const {rows} = res.data;

          if(this.recentNearbyBoards.length == 0 && rows.length == 0)
          {
              this.str_empty = str_no_board;
              return;
          }

          rows.forEach(element => {
            const temp = element.name.split('@');
            element.location = temp[1];
            element.name= temp[0];
          });

          this.recentNearbyBoards = rows;
        });  
    },
    requestNearbyPosts: function(){
        const url = Const._api + 'location/posts';
        common.api_get(url, res => {
            const {error, msg} = res.data;
            if(error > 0){
                console.log('msg', msg);
                switch (error) {
                    case Const.err_code.not_login:
                    
                    break;
                    case Const.err_code.not_location:
                    {
                        this.str_empty = msg;
                        this.show_try_button = true;
                    }
                    break
                    default:
                        common.toast(msg)
                    break;
                }
                return;
            }

            console.log('requestNearbyPosts.res.data', res.data)
            const {rows} = res.data;

            if(this.recentNearbyPosts.length == 0 && rows.length == 0)
            {
                this.str_empty = str_no_board;
                return;
            }

            rows.forEach(element => {
                const temp = element.board_name.split('@');
                element.location = temp[1];
                element.board_name= temp[0];
            });

            this.recentNearbyPosts = rows;      
        });
    },
    requestLikePosts(){
        let start = this.likePosts.length > 0 ? this.likePosts[this.likePosts.length - 1].idx : 0;
        const url = Const._api + `my/news/${start}`;
        common.api_get(url, res => {
            const {error, msg} = res.data;
            if(error > 0){
                console.log('msg', msg);
                switch (error) {
                    case Const.err_code.not_login:
                    
                    break;
                    case Const.err_code.not_location:
                        common.toast(msg);
                    break
                    default:
                        common.toast(msg);
                    break;
                }
                return;
            }

            console.log('data', res.data)

            res.data.rows.forEach(element => {
                element.board_name = element.name;
                element.name = '';
                const temp = element.name.split('@');
                if(temp.length > 1)
                {
                    element.location = temp[1];
                    element.board_name= temp[0];
                }
            });

            this.likePosts = this.likePosts.concat(res.data.rows);
        });
    },
    requestMyBoards(){
        console.log('requestMyBoards');
        const url = Const._api + 'board/likes';
        console.log('url', url);
            
        common.api_get(url, res => {
            const {error, msg, rows} = res.data;
            if(error > 0){          
            this.showModal('오류', msg);
            return;
            }

            console.log('myPost.res.data', res.data);

            rows.forEach(element => {
            // element.title = element.name;
                const temp = element.name.split('@');
                element.location = temp[1];
                element.name= temp[0];
            
            // element.name = '';
            // element.info1 = `posts ${element.posts}`   
            element.info1 = element.content
            // element.bottomRight = element.updated;       
            element.bottomRight = common.printTimeString(element.updated);
            
            });

            this.myPosts = rows;
        });
    },
    requestBoards: function(){
        const url = Const._api + 'board/hot';
        console.log('url', url);
                
        common.api_get(url, res => {
            const {error, msg, rows} = res.data;
            if(error > 0){          
            this.showModal('오류', msg);
            return;
            }

            console.log('requestBoards.res.data', res.data);
            this.recentBoards = rows;
            // this.recentBoards.rows = rows.concat(rows).concat(rows);        
        });
    },
    requestPosts: function(){
        console.log('requestPosts');
        const url = Const._api + 'post/recent';
        common.api_get(url, res => {
            const {error, msg} = res;
            if(error > 0){
                this.showModal('오류', msg);
            return;
            }

            this.recentPosts = res.data.rows;      
        });
    },
    showModal: function(header, body, callback){
        const param = {
            header,
            body,
            callback
        }
        common.popupModal(param);
    },
    onNavbar: function(button){
        console.log('onAppBar', button);
        switch (button) {
            case 'login':
                common.popupLogin();
            break;
            case 'menu':
            this.$refs.slidemenu.is_show = true;
            break;
            case 'search':
            //   this.$refs.searchPage.showPopup(true);        
                {
                    console.log('popup SearchPage')
                    const obj = {
                        name: 'SearchPage',
                        // param
                    }
                    common.popUp(obj);
                }
            break;
            case 'my':
            
            break;
        
            default:
            break;
        }
    },
    onNext: function(start, id){
      console.log('onNext', id, start);
      // todo
    },
    onPre: function(start, id){
      console.log('onPre', id, start);
    },
    onTabMenu: function(menu){
        this.selectedTab = menu;
        console.log('index.selectedTab', menu);

        switch (menu) {
            case this.around_board:
            this.requestNearbyBoard();
            break;
            case this.around_post:
            this.requestNearbyPosts();
            break;
            case this.recent_board:
            this.requestBoards();
            break;
            case this.recent_post:
            this.requestPosts();
            break;        
            default:
            break;
        }

        if(menu == this.around_board || this.around_post) // 위치 정보 없으면 가져오기 시도
        {
            console.log('this.$refs.locationbar.address', this.$refs.locationbar.address)
            if(this.$refs.locationbar.address == null){
                this.$refs.locationbar.search();
            }
        }
    },
    onSelBoard: function(idx){                    
        common.push({
            name: Const.component.SavedView,
            param: {idx}
        })
    },
    onSelPost: function(data){
        console.log('onSelPost', data);
        
        const {idx} = data;
        common.push({
            name: Const.component.PostView,
            param: {idx}
        })
    },
  },
}
</script>
