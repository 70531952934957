<template>
    <div v-if="popups.length > 0">
        <popup-container 
        class="pop "  
        :style="{ width: containerWidth + 'px' }"
        v-for="item in popups" 
        :key="item.index" 
        :param="item" 
    />
    </div>
</template>

<script>
import PopupContainer from './PopupContainer.vue';

export default {
//   name: 'NavigationController',
  components: {
    PopupContainer

  },
  props: {
    compName: String
  },
  data() {
    return {
        containerWidth: 0,
        popups: [],
        isLeaving: false, // 화면에서 감추고 콜백 받기전까지 true,
        waiting: [],  // 처리 대기중인 
    }
  },
  created(){
  },
  mounted() {     
    console.log('PopupController.mounted')
    {
        // 현재 브라우저 창의 너비와 높이를 얻어옴
        setTimeout(() => {
            this.containerWidth = this.ui.width;
        }, 1);
    }    
  },
  methods: {
    pushSlideContainer(container){
      const comp = this.lastComp();
      comp.container = container
    },
    notifyEvent(name, param){
        name, param
    },
    closeAll(){
        this.popups.length = 0;
    },
    componentMounted(obj){
      // console.log('componentMounted', obj)
      const {onShow, onHide, onEvent} = obj;
      const last = this.lastComp();

      if(last){
        last.onShow = onShow;
        last.onHide = onHide;
        last.onEvent = onEvent;
      }
    },
    closePopup(){
      // 마지막거... 
      const comp = this.popups.length > 0 ? this.popups[this.popups.length - 1] : null;


      if(comp) comp.close();

    //   this.popups.splice(this.popups.length - 1, 1); 
    },
    push(comp){
        comp.controller = this;

        if(this.isLeaving == false)
            this.popups.push(comp)
        else
            this.waiting.push(comp);
    },
    removeLastComp(){
        this.popups.splice(this.popups.length - 1, 1); 
    },
    onAfterLeave(){
        this.isLeaving = false;

        this.removeLastComp();

        setTimeout(()=>{
            this.waiting.forEach(element => {
                this.push(element)
            });
            this.waiting = [];
        }, 1)
    },
    onBeforeLeave(){
        this.isLeaving = true;
    },
    onPopupEvent(tag, param){
      {
        param
      }

      switch (tag) {
        case 'back':
          break;
      
        default:
          break;
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '@/assets/common';

.pop {
  position: fixed;
  top: 0;
  /* left: 0; */
  /* width: 50%; */
  /* height: 100vh; */
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
